import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import clsx from "clsx";
import i18n from "i18next";

const getCommonColumns = () => {
  return [
    {
      field: `ID`,
      type: "number",
      headerName: i18n.t(`ANALYSIS_GRID_ID`),
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: `PR_NAME`,
      type: "string",
      headerName: i18n.t(`ANALYSIS_GRID_PR_NAME`),
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: `TYPE_NAME`,
      type: "string",
      headerName: i18n.t(`ANALYSIS_GRID_TYPE_NAME`),
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: `OPENED_AT`,
      type: "string",
      headerName: i18n.t(`CANDLE_OPENED_AT`),
      width: 160,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: `CLOSED_AT`,
      type: "string",
      headerName: i18n.t(`CANDLE_CLOSED_AT`),
      width: 160,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: `OPEN`,
      type: "number",
      headerName: i18n.t(`CANDLE_OPEN`),
      width: 90,
      disableColumnMenu: true,
    },
    {
      field: `CLOSE`,
      type: "number",
      headerName: i18n.t(`CANDLE_CLOSE`),
      width: 90,
      disableColumnMenu: true,
    },
    {
      field: `HIGH`,
      type: "number",
      headerName: i18n.t(`CANDLE_HIGH`),
      width: 90,
      disableColumnMenu: true,
    },
    {
      field: `LOW`,
      type: "number",
      headerName: i18n.t(`CANDLE_LOW`),
      width: 90,
      disableColumnMenu: true,
    },
    {
      field: `SYMBOL_PREFIX`,
      type: "string",
      headerName: i18n.t(`ANALYSIS_GRID_SYMBOL_PREFIX`),
      width: 40,
      disableColumnMenu: true,
    },
  ];
};

export const getGridFaqConfig = () => {
  return [
    {
      title: "ANALYSIS_FAQ_TITLE_TIGHT_BB",
      description: "ANALYSIS_FAQ_DESCRIPTION_TIGHT_BB",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_LOOSE_BB",
      description: "ANALYSIS_DESCRIPTION_FAQ_LOOSE_BB",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_BB_WIDTH",
      description: "ANALYSIS_DESCRIPTION_FAQ_BB_WIDTH",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_FROM_MA50",
      description: "ANALYSIS_FAQ_DESCRIPTION_FROM_MA50",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_FROM_MA200",
      description: "ANALYSIS_FAQ_DESCRIPTION_FROM_MA200",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_FROM_ATH",
      description: "ANALYSIS_FAQ_DESCRIPTION_FROM_ATH",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_FROM_ATL",
      description: "ANALYSIS_FAQ_DESCRIPTION_FROM_ATL",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_CONSOLIDATION",
      description: "ANALYSIS_FAQ_DESCRIPTION_CONSOLIDATION",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_PRICE_UP",
      description: "ANALYSIS_FAQ_DESCRIPTION_PRICE_UP",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_PRICE_DOWN",
      description: "ANALYSIS_FAQ_DESCRIPTION_PRICE_DOWN",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_GREEN",
      description: "ANALYSIS_FAQ_DESCRIPTION_GREEN",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_RED",
      description: "ANALYSIS_FAQ_DESCRIPTION_RED",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_VIX_UP",
      description: "ANALYSIS_FAQ_DESCRIPTION_VIX_UP",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_VIX_DOWN",
      description: "ANALYSIS_FAQ_DESCRIPTION_VIX_DOWN",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_VIX_22",
      description: "ANALYSIS_FAQ_DESCRIPTION_VIX_22",
    },
  ];
};

export const getVisibility = () => {
  return {
    ID: false,
    PR_NAME: false,
    TYPE_NAME: false,
    OPENED_AT: false,
    CLOSED_AT: false,
    OPEN: false,
    CLOSE: false,
    HIGH: false,
    LOW: false,
    SYMBOL_PREFIX: false,
    RSIDIV_ST_14: false,
    RSIDIV_ST_21: false,
  };
};

const getColumns: any = (code: string | undefined) => {
  const common = getCommonColumns();
  switch (code) {
    case "volatility":
      return [
        {
          field: `SYMBOL`,
          type: "string",
          headerName: i18n.t(`ANALYSIS_GRID_SYMBOL`),
          width: 110,
          valueGetter: (symbol: any, signal: any) =>
            symbol.replaceAll(signal.SYMBOL_PREFIX, ""),
          disableColumnMenu: true,
        },
        {
          field: `BB_TIGHT`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_BB_TIGHT`),
          width: 115,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  highlight: params.value >= 5,
                }),
          disableColumnMenu: true,
        },
        {
          field: `BB_LOOSE`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_BB_LOOSE`),
          width: 120,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  highlight: params.value >= 5,
                }),
          disableColumnMenu: true,
        },
        {
          field: `BB_WIDTH`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_BB_WIDTH`),
          width: 145,
          disableColumnMenu: true,
        },
        {
          field: `SMA50_D`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_SMA50_D`),
          width: 150,
          valueGetter: (sma: any) => (sma ? Number(sma.toFixed(2)) : sma),
          disableColumnMenu: true,
        },
        {
          field: `SMA200_D`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_SMA200_D`),
          width: 155,
          valueGetter: (sma: any) => (sma ? Number(sma.toFixed(2)) : null),
          disableColumnMenu: true,
        },
        {
          field: `ATH_D`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_ATH_D`),
          width: 120,
          valueGetter: (ath: any) => (ath ? Number(ath.toFixed(2)) : null),
          disableColumnMenu: true,
        },
        {
          field: `ATL_D`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_ATL_D`),
          width: 120,
          valueGetter: (atl: any) => (atl ? Number(atl.toFixed(2)) : null),
          disableColumnMenu: true,
        },
        {
          field: `FLAG_CL`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_FLAG_CL`),
          width: 150,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  highlight: params.value >= 5,
                }),
          disableColumnMenu: true,
        },
        {
          field: `PRICE_UP`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_PRICE_UP`),
          width: 115,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  sell: params.value >= 5,
                }),
          disableColumnMenu: true,
        },
        {
          field: `PRICE_DOWN`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_PRICE_DOWN`),
          width: 135,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value >= 5,
                }),
          disableColumnMenu: true,
        },
        {
          field: `GREEN`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_GREEN`),
          width: 105,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  sell: params.value >= 7,
                }),
          disableColumnMenu: true,
        },
        {
          field: `RED`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_RED`),
          width: 90,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value >= 7,
                }),
          disableColumnMenu: true,
        },
        {
          field: `VIX_UP`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_VIX_UP`),
          width: 105,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  sell: params.value >= 7,
                }),
          disableColumnMenu: true,
        },
        {
          field: `VIX_DOWN`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_VIX_DOWN`),
          width: 125,
          cellClassName: (params: GridCellParams<any, number>) =>
            params.value == null
              ? ""
              : clsx(`analysis-grid`, {
                  buy: params.value >= 7,
                }),
          disableColumnMenu: true,
        },
        {
          field: `VIX_22`,
          type: "number",
          headerName: i18n.t(`ANALYSIS_GRID_VIX_22`),
          width: 115,
          disableColumnMenu: true,
        },
        ...common,
      ];
    default:
      return [];
  }
};

export const getTogglableColumns = (columns: GridColDef[]) =>
  columns
    .filter(
      (column) =>
        column.field !== "SYMBOL_PREFIX" &&
        column.field !== "RSIDIV_ST_14" &&
        column.field !== "RSIDIV_ST_21"
    )
    .map((column) => column.field);

export const gridKey = "ID";
export default getColumns;
