import React from "react";
import { Box } from "@mui/material";
import AnalysisGridFilters from "@pages/Analysis/Grid/AnalysisGridFilters";
import AnalysisGrid from "@pages/Analysis/Grid/AnalysisGrid";
import AnalysisGridFaq from "@pages/Analysis/Grid/AnalysisGridFaq";

const AnalysisGridTab: React.FC = () => {
  return (
    <Box>
      <AnalysisGridFilters />
      <AnalysisGrid />
      <AnalysisGridFaq />
    </Box>
  );
};

export default AnalysisGridTab;
