import {
  enRankingGrid,
  elRankingGrid,
} from "@pages/Markets/Rankings/Grid/translations";
import {
  enRankingFaq,
  elRankingFaq,
} from "@pages/Markets/Rankings/Faq/translations";

export const enRankingTab = {
  ...enRankingGrid,
  ...enRankingFaq,
};

export const elRankingTab = {
  ...elRankingGrid,
  ...elRankingFaq,
};
