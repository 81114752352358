export const enCryptoMetricsTab = {
  MARKET_CRYPTO_METRICS_DOMINANCE: "Dominance (%)",
  MARKET_CRYPTO_METRICS_EXCHANGES_SUPPLY_USD: "Exchanges supply (B $)",
  MARKET_CRYPTO_METRICS_EXCHANGES_INFLOWS_NTV:
    "Exchanges inflows (in {{symbol}})",
  MARKET_CRYPTO_METRICS_EXCHANGES_OUTFLOWS_NTV:
    "Exchanges outflows (in {{symbol}})",
};

export const elCryptoMetricsTab = {
  MARKET_CRYPTO_METRICS_DOMINANCE: "Κυριαρχία (%)",
  MARKET_CRYPTO_METRICS_EXCHANGES_SUPPLY_USD: "Απόθεμα ανταλλακτηρίων (Δις $)",
  MARKET_CRYPTO_METRICS_EXCHANGES_INFLOWS_NTV:
    "Εισροές ανταλλακτηρίων (σε {{symbol}})",
  MARKET_CRYPTO_METRICS_EXCHANGES_OUTFLOWS_NTV:
    "Εκροές ανταλλακτηρίων (σε {{symbol}})",
};
