import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ROUTES } from "@constants/routes";
import { TAppState } from "@store/reducers";
import { commonSelector } from "@components/selectors";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { theme } = useSelector((state: TAppState) => commonSelector(state));

  const navigateToHome = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <Box sx={{ padding: 1 }}>
      <Box
        sx={{
          position: "fixed",
          left: "50%",
          top: "40%",
          width: "40%",
          WebkitTransform: "translate(-50%, -50%)",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <Box sx={{ fontSize: "4em" }}>
          <Typography>{t("NOT_FOUND_HEADER")}</Typography>
        </Box>
        <Box sx={{ fontSize: "2em" }}>
          <Typography>{t("NOT_FOUND_MESSAGE")}</Typography>
        </Box>
        <Box>
          <Button
            color={theme === "light" ? "inherit" : "primary"}
            onClick={navigateToHome}
          >
            <ArrowForwardIcon sx={{ fontSize: "5em" }} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
