import { enDateDisplay, elDateDisplay } from "@common/DateDisplay/translations";
import { enMenu, elMenu } from "@general/Menu/translations";
import {
  enBaseDatePicker,
  elBaseDatePicker,
} from "@common/BaseDatePicker/translations";
import {
  enTradingChart,
  elTradingChart,
} from "@common/TradingChart/translations";
import {
  enLeftRightChart,
  elLeftRightChart,
} from "@common/LeftRightChart/translations";
import { enBaseSelect, elBaseSelect } from "@common/BaseSelect/translations";
import { enBaseFields, elBaseFields } from "@common/BaseFields/translations";
import { elBaseButton, enBaseButton } from "@common/BaseButton/translations";

export const enCommon = {
  ...enDateDisplay,
  ...enMenu,
  ...enBaseDatePicker,
  ...enTradingChart,
  ...enLeftRightChart,
  ...enBaseSelect,
  ...enBaseFields,
  ...enBaseButton,
};

export const elCommon = {
  ...elDateDisplay,
  ...elMenu,
  ...elBaseDatePicker,
  ...elTradingChart,
  ...elLeftRightChart,
  ...elBaseSelect,
  ...elBaseFields,
  ...elBaseButton,
};
