import React from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { TAppState } from "@store/reducers";
import { chartsSelector } from "@components/selectors";
import BaseDialog from "@common/BaseDialog/BaseDialog";
import ChartLiveFields from "@pages/Charts/Live/Dialog/ChartsLiveFields";
import ChartLiveInfo from "@pages/Charts/Live/Dialog/ChartsLiveInfo";
import ChartLiveTopBar from "@pages/Charts/Live/Dialog/ChartsLiveTopBar";

const ChartsLiveDialog: React.FC = () => {
  const {
    live: {
      popup: { active },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  return (
    <Stack
      sx={{
        width: "100%",
        background: "rgba(96, 130, 182, 0.6)",
        flexDirection: "row",
      }}
    >
      <BaseDialog
        open={active}
        fullScreen
        padding={2}
        content={
          <Box>
            <ChartLiveTopBar />
            <ChartLiveFields />
            <ChartLiveInfo />
          </Box>
        }
      />
    </Stack>
  );
};

export default ChartsLiveDialog;
