import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { PERIODS_FETCH_LAST_SYNCED } from "@actions/_types";
import { setInterceptors, clearInterceptors } from "@features/interceptors/app";
import { periodsUrl } from "@constants/endpoints";

type TLastSyncedPayload = {
  periodId: number | null;
  offset?: number | null;
};

export const fetchLastSynced = createAsyncThunk(
  PERIODS_FETCH_LAST_SYNCED,
  async (params: TLastSyncedPayload) => {
    const { periodId, offset = null } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${periodsUrl}/last?periodId=${periodId}${
        offset !== null ? `&offset=${offset}` : ``
      }`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);
