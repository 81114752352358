import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "@store/reducers";
import TradingChart from "@common/TradingChart/TradingChart";
import { backtestingSelector } from "@components/selectors";
import { parseCandles, parseSignals } from "@pages/Admin/Backtesting/helpers";

type Props = {
  autoSize?: boolean;
  autoHeight?: boolean;
  autoWidth?: boolean;
  height?: number;
};

const BacktestingChart: React.FC<Props> = ({
  height = 500,
  autoSize = false,
  autoHeight = false,
  autoWidth = true,
}) => {
  const { json } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );
  const { candles, signals } = json;
  const { symbol, candleData } = parseCandles(candles);
  const signalMarkers = parseSignals(signals);

  return (
    <Box sx={{ width: "100%" }}>
      <TradingChart
        autoSize={autoSize}
        autoHeight={autoHeight}
        autoWidth={autoWidth}
        height={height}
        fitContent
        symbol={symbol}
        candleData={candleData}
        markers={signalMarkers}
      />
    </Box>
  );
};

export default BacktestingChart;
