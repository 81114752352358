import { io } from "socket.io-client";
import { API } from "@constants/settings";

export const socket = io(API.WS_URL, {
  transports: ["websocket"],
  autoConnect: true,
  reconnection: true,
  reconnectionDelay: 10000,
  reconnectionDelayMax: 15000,
  reconnectionAttempts: 5,
});
