import i18n from "i18next";
import { TIndicatorConfig, TIndicatorsConfig } from "@app/types/entities";
import { LogLevel, LogType } from "@constants/enums";

export const createIndicatorsConfig = (period: any, indicators: Array<any>) => {
  let result: TIndicatorsConfig = {
    price: [],
    range: [],
    noRange: [],
    histogram: [],
    point: [],
  };
  for (const indicator of indicators) {
    const { CODE, config } = indicator;
    const { parameters, properties } = config;
    const { isPrice, isPoint, hasRange_0_100, hasNoRange, isHistogram } =
      properties;
    if (parameters[period.CODE]) {
      const executions = parameters[period.CODE];
      let counter = 1;
      const upper = CODE.toUpperCase();
      let config: TIndicatorConfig = {
        code: CODE,
        label: i18n.t(`INDICATOR_DISPLAY_LABEL_${upper}`),
        executions: [],
      };
      for (const execution of executions) {
        const { output } = execution;
        const variation = {
          code: `${CODE}_${counter}`,
          label: i18n.t(`INDICATOR_LABEL_${upper}_${counter}`),
          output: output.map((key: any) => key),
        };
        config.executions = [...config.executions, variation];
        counter++;
      }
      if (isPoint) result.point.push(config);
      else if (isPrice) result.price.push(config);
      else if (hasRange_0_100) result.range.push(config);
      else if (hasNoRange) result.noRange.push(config);
      else if (isHistogram) result.histogram.push(config);
    } else {
      console.log(`Indicator ${CODE} does not exist for period ${period.CODE}`);
    }
  }
  return result;
};

export const extendIndicatorsConfig = (
  config: Array<TIndicatorConfig>,
  extendFn: (key: string) => any
) =>
  config
    ? config.map((indicator) => ({
        ...indicator,
        executions: indicator.executions.map((execution) => ({
          ...execution,
          output: execution.output.map((key: string) => ({
            key,
            ...extendFn(key),
          })),
        })),
      }))
    : [];

export const getLogLevelColor = (level: number) => {
  switch (level) {
    case LogLevel.Info:
      return "#7CFC00";
    case LogLevel.Warning:
      return "#FFAC1C";
    case LogLevel.Error:
      return "#FF3131";
    default:
      return "#1F51FF";
  }
};

export const getLogLevelText = (level: number) => {
  switch (level) {
    case LogLevel.Info:
      return i18n.t("LOG_LEVEL_INFO_LABEL");
    case LogLevel.Warning:
      return i18n.t("LOG_LEVEL_WARNING_LABEL");
    case LogLevel.Error:
      return i18n.t("LOG_LEVEL_ERROR_LABEL");
    default:
      return i18n.t("LOG_LEVEL_UNKNOWN_LABEL");
  }
};

export const getLogTypeText = (type: string) => {
  switch (type) {
    case LogType.Sync:
      return i18n.t("LOG_TYPE_SYNC_LABEL");
    case LogType.Calculation:
      return i18n.t("LOG_TYPE_CALCULATION_LABEL");
    case LogType.Report:
      return i18n.t("LOG_TYPE_REPORT_LABEL");
    case LogType.Mail:
      return i18n.t("LOG_TYPE_MAIL_LABEL");
    case LogType.User:
      return i18n.t("LOG_TYPE_USER_LABEL");
    case LogType.Security:
      return i18n.t("LOG_TYPE_SECURITY_LABEL");
    case LogType.Generic:
      return i18n.t("LOG_TYPE_GENERIC_LABEL");
    default:
      return i18n.t("LOG_TYPE_UNKNOWN_LABEL");
  }
};
