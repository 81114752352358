import { has } from "lodash";
import { TChartCandles, TChartMarkers } from "@app/types/entities";
import { parseDbDates } from "@utilities/helpers";

export const parseCandles = (json) => {
  let candleData: TChartCandles = [];
  let symbol = "";
  try {
    const data = JSON.parse(json);
    symbol = data.symbol;
    for (const candle of data.candles) {
      const time = parseDbDates(candle.OPENED_AT);
      candleData.push({
        time,
        open: candle.OPEN,
        close: candle.CLOSE,
        high: candle.HIGH,
        low: candle.LOW,
      });
    }
  } catch (e) {}
  return { symbol, candleData };
};

export const parseSignals = (json) => {
  let signalMarkers: TChartMarkers = [];
  try {
    const data = JSON.parse(json);
    for (const row of data) {
      for (const signal of row.signals) {
        const time = parseDbDates(signal.OPENED_AT);
        if (signal.BUY === 1 || signal.SELL === 1) {
          const renderText = has(signal, "RENDER_TEXT")
            ? `/ ${signal.RENDER_TEXT}`
            : "";
          const text =
            signal.BUY === 1
              ? `${row.name} (B) ${renderText}`
              : `${row.name} (S) ${renderText}`;
          signalMarkers.push({
            time: {
              year: time.year,
              month: time.month,
              day: time.day,
            },
            position: signal.BUY === 1 ? "belowBar" : "aboveBar",
            color: signal.BUY === 1 ? "#32cd32" : "#ff2800",
            shape: signal.BUY === 1 ? "arrowUp" : "arrowDown",
            text,
          });
        }
      }
    }
  } catch (e) {}
  return signalMarkers;
};

export const parseSymbol = (json) => {
  let pairName: string = "";
  try {
    const data = JSON.parse(json);
    pairName = data.symbol;
  } catch (e) {}
  return pairName;
};

export const parseSignalNames = (json: any) => {
  let signalNames: Array<string> = [];
  try {
    const data = JSON.parse(json);
    for (const row of data) {
      signalNames.push(row.name);
    }
  } catch (e) {}
  return signalNames;
};
