import { API } from "@constants/settings";

export const periodsUrl = `${API.HTTP_URL}/periods`;
export const candlesUrl = `${API.HTTP_URL}/candles`;
export const patternsUrl = `${API.HTTP_URL}/patterns`;
export const signalsUrl = `${API.HTTP_URL}/signals`;
export const analysesUrl = `${API.HTTP_URL}/analyses`;
export const economyUrl = `${API.HTTP_URL}/economy`;
export const marketUrl = `${API.HTTP_URL}/market`;
export const userUrl = `${API.HTTP_URL}/user`;
export const appUrl = `${API.HTTP_URL}/app`;
export const appLogsUrl = `${API.HTTP_URL}/logs/app`;
export const cronTasksUrl = `${API.HTTP_URL}/cron-tasks`;

const extraTimeout = [candlesUrl];

export const checkForExtendedTimeout = (url: string | undefined) =>
  url !== undefined && extraTimeout.filter((u) => url.startsWith(u)).length > 0;
