import { enMenu, elMenu } from "@general/Menu/translations";
import { enHeader, elHeader } from "@general/Header/translations";
import { enFooter, elFooter } from "@general/Footer/translations";
import {
  enNotifications,
  elNotifications,
} from "@general/Notifications/translations";
import {
  enSettingsDialog,
  elSettingsDialog,
} from "@general/SettingsDialog/translations";

export const enApp = {
  APP_LANGUAGE_LABEL: "Language",
  APP_LANGUAGE_OPTION_EL: "Ελληνικά",
  APP_LANGUAGE_OPTION_EN: "English",
  APP_THEME_LABEL: "Theme",
  APP_THEME_OPTION_LIGHT: "Light",
  APP_THEME_OPTION_DARK: "Dark",
  ...enMenu,
  ...enHeader,
  ...enFooter,
  ...enNotifications,
  ...enSettingsDialog,
};

export const elApp = {
  APP_LANGUAGE_LABEL: "Γλώσσα",
  APP_LANGUAGE_OPTION_EL: "Ελληνικά",
  APP_LANGUAGE_OPTION_EN: "English",
  APP_THEME_LABEL: "Θέμα",
  APP_THEME_OPTION_LIGHT: "Φωτεινό",
  APP_THEME_OPTION_DARK: "Σκοτεινό",
  ...elMenu,
  ...elHeader,
  ...elFooter,
  ...elNotifications,
  ...elSettingsDialog,
};
