import { createSlice } from "@reduxjs/toolkit";
import {
  fetchRankings,
  fetchFearAndGreed,
  fetchMetrics,
} from "@app/store/actions/markets";
import { AXIOS_STATUSES } from "@app/types/entities";

export interface TMarketsState {
  rankings: {
    dataset: {
      periods: Array<any>;
      assetTypes: Array<any>;
    };
    filters: {
      period: any | null;
      assetType: any | null;
      valuedAt: any | null;
    };
    data: Array<any>;
  };
  fearAndGreed: {
    crypto: Array<any>;
    stocks: Array<any>;
  };
  metrics: {
    crypto: {
      dataset: {
        periods: Array<any>;
        assets: Array<any>;
      };
      filters: {
        period: any | null;
        asset: any | null;
      };
      data: Array<any>;
    };
  };
  status: string | null;
}

const initialState: TMarketsState = {
  rankings: {
    dataset: {
      periods: [],
      assetTypes: [],
    },
    filters: {
      period: null,
      assetType: null,
      valuedAt: null,
    },
    data: [],
  },
  metrics: {
    crypto: {
      dataset: {
        periods: [],
        assets: [],
      },
      filters: {
        period: null,
        asset: null,
      },
      data: [],
    },
  },
  fearAndGreed: {
    crypto: [],
    stocks: [],
  },
  status: null,
};

const marketsSlice = createSlice({
  name: "markets",
  initialState,
  reducers: {
    rankingsDatasetPeriodsChanged: (state, action) => {
      state.rankings.dataset.periods = action.payload;
    },
    rankingsDatasetAssetTypesChanged: (state, action) => {
      state.rankings.dataset.assetTypes = action.payload;
    },
    rankingsPeriodChanged: (state, action) => {
      state.rankings.filters.period = action.payload;
    },
    rankingsAssetTypeChanged: (state, action) => {
      state.rankings.filters.assetType = action.payload;
    },
    rankingsValuedAtChanged: (state, action) => {
      state.rankings.filters.valuedAt = action.payload;
    },
    cryptoMetricsPeriodChanged: (state, action) => {
      state.metrics.crypto.filters.period = action.payload;
    },
    cryptoMetricsAssetChanged: (state, action) => {
      state.metrics.crypto.filters.asset = action.payload;
    },
    cryptoMetricsDatasetPeriodsChanged: (state, action) => {
      state.metrics.crypto.dataset.periods = action.payload;
    },
    cryptoMetricsDatasetAssetsChanged: (state, action) => {
      state.metrics.crypto.dataset.assets = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRankings.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchRankings.fulfilled, (state, action) => {
        state.rankings.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchRankings.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchFearAndGreed.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchFearAndGreed.fulfilled, (state, action) => {
        const { meta } = action;
        const { type } = meta.arg;
        if (type === "crypto") state.fearAndGreed.crypto = action.payload;
        else if (type === "stock") state.fearAndGreed.stocks = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchFearAndGreed.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchMetrics.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.metrics.crypto.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchMetrics.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      });
  },
});

export const {
  rankingsDatasetPeriodsChanged,
  rankingsDatasetAssetTypesChanged,
  rankingsPeriodChanged,
  rankingsAssetTypeChanged,
  rankingsValuedAtChanged,
  cryptoMetricsPeriodChanged,
  cryptoMetricsAssetChanged,
  cryptoMetricsDatasetPeriodsChanged,
  cryptoMetricsDatasetAssetsChanged,
} = marketsSlice.actions;
export default marketsSlice.reducer;
