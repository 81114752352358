import { createSlice } from "@reduxjs/toolkit";
import { first } from "lodash";
import {
  fetchAnalysisByOpenedAt,
  fetchAnalysisByPair,
} from "@actions/analysis";
import { AXIOS_STATUSES } from "@app/types/entities";

export interface TAnalysisState {
  list: {
    data: Array<any>;
    dataset: {
      periods: Array<any>;
      analyses: Array<any>;
    };
    filters: {
      analysis: any | null;
      period: any | null;
      openedAt: any | null;
    };
  };
  chart: {
    symbol: string;
    name?: string;
    data: Array<any>;
    dataset: {
      periods: Array<any>;
      analyses: Array<any>;
      pairs: Array<any>;
    };
    filters: {
      analysis: any | null;
      period: any | null;
      pair: any | null;
    };
    activeMetrics: Array<any>;
  };
  status: string | null;
}

const initialState: TAnalysisState = {
  list: {
    data: [],
    dataset: {
      periods: [],
      analyses: [],
    },
    filters: {
      analysis: null,
      period: null,
      openedAt: null,
    },
  },
  chart: {
    symbol: "",
    name: "",
    data: [],
    dataset: {
      periods: [],
      analyses: [],
      pairs: [],
    },
    filters: {
      analysis: null,
      period: null,
      pair: null,
    },
    activeMetrics: [],
  },
  status: null,
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    listPeriodChanged: (state, action) => {
      state.list.filters.period = action.payload;
    },
    listAnalysisChanged: (state, action) => {
      state.list.filters.analysis = action.payload;
    },
    listOpenedAtChanged: (state, action) => {
      state.list.filters.openedAt = action.payload;
    },
    listDatasetPeriodsChanged: (state, action) => {
      state.list.dataset.periods = action.payload;
    },
    listDatasetAnalysesChanged: (state, action) => {
      state.list.dataset.analyses = action.payload;
    },
    chartPeriodChanged: (state, action) => {
      state.chart.filters.period = action.payload;
    },
    chartPairChanged: (state, action) => {
      state.chart.filters.pair = action.payload;
      if (action.payload === null) {
        state.chart.symbol = "";
        state.chart.name = "";
      }
    },
    chartAnalysisChanged: (state, action) => {
      state.chart.filters.analysis = action.payload;
    },
    chartDataChanged: (state, action) => {
      state.chart.data = action.payload;
    },
    chartActiveMetricsChanged: (state, action) => {
      state.chart.activeMetrics = action.payload;
    },
    chartActiveMetricChanged: (state, action) => {
      const { value, checked } = action.payload;
      const active = state.chart.activeMetrics;
      state.chart.activeMetrics = !checked
        ? active.filter((m) => m !== value)
        : [...active, value];
    },
    chartDatasetPeriodsChanged: (state, action) => {
      state.chart.dataset.periods = action.payload;
    },
    chartDatasetPairsChanged: (state, action) => {
      state.chart.dataset.pairs = action.payload;
    },
    chartDatasetAnalysesChanged: (state, action) => {
      state.chart.dataset.analyses = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalysisByOpenedAt.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchAnalysisByOpenedAt.fulfilled, (state, action) => {
        state.list.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchAnalysisByOpenedAt.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchAnalysisByPair.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchAnalysisByPair.fulfilled, (state, action) => {
        const row = first(action.payload);
        state.chart.data = action.payload;
        state.chart.symbol = row?.SYMBOL.replaceAll(row.SYMBOL_PREFIX, "");
        state.chart.name = first(action.payload)?.NAME;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchAnalysisByPair.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      });
  },
});

export const {
  listPeriodChanged,
  listOpenedAtChanged,
  listAnalysisChanged,
  listDatasetPeriodsChanged,
  listDatasetAnalysesChanged,
  chartPeriodChanged,
  chartPairChanged,
  chartAnalysisChanged,
  chartDataChanged,
  chartActiveMetricsChanged,
  chartActiveMetricChanged,
  chartDatasetPeriodsChanged,
  chartDatasetPairsChanged,
  chartDatasetAnalysesChanged,
} = analysisSlice.actions;

export default analysisSlice.reducer;
