/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

const TaskActions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "0px 10px 0px 10px",
        marginBottom: "20px",
        height: 40,
      }}
    >
      <Box sx={{ flex: 5 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t("ADMIN_SCHEDULED_TASKS_HEADER")}
        </Typography>
      </Box>
    </Box>
  );
};

export default TaskActions;
