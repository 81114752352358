import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ThunkDispatch } from "redux-thunk";
import { UnknownAction } from "@reduxjs/toolkit";
import i18n from "i18next";
import { API } from "@constants/settings";
import { ROUTES } from "@constants/routes";
import { THttpResponseBody } from "@app/types/entities";
import { history } from "@utilities/history";
import { httpErrorChanged } from "@store/reducers/common";
import { changeLoggedIn } from "@store/reducers/user";
import { getSettingsInStorage, removeUserInStorage } from "@utilities/helpers";

const handleErrors = async (
  dispatch: ThunkDispatch<any, any, UnknownAction>,
  queryFulfilled: any
) => {
  try {
    await queryFulfilled;
  } catch (e: any) {
    const status = e?.error?.status;
    const errorMessage = e?.error?.extra?.message;
    dispatch(
      httpErrorChanged({
        errorStatus: true,
        errorMessage:
          errorMessage || i18n.t(`NOTIFICATIONS_HTTP_ERROR_${status}`),
      })
    );
    if (status === 401) {
      const settings = getSettingsInStorage();
      const { authActive = null } = settings;
      removeUserInStorage();
      dispatch(changeLoggedIn(false));
      history.replace(authActive ? ROUTES.LOGIN : ROUTES.HOME);
    }
  }
};

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API.HTTP_URL,
    timeout: API.TIMEOUT,
    credentials: "include",
    validateStatus: (response: Response, body: THttpResponseBody) =>
      response.status === 200 && body.success,
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getActivePeriods: build.query<any, void>({
      query: () => "/periods/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getSourcePeriods: build.query<any, void>({
      query: () => "/periods/source",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActivePairs: build.query<any, void>({
      query: () => "/pairs/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getLivePairs: build.query<any, void>({
      query: () => "/pairs/live",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getLiveTechnicalProviders: build.query<any, void>({
      query: () => "/providers/technical/live",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getAssetTypes: build.query<any, void>({
      query: () => "/asset-types",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getMetricCryptoAssets: build.query<any, void>({
      query: () => "/market/metrics/crypto-assets",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActiveIndicators: build.query<any, void>({
      query: () => "/indicators/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActivePatterns: build.query<any, void>({
      query: () => "/patterns/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActiveSignals: build.query<any, void>({
      query: () => "/signals/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActiveAnalyses: build.query<any, void>({
      query: () => "/analyses/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getEconomyGroups: build.query<any, void>({
      query: () => "/economy/groups/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getEconomyRecession: build.query<any, void>({
      query: () => "/economy/recession",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
  }),
});

export const {
  useGetActivePeriodsQuery,
  useGetActivePairsQuery,
  useGetLivePairsQuery,
  useGetLiveTechnicalProvidersQuery,
  useGetAssetTypesQuery,
  useGetMetricCryptoAssetsQuery,
  useGetActiveIndicatorsQuery,
  useGetActivePatternsQuery,
  useGetActiveSignalsQuery,
  useGetActiveAnalysesQuery,
  useGetEconomyGroupsQuery,
  useGetEconomyRecessionQuery,
} = api;
