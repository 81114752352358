import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Grid from "@common/Grid/Grid";
import { TAppState } from "@store/reducers";
import { marketsSelector } from "@components/selectors";
import getColumns, {
  gridKey,
  getVisibility,
  getSortModel,
  getTogglableColumns,
} from "@pages/Markets/Rankings/Grid/RankingsGridConfig";
import { convertRankings } from "@components/Common/helpers";

const RankingsGrid: React.FC = () => {
  const {
    rankings: {
      filters: { assetType },
      data,
    },
  } = useSelector((state: TAppState) => marketsSelector(state));

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Grid
        gridKey={gridKey}
        data={convertRankings(assetType?.CODE, data)}
        columns={getColumns()}
        visibility={getVisibility()}
        sortModel={getSortModel()}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          columnsManagement: {
            getTogglableColumns,
          },
        }}
      />
    </Box>
  );
};

export default RankingsGrid;
