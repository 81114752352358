import {
  enTaskActions,
  elTaskActions,
} from "@pages/Admin/ScheduledTasks/TaskActions/translations";
import {
  enTaskItem,
  elTaskItem,
} from "@pages/Admin/ScheduledTasks/TaskItem/translations";
import {
  enTaskList,
  elTaskList,
} from "@pages/Admin/ScheduledTasks/TaskList/translations";

export const enScheduledTasks = {
  ADMIN_SCHEDULED_TASKS_TAB_LABEL: "Scheduled Tasks",
  ADMIN_SCHEDULED_TASKS_HEADER: "Scheduled Tasks",
  ...enTaskActions,
  ...enTaskItem,
  ...enTaskList,
};

export const elScheduledTasks = {
  ADMIN_SCHEDULED_TASKS_TAB_LABEL: "Προγραμματισμένες Εργασίες",
  ADMIN_SCHEDULED_TASKS_HEADER: "Προγραμματισμένες Εργασίες",
  ...elTaskActions,
  ...elTaskItem,
  ...elTaskList,
};
