export const enRankingFaq = {
  MARKETS_RANKING_FAQ_TITLE_MC_RANK: "What is Market Cap Rank?",
  MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK:
    "Market Cap Rank is the position of the asset if ranked by market cap (lower rank number means higher market cap).",
  MARKETS_RANKING_FAQ_TITLE_MC_RANK_1D: "What is Market Cap Rank (1D)?",
  MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK_1D:
    "Market Cap Rank (1D) is the market cap position(s) that an asset gained (positive) or lost (negative) in the last day.",
  MARKETS_RANKING_FAQ_TITLE_MC_RANK_7D: "What is Market Cap Rank (7D)?",
  MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK_7D:
    "Market Cap Rank (7D) is the market cap position(s) that an asset gained (positive) or lost (negative) in the last 7 days.",
  MARKETS_RANKING_FAQ_TITLE_MC_RANK_30D: "What is Market Cap Rank (30D)?",
  MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK_30D:
    "Market Cap Rank (30D) is the market cap position(s) that an asset gained (positive) or lost (negative) in the last 30 days.",
  MARKETS_RANKING_FAQ_TITLE_LQ_RANK: "What is Liquidity Rank?",
  MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK:
    "Liquidity Rank is the position of the asset if ranked by liquiditiy (lower rank number means higher liquidity).",
  MARKETS_RANKING_FAQ_TITLE_LQ_RANK_1D: "What is Liquidity Rank (1D)?",
  MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK_1D:
    "Liquidity Rank (1D) is the liquidity position(s) that an asset gained (positive) or lost (negative) in the last day.",
  MARKETS_RANKING_FAQ_TITLE_LQ_RANK_7D: "What is Liquidity Rank (7D)?",
  MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK_7D:
    "Liquidity Rank (7D) is the liquidity position(s) that an asset gained (positive) or lost (negative) in the last 7 days.",
  MARKETS_RANKING_FAQ_TITLE_LQ_RANK_30D: "What is Liquidity Rank (30D)?",
  MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK_30D:
    "Liquidity Rank (30D) is the liquidity position(s) that an asset gained (positive) or lost (negative) in the last 30 days.",
  MARKETS_RANKING_FAQ_TITLE_VL_RANK: "What is Volume Rank?",
  MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK:
    "Volume Rank is the position of the asset if ranked by volume in U.S. dollars (lower rank number means higher volume).",
  MARKETS_RANKING_FAQ_TITLE_VL_RANK_1D: "What is Volume Rank (1D)?",
  MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK_1D:
    "Volume Rank (1D) is the volume position(s) that an asset gained (positive) or lost (negative) in the last day.",
  MARKETS_RANKING_FAQ_TITLE_VL_RANK_7D: "What is Volume Rank (7D)?",
  MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK_7D:
    "Liquidity Rank (7D) is the volume position(s) that an asset gained (positive) or lost (negative) in the last 7 days.",
  MARKETS_RANKING_FAQ_TITLE_VL_RANK_30D: "What is Volume Rank (30D)?",
  MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK_30D:
    "Liquidity Rank (30D) is the volume position(s) that an asset gained (positive) or lost (negative) in the last 30 days.",
};

export const elRankingFaq = {
  MARKETS_RANKING_FAQ_TITLE_MC_RANK: "Τι είναι η Κατάταξη Κεφαλαιοποίησης;",
  MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK:
    "Η Κατάταξη Κεφαλαιοποίησης είναι η θέση του περιουσιακού στοιχείου εάν καταταχθεί κατά κεφαλαιοποίηση (χαμηλότερος αριθμός κατάταξης σημαίνει υψηλότερη κεφαλαιοποίηση αγοράς).",
  MARKETS_RANKING_FAQ_TITLE_MC_RANK_1D:
    "Τι είναι η Κατάταξη Κεφαλαιοποίησης (1D);",
  MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK_1D:
    "Η Κατάταξη Κεφαλαιοποίησης (1D) είναι η θέση(εις) κεφαλαιοποίησης που κέρδισε (θετικό) ή έχασε (αρνητικό) ένα περιουσιακό στοιχείο την τελευταία ημέρα.",
  MARKETS_RANKING_FAQ_TITLE_MC_RANK_7D:
    "Τι είναι η Κατάταξη Κεφαλαιοποίησης (7D);",
  MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK_7D:
    "Η Κατάταξη Κεφαλαιοποίησης (7D) είναι οι θέσεις κεφαλαιοποίησης που κέρδισε (θετικό) ή έχασε (αρνητικό) ένα περιουσιακό στοιχείο τις τελευταίες 7 ημέρες.",
  MARKETS_RANKING_FAQ_TITLE_MC_RANK_30D:
    "Τι είναι η Κατάταξη Κεφαλαιοποίησης (30D);",
  MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK_30D:
    "Η Κατάταξη Κεφαλαιοποίησης (30D) είναι η θέση(εις) κεφαλαιοποίησης που κέρδισε (θετικό) ή έχασε (αρνητικό) ένα περιουσιακό στοιχείο τις τελευταίες 30 ημέρες.",
  MARKETS_RANKING_FAQ_TITLE_LQ_RANK: "Τι είναι η Κατάταξη Ρευστότητας;",
  MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK:
    "Κατάταξη Ρευστότητας είναι η θέση του περιουσιακού στοιχείου εάν κατατάσσεται βάσει ρευστότητας (ο χαμηλότερος αριθμός κατάταξης σημαίνει υψηλότερη ρευστότητα).",
  MARKETS_RANKING_FAQ_TITLE_LQ_RANK_1D: "Τι είναι η Κατάταξη Ρευστότητας (1D);",
  MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK_1D:
    "Κατάταξη Ρευστότητας (1D) είναι η(οι) θέση(εις) ρευστότητας που ένα περιουσιακό στοιχείο κέρδισε (θετικό) ή έχασε (αρνητικό) την τελευταία ημέρα.",
  MARKETS_RANKING_FAQ_TITLE_LQ_RANK_7D: "Τι είναι η Κατάταξη Ρευστότητας (7D);",
  MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK_7D:
    "Κατάταξη Ρευστότητας (7D) είναι η(οι) θέση(εις) ρευστότητας που ένα περιουσιακό στοιχείο κέρδισε (θετικό) ή έχασε (αρνητικό) τις τελευταίες 7 ημέρες.",
  MARKETS_RANKING_FAQ_TITLE_LQ_RANK_30D:
    "Τι είναι η Κατάταξη Ρευστότητας (30D);",
  MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK_30D:
    "Κατάταξη Ρευστότητας (30D) είναι η(οι) θέση(εις) ρευστότητας που ένα περιουσιακό στοιχείο κέρδισε (θετικό) ή έχασε (αρνητικό) τις τελευταίες 30 ημέρες.",

  MARKETS_RANKING_FAQ_TITLE_VL_RANK: "Τι είναι η Κατάταξη Όγκου;",
  MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK:
    "Κατάταξη Όγκου είναι η θέση του περιουσιακού στοιχείου εάν κατατάσσεται βάσει όγκου συναλλαγών σε αμερικανικά δολλάρια (ο χαμηλότερος αριθμός κατάταξης σημαίνει υψηλότερo όγκο).",
  MARKETS_RANKING_FAQ_TITLE_VL_RANK_1D: "Τι είναι η Κατάταξη Όγκου (1D);",
  MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK_1D:
    "Κατάταξη Όγκου (1D) είναι η(οι) θέση(εις) όγκου συναλλαγών που ένα περιουσιακό στοιχείο κέρδισε (θετικό) ή έχασε (αρνητικό) την τελευταία ημέρα.",
  MARKETS_RANKING_FAQ_TITLE_VL_RANK_7D: "Τι είναι η Κατάταξη Όγκου (7D);",
  MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK_7D:
    "Κατάταξη Όγκου (7D) είναι η(οι) θέση(εις) όγκου συναλλαγών που ένα περιουσιακό στοιχείο κέρδισε (θετικό) ή έχασε (αρνητικό) τις τελευταίες 7 ημέρες.",
  MARKETS_RANKING_FAQ_TITLE_VL_RANK_30D: "Τι είναι η Κατάταξη Όγκου (30D);",
  MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK_30D:
    "Κατάταξη Ρευστότητας (30D) είναι η(οι) θέση(εις) όγκου συναλλαγών που ένα περιουσιακό στοιχείο κέρδισε (θετικό) ή έχασε (αρνητικό) τις τελευταίες 30 ημέρες.",
};
