import React from "react";
import { Box } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ChartJsDataset } from "@app/types/entities";

type Props = {
  isResponsive?: boolean;
  keepAspectRatio?: boolean;
  titlePosition?: "top" | "left" | "right" | "bottom" | undefined;
  legendPosition?: "top" | "center" | "left" | "right" | "bottom" | "chartArea";
  title?: string;
  displayTitle?: boolean;
  displayLegend?: boolean;
  labels?: Array<string>;
  datasets?: Array<ChartJsDataset>;
  height?: number | string;
  pointRadius?: number;
};

const LineChart: React.FC<Props> = ({
  isResponsive = true,
  keepAspectRatio = false,
  legendPosition = "top",
  titlePosition = "top",
  title = "",
  displayTitle = true,
  displayLegend = false,
  labels = [],
  datasets = [{ label: "", data: [] }],
  height = 300,
  pointRadius = 0,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  ChartJS.defaults.color = "#ffffff";
  ChartJS.defaults.font.family = "Calibri";
  ChartJS.defaults.font.size = 10;

  const options = {
    responsive: isResponsive,
    maintainAspectRatio: keepAspectRatio,
    plugins: {
      legend: {
        display: displayLegend,
        position: `${legendPosition}` as const,
      },
      title: {
        display: displayTitle,
        text: title,
        position: `${titlePosition}` as const,
      },
    },
    elements: {
      point: {
        radius: pointRadius,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgba(214, 220, 222, 0.10)",
          lineWidth: 0.5,
        },
      },
      y: {
        grid: {
          display: true,
          color: "rgba(214, 220, 222, 0.10)",
          lineWidth: 0.5,
        },
      },
    },
  };

  return (
    <Box
      sx={{
        background: "#253248",
      }}
    >
      <Line height={height} options={options} data={{ labels, datasets }} />
    </Box>
  );
};

export default LineChart;
