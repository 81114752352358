import React, { useEffect, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { Box, Stack } from "@mui/material";
import { AppDispatch } from "@store/configureStore";
import BaseSelect from "@common/BaseSelect/BaseSelect";
import { TAppState } from "@store/reducers";
import { DeleteButton, UploadButton } from "@common/BaseButton/Buttons";
import {
  liveJsonChanged,
  liveDataChanged,
  liveIndicatorsChanged,
  livePatternsChanged,
  liveSignalsChanged,
  livePeriodChanged,
  livePairChanged,
  liveProviderChanged,
  liveDatasetIndicatorsChanged,
  liveDatasetPatternsChanged,
  liveDatasetSignalsChanged,
  toggleLiveMode,
  liveDatasetPeriodsChanged,
} from "@store/reducers/charts";
import {
  useGetActivePeriodsQuery,
  useGetActiveIndicatorsQuery,
  useGetActivePatternsQuery,
  useGetActiveSignalsQuery,
} from "@features/api";
import { chartsSelector } from "@components/selectors";
import {
  filterIndicators,
  filterPairsByProvider,
  filterPatterns,
  filterSignals,
} from "@utilities/filters";

const ChartsFilterArea: React.FC = () => {
  const { t } = useTranslation();
  const [, startTransition] = useTransition();
  const dispatch = useDispatch<AppDispatch>();
  const { data: periodData, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: signalData, isLoading: signalsLoading } =
    useGetActiveSignalsQuery();
  const { data: indicatorData, isLoading: indicatorsLoading } =
    useGetActiveIndicatorsQuery();
  const { data: patternData, isLoading: patternsLoading } =
    useGetActivePatternsQuery();
  const {
    live: {
      data,
      dataset,
      filters: { period, indicators, patterns, signals },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  const resetFilters = () => {
    dispatch(livePairChanged([]));
    dispatch(liveJsonChanged(""));
    dispatch(liveDataChanged([]));
    dispatch(liveIndicatorsChanged([]));
    dispatch(livePatternsChanged([]));
    dispatch(liveSignalsChanged([]));
  };

  const periodSelected = (period: any) => {
    dispatch(livePeriodChanged(period));
    resetFilters();
  };

  const indicatorsSelected = (indicators: Array<any>) => {
    dispatch(liveIndicatorsChanged(indicators.map((i: any) => i.SELECT)));
  };

  const patternsSelected = (patterns: Array<any>) => {
    dispatch(livePatternsChanged(patterns.map((i: any) => i.SELECT)));
  };

  const signalsSelected = (signals: Array<any>) => {
    dispatch(liveSignalsChanged(signals));
  };

  useEffect(() => {
    if (periodData && !periodsLoading) {
      const filtered = periodData.filter(
        (period: any) => period.IS_SOURCE === 1
      );
      dispatch(liveDatasetPeriodsChanged(filtered));
      dispatch(livePeriodChanged(first(filtered)));
    }
  }, [dispatch, periodData, periodsLoading]);

  useEffect(() => {
    if (indicatorData && !indicatorsLoading) {
      dispatch(
        liveDatasetIndicatorsChanged(filterIndicators(indicatorData, period))
      );
    }
  }, [dispatch, period, indicatorData, indicatorsLoading]);

  useEffect(() => {
    if (patternData && !patternsLoading) {
      dispatch(liveDatasetPatternsChanged(filterPatterns(patternData, period)));
    }
  }, [dispatch, period, patternData, patternsLoading]);

  useEffect(() => {
    if (signalData && !signalsLoading) {
      dispatch(liveDatasetSignalsChanged(filterSignals(signalData, period)));
    }
  }, [dispatch, period, signalData, signalsLoading]);

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginBottom={5}
    >
      <Box>
        <BaseSelect
          data={dataset.periods}
          loading={periodsLoading}
          selected={[period?.CODE || ""]}
          onChange={periodSelected}
          selectedDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          optionsDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
        />
      </Box>
      <Box>
        <BaseSelect
          data={dataset.signals}
          loading={signalsLoading}
          onChange={signalsSelected}
          selectedDisplay={(row: any) =>
            `${t(`SIGNAL_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
          }
          optionsDisplay={(row: any) =>
            `${t(`SIGNAL_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
          }
          selected={signals.map((s: any) => s.CODE) || null}
          selectKey="CODE"
          multiselect
          inputLabel={t("SIGNAL_SELECT_INPUT_LABEL")}
          label={t("SIGNAL_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
        />
      </Box>
      <Box>
        <BaseSelect
          data={dataset.indicators}
          loading={indicatorsLoading}
          selected={indicators || ""}
          onChange={indicatorsSelected}
          multiselect
          inputLabel={t("INDICATOR_SELECT_INPUT_LABEL")}
          label={t("INDICATOR_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          groupKey="ID"
          headerKey="TEXT"
          selectKey="SELECT"
          displayKey="DISPLAY"
          selectedDisplay={(row: any) => `${row.TEXT}`}
        />
      </Box>
      <Box>
        <BaseSelect
          data={dataset.patterns}
          loading={patternsLoading}
          selected={patterns || ""}
          onChange={patternsSelected}
          multiselect
          inputLabel={t("PATTERN_SELECT_INPUT_LABEL")}
          label={t("PATTERN_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          groupKey="ID"
          headerKey="TEXT"
          selectKey="SELECT"
          displayKey="DISPLAY"
          selectedDisplay={(row: any) => `${row.TEXT}`}
        />
      </Box>
      <Box>
        <DeleteButton disabled={data.length === 0} onClick={resetFilters} />
      </Box>
      <Box>
        <UploadButton
          onClick={() => {
            startTransition(() => {
              if (data.length === 0) {
                const { providers, pairs } = dataset;
                dispatch(liveProviderChanged(first(providers)));
                dispatch(
                  livePairChanged(
                    first(filterPairsByProvider(pairs, first(providers)))
                  )
                );
              }
              dispatch(toggleLiveMode(true));
            });
          }}
        />
      </Box>
    </Stack>
  );
};

export default ChartsFilterArea;
