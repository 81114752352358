import { DateTime } from "luxon";
import { isNull, isArray, has, every } from "lodash";
import { SignalElapsed, SignalValues } from "@constants/enums";
import { TChartCandles, TChartLines } from "@app/types/entities";

const isValidJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return false;
  }
};

const isCandle = (candle: any) =>
  has(candle, "OPENED_AT") &&
  has(candle, "OPEN") &&
  has(candle, "CLOSE") &&
  has(candle, "HIGH") &&
  has(candle, "LOW") &&
  parseDbDates(candle.OPENED_AT).isValid;

const areCandles = (data: Array<any>) =>
  isArray(data) && every(data.map((c) => isCandle(c)));

const areBacktestingCandles = (data: any) =>
  has(data, "symbol") &&
  has(data, "candles") &&
  isArray(data.candles) &&
  every(data.candles.map((candle: any) => isCandle(candle)));

const areBacktestingSignals = (data: Array<any>) =>
  isArray(data) &&
  every(
    data.map(
      (type) =>
        has(type, "name") &&
        has(type, "signals") &&
        every(type.signals.map((signal: any) => isBacktestingSignal(signal)))
    )
  );

const isBacktestingSignal = (signal: any) =>
  has(signal, "OPENED_AT") &&
  has(signal, "BUY") &&
  has(signal, "SELL") &&
  parseDbDates(signal.OPENED_AT).isValid;

const validateEconomy = (economy: any): boolean => !isNull(economy.VALUE);

const validateMarketValue = (value: any, field: string): boolean =>
  !isNull(value[field]);

const validateFearGreed = (fearGreed: any): boolean =>
  !isNull(fearGreed.FG_VALUE);

const createChartMarket = (marketValues: any, field: string) => {
  let transformed: TChartLines = [];
  for (const marketValue of marketValues) {
    if (validateMarketValue(marketValue, field))
      transformed.push({
        time: parseDbDates(marketValue.VALUED_AT),
        value: marketValue[field],
      });
  }
  return transformed;
};

export const setThemeInStorage = (theme: any) => {
  localStorage.setItem("theme", theme);
};

export const getThemeInStorage = () => {
  return localStorage.getItem("theme") || "light";
};

export const setLanguageInStorage = (language: any) => {
  localStorage.setItem("language", language);
};

export const getLanguageInStorage = () => {
  return localStorage.getItem("language") || "en";
};

export const setUserInStorage = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUserInStorage = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user as string) : null;
};

export const removeUserInStorage = () => {
  localStorage.removeItem("user");
};

export const setSettingsInStorage = (settings: any) => {
  localStorage.setItem("settings", JSON.stringify(settings));
};

export const getSettingsInStorage = () => {
  const settings = localStorage.getItem("settings");
  return settings ? JSON.parse(settings as string) : null;
};

export const pretty = (json: string) => {
  return json !== "" ? JSON.stringify(JSON.parse(json), null, 2) : "";
};

export const parseDbDates = (date: any) => {
  const format = "yyyy-MM-dd HH:mm:ss";
  return DateTime.fromFormat(date, format, {
    zone: "utc",
  });
};

export const isBuy = (field: number, elapsed: number | boolean = false) => {
  return elapsed === false
    ? field === SignalValues.Buy
    : field === SignalValues.Buy && elapsed === SignalElapsed.One;
};

export const isSell = (field: number, elapsed: number | boolean = false) => {
  return elapsed === false
    ? field === SignalValues.Sell
    : field === SignalValues.Sell && elapsed === SignalElapsed.One;
};

export const isBullish = (field: number) => {
  return field === SignalValues.Bullish;
};

export const isBearish = (field: number) => {
  return field === SignalValues.Bearish;
};

export const isGolden = (field: number) => {
  return field === SignalValues.Golden;
};

export const isDeath = (field: number) => {
  return field === SignalValues.Death;
};

export const validateCandle = (candle: any): boolean =>
  !isNull(candle.OPEN) &&
  !isNull(candle.CLOSE) &&
  !isNull(candle.HIGH) &&
  !isNull(candle.LOW);

export const createChartCandles = (candleData: any) => {
  let transformed: TChartCandles = [];
  for (const candle of candleData) {
    if (validateCandle(candle))
      transformed.push({
        time: parseDbDates(candle.OPENED_AT),
        open: candle.OPEN,
        close: candle.CLOSE,
        high: candle.HIGH,
        low: candle.LOW,
      });
  }
  return transformed;
};

export const createChartEconomy = (economyData: any) => {
  let transformed: TChartLines = [];
  for (const economy of economyData) {
    if (validateEconomy(economy))
      transformed.push({
        time: parseDbDates(economy.DATE),
        value: economy.VALUE,
      });
  }
  return transformed;
};

export const createChartDominance = (marketValues: any) =>
  createChartMarket(marketValues, "DOM_PCNT");

export const createChartExchangesSupply = (marketValues: any) =>
  createChartMarket(marketValues, "EXSPLY_USD");

export const createChartExchangesInflows = (marketValues: any) =>
  createChartMarket(marketValues, "EXFLWIN_NTV");

export const createChartExchangesOutflows = (marketValues: any) =>
  createChartMarket(marketValues, "EXFLWOUT_NTV");

export const createChartFearGreed = (fearGreedData: any) => {
  let transformed: TChartLines = [];
  for (const fearGreed of fearGreedData) {
    if (validateFearGreed(fearGreed))
      transformed.push({
        time: parseDbDates(fearGreed.FG_AT),
        value: Math.round(fearGreed.FG_VALUE),
      });
  }
  return transformed;
};

export const createHorizontalLine = (
  economyData: any,
  value: number | null = null
) => {
  let transformed: TChartLines = [];
  if (value === null) return transformed;
  for (const economy of economyData) {
    transformed.push({
      time: parseDbDates(economy.DATE).toSeconds(),
      value,
    });
  }
  return transformed;
};

export const createMarker = (
  candle: any,
  markerConfig: Array<any>,
  selected: any,
  validatorFn: (marker: any, candle: any) => boolean
) => {
  let transformed: any = [];
  const fromFormat = parseDbDates(candle.OPENED_AT);
  if (validateCandle(candle)) {
    const selectedCode = selected.CODE;
    for (const config of markerConfig) {
      const { code, markers } = config;
      if (code === selectedCode || code === selected) {
        for (const marker of markers) {
          if (validatorFn(marker, candle)) {
            transformed.push({
              time: {
                year: fromFormat.year,
                month: fromFormat.month,
                day: fromFormat.day,
              },
              ...marker.config,
            });
          }
        }
      }
    }
  }
  return transformed;
};

export const disableDates = (period: any) => (date: any) =>
  !isActiveDate(period, date);

export const isActiveDate = (period: any, date: any) => {
  const code = period?.CODE;
  switch (code) {
    case "1w":
      return date.toFormat("EEEE") === "Monday";
    case "1m":
      return date.toFormat("dd") === "01";
    default:
      return true;
  }
};

export const getMaxDate = (period: any) => {
  const code = period?.CODE;
  switch (code) {
    case "1d":
    default:
      return DateTime.utc().startOf("day").minus({ days: 1 });
    case "1w":
      return DateTime.utc().startOf("week").minus({ weeks: 1 });
    case "1m":
      return DateTime.utc().startOf("month").minus({ months: 1 });
  }
};

export const isCandleJson = (json: string) =>
  isValidJson(json) ? areCandles(isValidJson(json)) : false;

export const isBacktestingCandleJson = (json: string) =>
  isValidJson(json) ? areBacktestingCandles(isValidJson(json)) : false;

export const isBacktestingSignalJson = (json: string) =>
  isValidJson(json) ? areBacktestingSignals(isValidJson(json)) : false;
