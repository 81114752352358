/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { Box, Stack } from "@mui/material";
import BaseSelect from "@common/BaseSelect/BaseSelect";
import { TAppState } from "@store/reducers";
import { AppDispatch } from "@store/configureStore";
import { marketsSelector } from "@components/selectors";
import {
  useGetActivePeriodsQuery,
  useGetMetricCryptoAssetsQuery,
} from "@features/api";
import {
  cryptoMetricsAssetChanged,
  cryptoMetricsDatasetAssetsChanged,
  cryptoMetricsDatasetPeriodsChanged,
  cryptoMetricsPeriodChanged,
} from "@store/reducers/markets";
import { fetchMetrics } from "@actions/markets";

const CryptoMetricsFilters: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { data: periodData, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: assetsData, isLoading: assetsLoading } =
    useGetMetricCryptoAssetsQuery();
  const {
    metrics: {
      crypto: {
        dataset: { periods, assets },
        filters: { period, asset },
      },
    },
  } = useSelector((state: TAppState) => marketsSelector(state));

  const periodSelected = (period: any) => {
    dispatch(cryptoMetricsPeriodChanged(period));
  };

  const assetSelected = (asset: any) => {
    dispatch(cryptoMetricsAssetChanged(asset));
  };

  useEffect(() => {
    if (periodData && !periodsLoading) {
      dispatch(cryptoMetricsDatasetPeriodsChanged(periodData));
      dispatch(cryptoMetricsPeriodChanged(first(periodData)));
    }
  }, [dispatch, periodData, periodsLoading]);

  useEffect(() => {
    if (assetsData && !assetsLoading) {
      dispatch(cryptoMetricsDatasetAssetsChanged(assetsData));
      dispatch(cryptoMetricsAssetChanged(first(assetsData)));
    }
  }, [dispatch, assetsData, assetsLoading]);

  useEffect(() => {
    if (period && asset) {
      dispatch(
        fetchMetrics({
          period,
          asset,
        })
      );
    }
  }, [dispatch, period, asset]);

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginTop={2}
      marginBottom={2}
    >
      <Box>
        <BaseSelect
          data={periods}
          loading={periodsLoading}
          onChange={periodSelected}
          selectedDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          optionsDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selected={[period?.CODE || ""]}
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
        />
      </Box>
      <Box>
        <BaseSelect
          data={assets}
          loading={assetsLoading}
          onChange={assetSelected}
          selected={[asset?.ID || ""]}
          selectKey="ID"
          inputLabel={t("CRYPTO_ASSET_SELECT_INPUT_LABEL")}
          label={t("CRYPTO_ASSET_SELECT_LABEL")}
          minWidth={200}
          maxWidth={200}
          selectedDisplay={(row: any) => `${row.NAME} (${row.SYMBOL})`}
          optionsDisplay={(row: any) => `${row.NAME} (${row.SYMBOL})`}
        />
      </Box>
    </Stack>
  );
};

export default CryptoMetricsFilters;
