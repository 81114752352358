export const enRankingGrid = {
  MARKETS_RANKING_GRID_ICON: "Icon",
  MARKETS_RANKING_GRID_SYMBOL: "Symbol",
  MARKETS_RANKING_GRID_NAME: "Name",
  MARKETS_RANKING_GRID_AGE: "Age",
  MARKETS_RANKING_GRID_MC_RANK: "Marketcap Rank",
  MARKETS_RANKING_GRID_MC_RANK_1D: "Marketcap Rank (1D)",
  MARKETS_RANKING_GRID_MC_RANK_7D: "Marketcap Rank (7D)",
  MARKETS_RANKING_GRID_MC_RANK_30D: "Marketcap Rank (30D)",
  MARKETS_RANKING_GRID_LQ_RANK: "Liquidity Rank",
  MARKETS_RANKING_GRID_LQ_RANK_1D: "Liquidity Rank (1D)",
  MARKETS_RANKING_GRID_LQ_RANK_7D: "Liquidity Rank (7D)",
  MARKETS_RANKING_GRID_LQ_RANK_30D: "Liquidity Rank (30D)",
  MARKETS_RANKING_GRID_VL_RANK: "Volume Rank",
  MARKETS_RANKING_GRID_VL_RANK_1D: "Volume Rank (1D)",
  MARKETS_RANKING_GRID_VL_RANK_7D: "Volume Rank (7D)",
  MARKETS_RANKING_GRID_VL_RANK_30D: "Volume Rank (30D)",
  MARKETS_RANKING_GRID_ID: "Id",
  MARKETS_RANKING_GRID_METADATA: "More",
};

export const elRankingGrid = {
  MARKETS_RANKING_GRID_ICON: "Εικονίδιο",
  MARKETS_RANKING_GRID_SYMBOL: "Σύμβολο",
  MARKETS_RANKING_GRID_NAME: "Όνομα",
  MARKETS_RANKING_GRID_AGE: "Ηλικία",
  MARKETS_RANKING_GRID_MC_RANK: "Κατάταξη Κεφαλαιοποίησης",
  MARKETS_RANKING_GRID_MC_RANK_1D: "Κατάταξη Κεφαλαιοποίησης (1D)",
  MARKETS_RANKING_GRID_MC_RANK_7D: "Κατάταξη Κεφαλαιοποίησης (7D)",
  MARKETS_RANKING_GRID_MC_RANK_30D: "Κατάταξη Κεφαλαιοποίησης (30D)",
  MARKETS_RANKING_GRID_LQ_RANK: "Κατάταξη Ρευστότητας",
  MARKETS_RANKING_GRID_LQ_RANK_1D: "Κατάταξη Ρευστότητας (1D)",
  MARKETS_RANKING_GRID_LQ_RANK_7D: "Κατάταξη Ρευστότητας (7D)",
  MARKETS_RANKING_GRID_LQ_RANK_30D: "Κατάταξη Ρευστότητας (30D)",
  MARKETS_RANKING_GRID_VL_RANK: "Κατάταξη Όγκου",
  MARKETS_RANKING_GRID_VL_RANK_1D: "Κατάταξη Όγκου (1D)",
  MARKETS_RANKING_GRID_VL_RANK_7D: "Κατάταξη Όγκου (7D)",
  MARKETS_RANKING_GRID_VL_RANK_30D: "Κατάταξη Όγκου (30D)",
  MARKETS_RANKING_GRID_ID: "Αναγνωριστικό",
  MARKETS_RANKING_GRID_METADATA: "Περισσότερα",
};
