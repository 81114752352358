import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { AppDispatch } from "@store/configureStore";
import { DeleteButton, LaunchButton } from "@common/BaseButton/Buttons";
import { UserRoles } from "@constants/enums";
import BaseDialog from "@common/BaseDialog/BaseDialog";
import BaseConfirmDialog from "@common/BaseConfirmDialog/BaseConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import { deleteAppLog } from "@actions/logs";
import { TAppState } from "@store/reducers";
import {
  adminSelector,
  commonSelector,
  userSelector,
} from "@components/selectors";
import { listLogsSet } from "@store/reducers/admin";
import LogItemDetailsTopBar from "@pages/Admin/Logs/LogItem/Details/LogItemDetailsTopBar";
import LogItemDetails from "@pages/Admin/Logs/LogItem/Details/LogItemDetails";

type Props = {
  log: any;
};

const LogItemActions: React.FC<Props> = ({ log }) => {
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const {
    settings: { authActive },
  } = useSelector((state: TAppState) => commonSelector(state));
  const { profile } = useSelector((state: TAppState) => userSelector(state));
  const {
    logs: {
      list: { all },
    },
  } = useSelector((state: TAppState) => adminSelector(state));

  const showActions = authActive ? profile?.role === UserRoles.Admin : true;

  const onDelete = async () => {
    const filtered = all.filter((row: any) => row.ID !== log.ID);
    const data = await dispatch(deleteAppLog({ id: log.ID })).unwrap();
    data.length === 0 && dispatch(listLogsSet(filtered));
    data.length === 0 && setDeleteOpen(false);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
      {showActions && (
        <>
          <BaseConfirmDialog
            title={t("ADMIN_LOG_ITEM_ACTION_DELETE_TITLE")}
            open={deleteOpen}
            message={t("ADMIN_LOG_ITEM_ACTION_DELETE_MESSAGE")}
            onCancel={() => setDeleteOpen(false)}
            onOk={() => onDelete()}
          />
          <DeleteButton onClick={() => setDeleteOpen(true)} />
        </>
      )}
      <>
        <BaseDialog
          open={detailsOpen}
          fullScreen
          padding={2}
          content={
            <>
              <LogItemDetailsTopBar onClose={() => setDetailsOpen(false)} />
              <LogItemDetails log={log} />
            </>
          }
        />
        <LaunchButton onClick={() => setDetailsOpen(true)} />
      </>
    </Box>
  );
};

export default LogItemActions;
