/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { AppDispatch } from "@store/configureStore";
import { fetchCronTasks } from "@actions/tasks";
import { TAppState } from "@store/reducers";
import { adminSelector } from "@components/selectors";
import TaskItem from "@pages/Admin/ScheduledTasks/TaskItem/TaskItem";

const TaskList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    tasks: { data },
  } = useSelector((state: TAppState) => adminSelector(state));

  useEffect(() => {
    dispatch(fetchCronTasks());
  }, []);

  return (
    <Box sx={{ margin: "2px 0 2px 0" }}>
      <Box>
        {data.map((task, i) => (
          <TaskItem key={i} task={task} />
        ))}
      </Box>
    </Box>
  );
};

export default TaskList;
