import {
  enRankingTab,
  elRankingTab,
} from "@pages/Markets/Rankings/translations";
import {
  enFearGreedTab,
  elFearGreedTab,
} from "@pages/Markets/FearGreed/translations";
import {
  enCryptoMetricsTab,
  elCryptoMetricsTab,
} from "@pages/Markets/CryptoMetrics/translations";

export const enMarketsPage = {
  MARKETS_RANKINGS_TAB_LABEL: "Rankings",
  MARKETS_FEARGREED_TAB_LABEL: "Fear & Greed",
  MARKETS_CRYPTO_METRICS_TAB_LABEL: "Crypto Metrics",
  ...enRankingTab,
  ...enFearGreedTab,
  ...enCryptoMetricsTab,
};

export const elMarketsPage = {
  MARKETS_RANKINGS_TAB_LABEL: "Κατατάξεις",
  MARKETS_FEARGREED_TAB_LABEL: "Fear & Greed",
  MARKETS_CRYPTO_METRICS_TAB_LABEL: "Μετρήσεις Κρυπτονομισμάτων",
  ...elRankingTab,
  ...elFearGreedTab,
  ...elCryptoMetricsTab,
};
