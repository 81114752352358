import { enCommon, elCommon } from "@common/translations";
import { enPages, elPages } from "@pages/translations";
import { enApp, elApp } from "@general/translations";

export const enComponent = {
  ...enCommon,
  ...enPages,
  ...enApp,
};

export const elComponent = {
  ...elCommon,
  ...elPages,
  ...elApp,
};
