/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Typography } from "@mui/material";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

type Props = {
  task: any;
};

const TaskItemStatus: React.FC<Props> = ({ task }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ height: 20, textAlign: "center" }}>
        <Typography>{task?.STATUS === 1 ? "Active" : "Inactive"}</Typography>
      </Box>
      <Box sx={{ height: 20, textAlign: "center" }}>
        {task?.STATUS === 1 ? (
          <ToggleOnIcon sx={{ color: "green" }} />
        ) : (
          <ToggleOffIcon sx={{ color: "red" }} />
        )}
      </Box>
    </Box>
  );
};

export default TaskItemStatus;
