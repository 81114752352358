import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { Box, Stack } from "@mui/material";
import { AppDispatch } from "@store/configureStore";
import { TAppState } from "@store/reducers";
import BaseSelect from "@common/BaseSelect/BaseSelect";
import {
  storedIndicatorsChanged,
  storedPatternsChanged,
  storedPairChanged,
  storedPeriodChanged,
  storedSignalsChanged,
  storedCandleDataChanged,
  storedPatternDataChanged,
  storedSignalDataChanged,
  storedDatasetIndicatorsChanged,
  storedDatasetPatternsChanged,
  storedDatasetSignalsChanged,
  storedDatasetPeriodsChanged,
  storedDatasetPairsChanged,
} from "@store/reducers/charts";
import {
  useGetActivePeriodsQuery,
  useGetActivePairsQuery,
  useGetActiveIndicatorsQuery,
  useGetActivePatternsQuery,
  useGetActiveSignalsQuery,
} from "@features/api";
import { chartsSelector } from "@components/selectors";
import {
  filterIndicators,
  filterPatterns,
  filterSignals,
} from "@utilities/filters";
import { fetchSignal } from "@actions/signal";
import { fetchPattern } from "@actions/pattern";

const ChartsFilterArea: React.FC = () => {
  const { t } = useTranslation();
  const { data: periodData, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: pairData, isLoading: pairsLoading } = useGetActivePairsQuery();
  const { data: signalData, isLoading: signalsLoading } =
    useGetActiveSignalsQuery();
  const { data: indicatorData, isLoading: indicatorsLoading } =
    useGetActiveIndicatorsQuery();
  const { data: patternData, isLoading: patternsLoading } =
    useGetActivePatternsQuery();
  const dispatch = useDispatch<AppDispatch>();

  const {
    stored: {
      dataset,
      filters: { period, pair, indicators, patterns, signals },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  const resetFilters = () => {
    dispatch(storedCandleDataChanged([]));
    dispatch(storedPatternDataChanged([]));
    dispatch(storedSignalDataChanged([]));
    dispatch(storedIndicatorsChanged([]));
    dispatch(storedPatternsChanged([]));
    dispatch(storedSignalsChanged([]));
  };

  const periodSelected = (period: any) => {
    dispatch(storedPeriodChanged(period));
    resetFilters();
  };

  const pairSelected = (pair: any) => {
    dispatch(storedPairChanged(pair));
    resetFilters();
  };

  const indicatorsSelected = (indicators: Array<any>) => {
    dispatch(storedIndicatorsChanged(indicators.map((i: any) => i.SELECT)));
  };

  const patternsSelected = (patterns: Array<any>) => {
    dispatch(storedPatternsChanged(patterns.map((i: any) => i.SELECT)));
  };

  const signalsSelected = (signals: Array<any>) => {
    dispatch(storedSignalsChanged(signals));
  };

  const patternSelected = (on: boolean, pattern: any) => {
    !on &&
      dispatch(
        fetchPattern({
          patternId: pattern.ID,
          periodId: period.ID,
          pairId: pair.ID,
        })
      );
  };

  const signalSelected = (on: boolean, signal: any) => {
    !on &&
      dispatch(
        fetchSignal({
          signalId: signal.ID,
          periodId: period.ID,
          pairId: pair.ID,
        })
      );
  };

  useEffect(() => {
    if (periodData && !periodsLoading) {
      dispatch(storedDatasetPeriodsChanged(periodData));
      dispatch(storedPeriodChanged(first(periodData)));
    }
  }, [dispatch, periodData, periodsLoading]);

  useEffect(() => {
    if (pairData && !pairsLoading) {
      dispatch(storedDatasetPairsChanged(pairData));
      dispatch(storedPairChanged(first(pairData)));
    }
  }, [dispatch, pairData, pairsLoading]);

  useEffect(() => {
    if (indicatorData && !indicatorsLoading) {
      dispatch(
        storedDatasetIndicatorsChanged(filterIndicators(indicatorData, period))
      );
    }
  }, [dispatch, period, indicatorData, indicatorsLoading]);

  useEffect(() => {
    if (patternData && !patternsLoading) {
      dispatch(
        storedDatasetPatternsChanged(filterPatterns(patternData, period))
      );
    }
  }, [dispatch, period, patternData, patternsLoading]);

  useEffect(() => {
    if (signalData && !signalsLoading) {
      dispatch(storedDatasetSignalsChanged(filterSignals(signalData, period)));
    }
  }, [dispatch, period, signalData, signalsLoading]);

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginBottom={5}
    >
      <Box>
        <BaseSelect
          data={dataset.periods}
          loading={periodsLoading}
          selected={[period?.CODE || ""]}
          onChange={periodSelected}
          selectedDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          optionsDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
        />
      </Box>
      <Box>
        <BaseSelect
          data={dataset.pairs}
          loading={pairsLoading}
          onChange={pairSelected}
          selected={[pair?.ID || ""]}
          selectKey="ID"
          displayKey="SYMBOL"
          inputLabel={t("PAIR_SELECT_INPUT_LABEL")}
          label={t("PAIR_SELECT_LABEL")}
          minWidth={200}
          maxWidth={200}
          selectedDisplay={(row, key) =>
            row[key].replaceAll(row.SYMBOL_PREFIX, "")
          }
          optionsDisplay={(row, key) =>
            row[key].replaceAll(row.SYMBOL_PREFIX, "")
          }
          optionInfo={(row) => (
            <Box>
              <Box>
                {row.TYPE_NAME} - {row.PROVIDER_NAME}
              </Box>
              {row.NAME && <Box>{row.NAME}</Box>}
            </Box>
          )}
          optionInfoStyle={{
            fontSize: "70%",
            color: "rgba(212,255,0,0.8)",
            paddingBottom: "1%",
          }}
          sortFn={(row: any) => [row.SYMBOL.replaceAll(row.SYMBOL_PREFIX, "")]}
          hasSearch
          searchKey="SYMBOL"
        />
      </Box>
      <Box>
        <BaseSelect
          data={dataset.signals}
          loading={signalsLoading}
          onChange={signalsSelected}
          selectedDisplay={(row: any) =>
            `${t(`SIGNAL_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
          }
          optionsDisplay={(row: any) =>
            `${t(`SIGNAL_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
          }
          selected={signals.map((s: any) => s.CODE)}
          selectKey="CODE"
          multiselect
          inputLabel={t("SIGNAL_SELECT_INPUT_LABEL")}
          label={t("SIGNAL_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          onItemSelect={signalSelected}
        />
      </Box>
      <Box>
        <BaseSelect
          data={dataset.indicators}
          loading={indicatorsLoading}
          selected={indicators}
          onChange={indicatorsSelected}
          multiselect
          inputLabel={t("INDICATOR_SELECT_INPUT_LABEL")}
          label={t("INDICATOR_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          groupKey="ID"
          headerKey="TEXT"
          selectKey="SELECT"
          displayKey="DISPLAY"
          selectedDisplay={(row: any) => `${row.TEXT}`}
        />
      </Box>
      <Box>
        <BaseSelect
          data={dataset.patterns}
          loading={patternsLoading}
          selected={patterns}
          onChange={patternsSelected}
          multiselect
          inputLabel={t("PATTERN_SELECT_INPUT_LABEL")}
          label={t("PATTERN_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
          groupKey="ID"
          headerKey="TEXT"
          selectKey="SELECT"
          displayKey="DISPLAY"
          onItemSelect={patternSelected}
          selectedDisplay={(row: any) => `${row.TEXT}`}
        />
      </Box>
    </Stack>
  );
};

export default ChartsFilterArea;
