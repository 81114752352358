import {
  enDisplayTab,
  elDisplayTab,
} from "@pages/Settings/Display/translations";
import {
  enNotificationsTab,
  elNotificationsTab,
} from "@pages/Settings/Notifications/translations";
import {
  elSecurityTab,
  enSecurityTab,
} from "@pages/Settings/Security/translations";

export const enSettingsPage = {
  ...enDisplayTab,
  ...enNotificationsTab,
  ...enSecurityTab,
};

export const elSettingsPage = {
  ...elDisplayTab,
  ...elNotificationsTab,
  ...elSecurityTab,
};
