import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Grid from "@common/Grid/Grid";
import { TAppState } from "@store/reducers";
import { analysisSelector } from "@components/selectors";
import getColumns, {
  gridKey,
  getVisibility,
  getTogglableColumns,
} from "@pages/Analysis/Grid/AnalysisGridConfig";

const AnalysisGrid: React.FC = () => {
  const {
    list: {
      data,
      filters: { analysis },
    },
  } = useSelector((state: TAppState) => analysisSelector(state));

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Grid
        gridKey={gridKey}
        columns={getColumns(analysis?.CODE)}
        data={data}
        visibility={getVisibility()}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          columnsManagement: {
            getTogglableColumns,
          },
        }}
      />
    </Box>
  );
};

export default AnalysisGrid;
