import i18n from "i18next";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import clsx from "clsx";

const getColumns: any = () => {
  return [
    {
      field: "IMAGE",
      headerName: i18n.t(`MARKETS_RANKING_GRID_ICON`),
      width: 70,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      display: "flex",
      renderCell: (params: any) => (
        <img
          width={20}
          height={20}
          src={params?.row?.METADATA?.assets?.png}
          alt={params?.row?.NAME}
        />
      ),
    },
    {
      field: "SYMBOL",
      type: "string",
      headerName: i18n.t(`MARKETS_RANKING_GRID_SYMBOL`),
      width: 110,
      disableColumnMenu: true,
    },
    {
      field: "NAME",
      type: "string",
      headerName: i18n.t(`MARKETS_RANKING_GRID_NAME`),
      width: 115,
      disableColumnMenu: true,
    },
    {
      field: "AGE",
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_AGE`),
      width: 80,
      disableColumnMenu: true,
    },
    {
      field: `MC_RANK`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_MC_RANK`),
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: `MC_RANK_1D`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_MC_RANK_1D`),
      width: 160,
      disableColumnMenu: true,
      cellClassName: (params: GridCellParams<any, number>) => {
        const { value } = params;
        return value == null
          ? ""
          : clsx(`ranking-grid`, {
              up: value >= 50,
              down: value <= -50,
            });
      },
    },
    {
      field: `MC_RANK_7D`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_MC_RANK_7D`),
      width: 150,
      disableColumnMenu: true,
      cellClassName: (params: GridCellParams<any, number>) => {
        const { value } = params;
        return value == null
          ? ""
          : clsx(`ranking-grid`, {
              up: value >= 90,
              down: value <= -90,
            });
      },
    },
    {
      field: `MC_RANK_30D`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_MC_RANK_30D`),
      width: 170,
      disableColumnMenu: true,
      cellClassName: (params: GridCellParams<any, number>) => {
        const { value } = params;
        return value == null
          ? ""
          : clsx(`ranking-grid`, {
              up: value >= 90,
              down: value <= -90,
            });
      },
    },
    {
      field: `LQ_RANK`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_LQ_RANK`),
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: `LQ_RANK_1D`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_LQ_RANK_1D`),
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: `LQ_RANK_7D`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_LQ_RANK_7D`),
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: `LQ_RANK_30D`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_LQ_RANK_30D`),
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: `VL_RANK`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_VL_RANK`),
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: `VL_RANK_1D`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_VL_RANK_1D`),
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: `VL_RANK_7D`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_VL_RANK_7D`),
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: `VL_RANK_30D`,
      type: "number",
      headerName: i18n.t(`MARKETS_RANKING_GRID_VL_RANK_30D`),
      width: 150,
      disableColumnMenu: true,
    },
    ...getHiddenColumns(),
  ];
};

const getHiddenColumns: any = () => [
  {
    field: `ID`,
    headerName: i18n.t(`MARKETS_RANKING_GRID_ID`),
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: `METADATA`,
    headerName: i18n.t(`MARKETS_RANKING_GRID_METADATA`),
    width: 100,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
];

export const getVisibility = () => ({ ID: false, METADATA: false });

export const getSortModel = () => [
  {
    field: "MC_RANK_1D",
    sort: "desc",
  },
];

export const getTogglableColumns = (columns: GridColDef[]) =>
  columns
    .filter((column) => column.field !== "METADATA")
    .map((column) => column.field);

export default getColumns;
export const gridKey = "ID";
