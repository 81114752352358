import {
  enLogActions,
  elLogActions,
} from "@pages/Admin/Logs/LogActions/translations";
import { enLogItem, elLogItem } from "@pages/Admin/Logs/LogItem/translations";
import { enLogList, elLogList } from "@pages/Admin/Logs/LogList/translations";

export const enLogsTab = {
  ADMIN_LOGS_TAB_LABEL: "System logs",
  ADMIN_LOGS_HEADER: "Application processes",
  ...enLogActions,
  ...enLogList,
  ...enLogItem,
};

export const elLogsTab = {
  ADMIN_LOGS_TAB_LABEL: "Συμβάντα συστήματος",
  ADMIN_LOGS_HEADER: "Διεργασίες εφαρμογής",
  ...elLogActions,
  ...elLogList,
  ...elLogItem,
};
