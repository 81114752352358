import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { TAppState } from "@store/reducers";
import { AppDispatch } from "@store/configureStore";
import { changeTheme, changeLanguage } from "@store/reducers/common";
import Logo from "@general/Header/Logo";
import MainMenu from "@general/Menu/MainMenu";
import UserMenu from "@general/Menu/UserMenu";
import { SettingsButton } from "@common/BaseButton/Buttons";
import SettingsDialog from "@general/SettingsDialog/SettingsDialog";
import { commonSelector, userSelector } from "@components/selectors";
import { HEADER_MIN_HEIGHT } from "@constants/settings";
import { setLanguageInStorage, setThemeInStorage } from "@utilities/helpers";

const Header: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { settings, theme } = useSelector((state: TAppState) =>
    commonSelector(state)
  );
  const {
    auth: { loggedIn },
  } = useSelector((state: TAppState) => userSelector(state));

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme === "light" ? "#1434A4" : "#272727",
        height: HEADER_MIN_HEIGHT,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo isMobile={false} />
          {settings?.authActive ? (
            loggedIn ? (
              <MainMenu isMobile />
            ) : null
          ) : (
            <MainMenu isMobile />
          )}
          <Logo isMobile />
          {settings?.authActive ? (
            loggedIn ? (
              <MainMenu isMobile={false} />
            ) : null
          ) : (
            <MainMenu isMobile={false} />
          )}
          <Box sx={{ ml: "auto", display: "flex" }}>
            {!loggedIn || !settings?.authActive ? (
              <SettingsButton
                sx={{ color: "white" }}
                onClick={() => setDialogOpen(true)}
              />
            ) : null}
            <SettingsDialog
              open={dialogOpen}
              onClickCancel={(p, e, r) =>
                r !== "backdropClick" && setDialogOpen(false)
              }
              onClickOk={(p, e, r) => {
                const { locale, theme } = p;
                dispatch(changeLanguage(locale));
                dispatch(changeTheme(theme));
                setThemeInStorage(theme);
                setLanguageInStorage(locale);
                setDialogOpen(false);
                window.location.reload();
              }}
            />
            {loggedIn && settings?.authActive && <UserMenu />}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
