import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import FaqAccordion from "@common/FaqAccordion/FaqAccordion";

type Props = {};

const ChartsLiveInfo: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ padding: "2% 10% 10% 10%", width: "100%" }}>
      <Box sx={{ paddingBottom: "5%" }}>
        <FaqAccordion
          prettyPrint
          title={t("CHARTS_LIVE_JSON_ACCORDION_TITLE")}
          description={t("CHARTS_LIVE_JSON_ACCORDION_DESCRIPTION")}
          enableCopy
        />
      </Box>
    </Box>
  );
};

export default ChartsLiveInfo;
