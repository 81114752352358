import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  task: any;
};

const TaskItemExpression: React.FC<Props> = ({ task }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
      <Typography minWidth={150}>{task?.EXPRESSION}</Typography>
    </Box>
  );
};

export default TaskItemExpression;
