import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "@store/reducers";
import { AppDispatch } from "@store/configureStore";
import ChartTopBar from "@common/ChartCommon/TopBar/ChartTopBar";
import ChartDialog from "@common/ChartCommon/ChartDialog";
import ChartsFilterArea from "@pages/Charts/Stored/ChartsFilterArea";
import PriceChart from "@pages/Charts/Common/Charts/PriceChart";
import RangeChart from "@pages/Charts/Common/Charts/RangeChart";
import NoRangeChart from "@pages/Charts/Common/Charts/NoRangeChart";
import HistogramChart from "@pages/Charts/Common/Charts/HistogramChart";
import { fetchCandles } from "@actions/charts";
import { chartsSelector } from "@components/selectors";
import { createIndicatorsConfig } from "@pages/helpers";
import { useGetActiveIndicatorsQuery } from "@features/api";
import { TIndicatorsConfig } from "@app/types/entities";

const ChartsStoredTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    stored: {
      candleData,
      patternData,
      signalData,
      filters: { period, pair, indicators, patterns, signals },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));
  const { data: indicatorData, isLoading: indicatorsLoading } =
    useGetActiveIndicatorsQuery();
  const [priceDialogIsOpen, setPriceDialogIsOpen] = useState(false);
  const [rangeDialogIsOpen, setRangeDialogIsOpen] = useState(false);
  const [noRangeDialogIsOpen, setNoRangeDialogIsOpen] = useState(false);
  const [histogramDialogIsOpen, setHistogramDialogIsOpen] = useState(false);
  const [lineBarConfig, setLineBarConfig] = useState<TIndicatorsConfig>();

  useEffect(() => {
    if (period && !indicatorsLoading) {
      setLineBarConfig(createIndicatorsConfig(period, indicatorData));
    }
  }, [indicatorData, indicatorsLoading, period]);

  useEffect(() => {
    if (period && pair) {
      dispatch(
        fetchCandles({
          period,
          pair,
        })
      );
    }
  }, [dispatch, period, pair]);

  return (
    <Box className="charts-stored-page">
      <Box>
        <ChartsFilterArea />
      </Box>
      <Box>
        <ChartTopBar
          dialogOpen={false}
          onDialogOpen={() => setPriceDialogIsOpen(true)}
        />
        <ChartDialog
          dialogOpen={priceDialogIsOpen}
          onDialogClose={() => setPriceDialogIsOpen(false)}
          chartChild={
            <PriceChart
              pair={pair}
              candleData={candleData}
              indicators={indicators}
              patterns={patterns}
              patternData={patternData}
              signals={signals}
              signalData={signalData}
              lineBarConfig={lineBarConfig}
              autoSize
            />
          }
        />
        <PriceChart
          pair={pair}
          candleData={candleData}
          indicators={indicators}
          patterns={patterns}
          patternData={patternData}
          signals={signals}
          signalData={signalData}
          lineBarConfig={lineBarConfig}
          publishActions
        />
      </Box>
      <Box>
        <ChartTopBar
          dialogOpen={false}
          onDialogOpen={() => setRangeDialogIsOpen(true)}
        />
        <ChartDialog
          dialogOpen={rangeDialogIsOpen}
          onDialogClose={() => setRangeDialogIsOpen(false)}
          chartChild={
            <RangeChart
              pair={pair}
              candleData={candleData}
              indicators={indicators}
              lineBarConfig={lineBarConfig}
            />
          }
        />
        <RangeChart
          pair={pair}
          candleData={candleData}
          indicators={indicators}
          lineBarConfig={lineBarConfig}
          subscribeActions
        />
      </Box>
      <Box>
        <ChartTopBar
          dialogOpen={false}
          onDialogOpen={() => setNoRangeDialogIsOpen(true)}
        />
        <ChartDialog
          dialogOpen={noRangeDialogIsOpen}
          onDialogClose={() => setNoRangeDialogIsOpen(false)}
          chartChild={
            <NoRangeChart
              pair={pair}
              candleData={candleData}
              indicators={indicators}
              lineBarConfig={lineBarConfig}
              subscribeActions
            />
          }
        />
        <NoRangeChart
          pair={pair}
          candleData={candleData}
          indicators={indicators}
          lineBarConfig={lineBarConfig}
          subscribeActions
        />
      </Box>
      <Box>
        <ChartTopBar
          dialogOpen={false}
          onDialogOpen={() => setHistogramDialogIsOpen(true)}
        />
        <ChartDialog
          dialogOpen={histogramDialogIsOpen}
          onDialogClose={() => setHistogramDialogIsOpen(false)}
          chartChild={
            <HistogramChart
              pair={pair}
              candleData={candleData}
              indicators={indicators}
              lineBarConfig={lineBarConfig}
            />
          }
        />
        <HistogramChart
          pair={pair}
          candleData={candleData}
          indicators={indicators}
          lineBarConfig={lineBarConfig}
          subscribeActions
        />
      </Box>
    </Box>
  );
};

export default ChartsStoredTab;
