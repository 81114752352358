import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { TAppState } from "@store/reducers";
import { signalSelector } from "@components/selectors";
import { useGetActivePairsQuery } from "@features/api";
import BaseBar from "@common/BaseBar/BaseBar";
import {
  getPercent,
  getCountConfig,
  getSignalMatches,
} from "@pages/Signals/Grid/Count/SignalsGridCountConfig";

const SignalsGridCount: React.FC = () => {
  const {
    list: {
      filters: { signal },
      data,
    },
  } = useSelector((state: TAppState) => signalSelector(state));
  const { data: pairs, isLoading: pairsLoading } = useGetActivePairsQuery();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "380px",
        margin: "auto",
        fontSize: "90%",
        background: "rgba(114, 108, 111, 0.24)",
        padding: "2%",
        borderRadius: "10%",
      }}
    >
      {
        <Box sx={{ display: "flex", fontWeight: "bold" }}>
          <Box sx={{ width: "130px" }}>
            <Typography variant="subtitle1">
              {t("SIGNALS_GRID_COUNT_HEADER_SIGNAL")}
            </Typography>
          </Box>
          <Box sx={{ width: "70px" }}>
            <Typography variant="subtitle1">
              {t("SIGNALS_GRID_COUNT_HEADER_PAIRS")}
            </Typography>
          </Box>
          <Box sx={{ width: "80px" }}>
            <Typography variant="subtitle1">
              {t("SIGNALS_GRID_COUNT_HEADER_PERCENT")}
            </Typography>
          </Box>
          <Box sx={{ width: "100px" }}>
            <Typography variant="subtitle1">
              {t("SIGNALS_GRID_COUNT_HEADER_BAR")}
            </Typography>
          </Box>
        </Box>
      }
      {signal !== null &&
        !pairsLoading &&
        getCountConfig(signal?.CODE).map((c, k) => (
          <Box sx={{ display: "flex" }} key={k}>
            <Box sx={{ width: "130px" }}>{t(c.text)}</Box>
            <Box sx={{ width: "70px" }}>
              <Typography>
                {getSignalMatches({ key: c.key, value: c.value }, data) +
                  " / " +
                  pairs.length}
              </Typography>
            </Box>
            <Box sx={{ width: "80px" }}>
              <Typography>
                {getPercent(
                  { key: c.key, value: c.value },
                  data,
                  pairs.length
                ).toFixed(2) + "%"}
              </Typography>
            </Box>
            <Box sx={{ width: "100px" }}>
              <BaseBar
                color={c.color}
                value={getPercent(
                  { key: c.key, value: c.value },
                  data,
                  pairs.length
                )}
              />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default SignalsGridCount;
