import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { CloseButton, SaveButton } from "@common/BaseButton/Buttons";
import { AppDispatch } from "@store/configureStore";
import { TAppState } from "@store/reducers";
import { backtestingSelector } from "@components/selectors";
import {
  isBacktestingCandleJson,
  isBacktestingSignalJson,
} from "@utilities/helpers";
import { toggleUpload } from "@store/reducers/backtesting";

type Props = {};

const BacktestingUploadTopBar: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { json } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );
  const { candles, signals } = json;

  const onClose = () => {
    dispatch(toggleUpload(false));
  };

  const onSave = () => {
    if (isBacktestingCandleJson(candles) && isBacktestingSignalJson(signals)) {
      dispatch(toggleUpload(false));
    } else alert("Invalid JSON");
  };

  return (
    <Box
      sx={{
        textAlign: "right",
        width: "100%",
        background: "rgba(96, 130, 182, 0.6)",
      }}
    >
      <CloseButton onClick={onClose} />
      <SaveButton
        onClick={onSave}
        disabled={
          !isBacktestingCandleJson(candles) || !isBacktestingSignalJson(signals)
        }
      />
    </Box>
  );
};

export default BacktestingUploadTopBar;
