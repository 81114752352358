import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CRON_TASKS_FETCH,
  CRON_TASK_START,
  CRON_TASK_STOP,
  CRON_TASK_RUN,
} from "@actions/_types";
import { setInterceptors, clearInterceptors } from "@features/interceptors/app";
import { cronTasksUrl } from "@constants/endpoints";

type TStartCronTaskPayload = {
  taskId: number;
};

type TStopCronTaskPayload = {
  taskId: number;
};

type TRunCronTaskPayload = {
  taskId: number;
};

export const fetchCronTasks = createAsyncThunk(CRON_TASKS_FETCH, async () => {
  const intercepted = setInterceptors(axios.create({ withCredentials: true }));
  let url = `${cronTasksUrl}`;
  const response = await intercepted.instance.get(url);
  clearInterceptors(intercepted);
  return response.data;
});

export const startCronTask = createAsyncThunk(
  CRON_TASK_START,
  async (params: TStartCronTaskPayload) => {
    const { taskId } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const url = `${cronTasksUrl}/start?taskId=${taskId}`;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const stopCronTask = createAsyncThunk(
  CRON_TASK_STOP,
  async (params: TStopCronTaskPayload) => {
    const { taskId } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const url = `${cronTasksUrl}/stop?taskId=${taskId}`;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const runCronTask = createAsyncThunk(
  CRON_TASK_RUN,
  async (params: TRunCronTaskPayload) => {
    const { taskId } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const url = `${cronTasksUrl}/run?taskId=${taskId}`;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);
