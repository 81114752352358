import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import LeftRightChart from "@common/LeftRightChart/LeftRightChart";
import { TAppState } from "@store/reducers";
import { marketsSelector } from "@components/selectors";
import { createChartFearGreed } from "@utilities/helpers";

const FearGreedChart: React.FC = () => {
  const {
    fearAndGreed: { crypto, stocks },
  } = useSelector((state: TAppState) => marketsSelector(state));
  const { t } = useTranslation();

  return (
    <Box>
      <LeftRightChart
        height={300}
        showLeftLegend
        leftConfig={{
          lineWidth: 1,
          color: "#FF3131",
          lastValueVisible: true,
          priceLineVisible: false,
        }}
        leftData={createChartFearGreed(stocks)}
        leftLegendLabel={t("MARKETS_FEARGREED_STOCK_MARKET_CHART_LEGEND")}
        rightConfig={{
          lineWidth: 1,
          color: "#40E0D0",
          lastValueVisible: true,
          priceLineVisible: false,
        }}
        rightData={createChartFearGreed(crypto)}
        rightLegendLabel={t("MARKETS_FEARGREED_CRYPTO_MARKET_CHART_LEGEND")}
      />
    </Box>
  );
};

export default FearGreedChart;
