import React, { useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "@store/reducers";
import { AppDispatch } from "@store/configureStore";
import {
  CloseFullscreenButton,
  DeleteButton,
  OpenInFullButton,
  UploadButton,
} from "@common/BaseButton/Buttons";
import {
  candlesJsonChanged,
  signalsJsonChanged,
  toggleFullScreen,
  toggleUpload,
} from "@store/reducers/backtesting";
import { backtestingSelector } from "@components/selectors";

const BacktestingTopBarRight: React.FC = () => {
  const [, startTransition] = useTransition();
  const dispatch = useDispatch<AppDispatch>();

  const { fullScreenOpen, uploadOpen, json } = useSelector((state: TAppState) =>
    backtestingSelector(state)
  );
  const { candles, signals } = json;

  return (
    <Box
      sx={{
        textAlign: "right",
        width: "50%",
      }}
    >
      <DeleteButton
        disabled={candles.length === 0 && signals.length === 0}
        color="white"
        onClick={() => {
          dispatch(candlesJsonChanged(""));
          dispatch(signalsJsonChanged(""));
        }}
      />
      {!uploadOpen && (
        <UploadButton
          color="white"
          onClick={() => {
            startTransition(() => {
              dispatch(toggleUpload(true));
            });
          }}
        />
      )}
      {fullScreenOpen ? (
        <CloseFullscreenButton
          color="white"
          onClick={() => {
            startTransition(() => {
              dispatch(toggleFullScreen(false));
            });
          }}
        />
      ) : (
        <OpenInFullButton
          color="white"
          onClick={() => {
            startTransition(() => {
              dispatch(toggleFullScreen(true));
            });
          }}
        />
      )}
    </Box>
  );
};

export default BacktestingTopBarRight;
