import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import LanguageSettings from "@pages/Settings/Display/LanguageSettings/LanguageSettings";
import ThemeSettings from "@pages/Settings/Display/ThemeSettings/ThemeSettings";

const PreferencesTab: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%", padding: "0px 10px 0px 10px" }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t("SETTINGS_DISPLAY_HEADER")}
      </Typography>
      <LanguageSettings />
      <ThemeSettings />
    </Box>
  );
};

export default PreferencesTab;
