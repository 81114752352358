export const CANDLES_FETCH = "candles/fetch";
export const PATTERN_FETCH = "pattern/fetch";
export const SIGNAL_FETCH = "signal/fetch";
export const ANALYSIS_FETCH_BY_OPENED_AT = "analysis/fetch/by/openedAt";
export const ANALYSIS_FETCH_BY_PAIR_ID = "analysis/fetch/by/pairId";
export const SIGNALS_CALCULATE = "signals/calculate";
export const PERIODS_FETCH_LAST_SYNCED = "periods/fetch/last/synced";
export const ECONOMY_ANALYSIS_FETCH_BY_EXTERNAL_ID =
  "economy/fetch/by/externalid";
export const ECONOMY_ANALYSIS_FETCH_ECONOMY_GROUP =
  "economy/fetch/economy/group";
export const MARKETS_FETCH_RANKINGS = "markets/fetch/rankings";
export const MARKETS_FETCH_FEARANDGREED = "markets/fetch/fearandgreed";
export const MARKETS_FETCH_METRICS = "markets/fetch/metrics";
export const CANDLES_LIVE_FETCH = "candles/live/fetch";
export const USER_LOGIN = "user/login";
export const USER_LOGOUT = "user/logout";
export const USER_VERIFY = "user/verify";
export const USER_ENABLE_TFA = "user/tfa/enable";
export const USER_DISABLE_TFA = "user/tfa/disable";
export const USER_REQUEST_TFA = "user/tfa/request";
export const USER_FETCH_SESSION = "user/fetch/session";
export const USER_FETCH_SETTINGS = "user/fetch/settings";
export const USER_UPDATE_SETTINGS = "user/update/settings";
export const APP_FETCH_SETTINGS = "app/fetch/settings";
export const LOGS_APP_FETCH = "logs/fetch/app";
export const LOGS_APP_CLEAR = "logs/clear/app";
export const LOGS_APP_DELETE = "logs/delete/app";
export const CRON_TASKS_FETCH = "tasks/fetch/cron";
export const CRON_TASK_START = "task/start/cron";
export const CRON_TASK_STOP = "task/stop/cron";
export const CRON_TASK_RUN = "task/run/cron";
