import { createSlice } from "@reduxjs/toolkit";
import { first } from "lodash";
import { AXIOS_STATUSES } from "@app/types/entities";
import { fetchCandles, fetchLiveCandles } from "@actions/charts";
import { calculateSignals, fetchSignal } from "@actions/signal";
import { fetchPattern } from "@actions/pattern";

export interface TChartsState {
  stored: {
    candleData: Array<any>;
    patternData: any;
    signalData: any;
    dataset: {
      periods: Array<any>;
      pairs: Array<any>;
      indicators: Array<any>;
      patterns: Array<any>;
      signals: Array<any>;
    };
    filters: {
      period: any | null;
      pair: any | null;
      indicators: Array<any>;
      patterns: Array<any>;
      signals: Array<any>;
    };
  };
  live: {
    json: string;
    data: Array<any>;
    dataset: {
      periods: Array<any>;
      providers: Array<any>;
      pairs: Array<any>;
      indicators: Array<any>;
      patterns: Array<any>;
      signals: Array<any>;
    };
    filters: {
      period: any | null;
      provider: any | null;
      pair: any | null;
      indicators: Array<any>;
      patterns: Array<any>;
      signals: Array<any>;
    };
    popup: { active: boolean };
  };
  status: string | null;
}

const initialState: TChartsState = {
  stored: {
    candleData: [],
    patternData: {},
    signalData: {},
    dataset: {
      periods: [],
      pairs: [],
      indicators: [],
      patterns: [],
      signals: [],
    },
    filters: {
      period: null,
      pair: null,
      indicators: [],
      patterns: [],
      signals: [],
    },
  },
  live: {
    json: "",
    data: [],
    dataset: {
      periods: [],
      providers: [],
      pairs: [],
      indicators: [],
      patterns: [],
      signals: [],
    },
    filters: {
      period: null,
      provider: null,
      pair: null,
      indicators: [],
      patterns: [],
      signals: [],
    },
    popup: { active: false },
  },
  status: null,
};

const chartsSlice = createSlice({
  name: "charts",
  initialState,
  reducers: {
    storedPeriodChanged: (state, action) => {
      state.stored.filters.period = action.payload;
    },
    storedPairChanged: (state, action) => {
      state.stored.filters.pair = action.payload;
    },
    storedIndicatorsChanged: (state, action) => {
      state.stored.filters.indicators = action.payload;
    },
    storedPatternsChanged: (state, action) => {
      state.stored.filters.patterns = action.payload;
    },
    storedSignalsChanged: (state, action) => {
      state.stored.filters.signals = action.payload;
    },
    storedCandleDataChanged: (state, action) => {
      state.stored.candleData = action.payload;
    },
    storedPatternDataChanged: (state, action) => {
      state.stored.patternData = action.payload;
    },
    storedSignalDataChanged: (state, action) => {
      state.stored.signalData = action.payload;
    },
    storedDatasetPeriodsChanged: (state, action) => {
      state.stored.dataset.periods = action.payload;
    },
    storedDatasetPairsChanged: (state, action) => {
      state.stored.dataset.pairs = action.payload;
    },
    storedDatasetIndicatorsChanged: (state, action) => {
      state.stored.dataset.indicators = action.payload;
    },
    storedDatasetPatternsChanged: (state, action) => {
      state.stored.dataset.patterns = action.payload;
    },
    storedDatasetSignalsChanged: (state, action) => {
      state.stored.dataset.signals = action.payload;
    },
    livePeriodChanged: (state, action) => {
      state.live.filters.period = action.payload;
    },
    liveProviderChanged: (state, action) => {
      state.live.filters.provider = action.payload;
    },
    livePairChanged: (state, action) => {
      state.live.filters.pair = action.payload;
    },
    liveJsonChanged: (state, action) => {
      state.live.json = action.payload;
    },
    liveDataChanged: (state, action) => {
      state.live.data = action.payload;
    },
    liveIndicatorsChanged: (state, action) => {
      state.live.filters.indicators = action.payload;
    },
    livePatternsChanged: (state, action) => {
      state.live.filters.patterns = action.payload;
    },
    liveSignalsChanged: (state, action) => {
      state.live.filters.signals = action.payload;
    },
    liveDatasetPeriodsChanged: (state, action) => {
      state.live.dataset.periods = action.payload;
    },
    liveDatasetProvidersChanged: (state, action) => {
      state.live.dataset.providers = action.payload;
    },
    liveDatasetPairsChanged: (state, action) => {
      state.live.dataset.pairs = action.payload;
    },
    liveDatasetIndicatorsChanged: (state, action) => {
      state.live.dataset.indicators = action.payload;
    },
    liveDatasetPatternsChanged: (state, action) => {
      state.live.dataset.patterns = action.payload;
    },
    liveDatasetSignalsChanged: (state, action) => {
      state.live.dataset.signals = action.payload;
    },
    toggleLiveMode: (state, action) => {
      state.live.popup.active = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculateSignals.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(calculateSignals.fulfilled, (state, action) => {
        state.live.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(calculateSignals.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchCandles.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchCandles.fulfilled, (state, action) => {
        state.stored.candleData = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchCandles.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchPattern.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchPattern.fulfilled, (state, action) => {
        const code = first(action.payload)?.PATTERN_CODE;
        if (code)
          state.stored.patternData = {
            ...state.stored.patternData,
            [code]: action.payload,
          };
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchPattern.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchSignal.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchSignal.fulfilled, (state, action) => {
        const code = first(action.payload)?.SIGNAL_CODE;
        if (code)
          state.stored.signalData = {
            ...state.stored.signalData,
            [code]: action.payload,
          };
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchSignal.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchLiveCandles.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchLiveCandles.fulfilled, (state, action) => {
        state.live.json = JSON.stringify(action.payload, null, 2);
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchLiveCandles.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      });
  },
});

export const {
  storedPeriodChanged,
  storedPairChanged,
  storedSignalsChanged,
  storedIndicatorsChanged,
  storedPatternsChanged,
  storedCandleDataChanged,
  storedPatternDataChanged,
  storedSignalDataChanged,
  storedDatasetPeriodsChanged,
  storedDatasetPairsChanged,
  storedDatasetIndicatorsChanged,
  storedDatasetPatternsChanged,
  storedDatasetSignalsChanged,
  livePeriodChanged,
  liveProviderChanged,
  livePairChanged,
  liveIndicatorsChanged,
  livePatternsChanged,
  liveSignalsChanged,
  liveJsonChanged,
  liveDataChanged,
  liveDatasetPeriodsChanged,
  liveDatasetProvidersChanged,
  liveDatasetPairsChanged,
  liveDatasetIndicatorsChanged,
  liveDatasetPatternsChanged,
  liveDatasetSignalsChanged,
  toggleLiveMode,
} = chartsSlice.actions;
export default chartsSlice.reducer;
