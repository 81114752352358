import {
  enMarketSettings,
  elMarketSettings,
} from "@pages/Settings/Notifications/MarketSettings/translations";

export const enNotificationsTab = {
  SETTINGS_NOTIFICATIONS_TAB_LABEL: "Notifications",
  ...enMarketSettings,
};

export const elNotificationsTab = {
  SETTINGS_NOTIFICATIONS_TAB_LABEL: "Ειδοποιήσεις",
  ...elMarketSettings,
};
