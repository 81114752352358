import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { CloseButton, SaveButton } from "@common/BaseButton/Buttons";
import { AppDispatch } from "@store/configureStore";
import { TAppState } from "@store/reducers";
import { chartsSelector } from "@components/selectors";
import { toggleLiveMode } from "@store/reducers/charts";
import { calculateSignals } from "@actions/signal";
import { isCandleJson } from "@utilities/helpers";

type Props = {};

const ChartsLiveTopBar: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    live: {
      json,
      filters: { period },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  const onClose = () => {
    dispatch(toggleLiveMode(false));
  };

  const onSave = () => {
    if (isCandleJson(json)) {
      dispatch(
        calculateSignals({
          periodId: period.ID,
          data: JSON.parse(json),
        })
      );
      dispatch(toggleLiveMode(false));
    } else alert("Invalid JSON");
  };

  return (
    <Box
      sx={{
        textAlign: "right",
        width: "100%",
        background: "rgba(96, 130, 182, 0.6)",
      }}
    >
      <CloseButton onClick={onClose} />
      <SaveButton onClick={onSave} disabled={!isCandleJson(json)} />
    </Box>
  );
};

export default ChartsLiveTopBar;
