import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { TAppState } from "@store/reducers";
import DateDisplay from "@common/DateDisplay/DateDisplay";
import { commonSelector } from "@components/selectors";
import { FOOTER_MIN_HEIGHT } from "@constants/settings";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const { theme } = useSelector((state: TAppState) => commonSelector(state));

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setContainerWidth(entry.contentRect.width);
        }
      });
      observer.observe(container);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <Box
      className="app-footer-container"
      ref={containerRef}
      sx={{
        display: "flex",
        backgroundColor: theme === "light" ? "#1434A4" : "#272727",
        minHeight: FOOTER_MIN_HEIGHT,
      }}
    >
      {containerWidth >= 375 && (
        <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
          <Typography>
            {containerWidth > 900
              ? t("APP_FOOTER_COPYRIGHT_FULL")
              : t("APP_FOOTER_COPYRIGHT_SHORT")}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}></Box>
      <Box
        sx={{
          flex: containerWidth <= 900 ? (containerWidth <= 600 ? 3 : 2) : 1,
          marginLeft:
            containerWidth > 1200 ? "40%" : containerWidth < 600 ? 0 : "20%",
        }}
      >
        <DateDisplay />
      </Box>
    </Box>
  );
};

export default Footer;
