import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers";
import { FormControl } from "@mui/material";

type Props = {
  label?: string | null;
  defaultValue?: any;
  maxDate?: any;
  format?: string;
  ampm?: boolean;
  onChange: (date: any) => void;
  shouldDisableDate?: (date: any) => boolean;
  timeSteps?:
    | {
        hours: number;
        minutes: number;
      }
    | undefined;
};

const BaseDatePicker: React.FC<Props> = ({
  label = null,
  onChange,
  defaultValue = null,
  maxDate = null,
  format = "dd/MM/yyyy HH:mm:ss",
  ampm = false,
  shouldDisableDate = (date: any) => false,
  timeSteps,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<any | null>(defaultValue);

  const handleChange = (dateTime: DateTime) => {
    setValue(dateTime);
    onChange(dateTime);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <FormControl size="small" fullWidth>
      <DateTimePicker
        className="base-date-picker"
        slotProps={{ textField: { size: "small" } }}
        onChange={handleChange}
        maxDate={maxDate}
        ampm={ampm}
        timeSteps={timeSteps}
        format={format}
        defaultValue={defaultValue}
        value={value}
        label={label || t("BASE_DATEPICKER_LABEL")}
        shouldDisableDate={shouldDisableDate || undefined}
      />
    </FormControl>
  );
};

export default BaseDatePicker;
