export const enFearGreedChart = {
  MARKETS_FEARGREED_CRYPTO_MARKET_GAUGE_HEADER: "Crypto Fear & Greed Index",
  MARKETS_FEARGREED_CRYPTO_MARKET_GAUGE_TODAY: "Today",
  MARKETS_FEARGREED_STOCK_MARKET_GAUGE_HEADER: "Stocks Fear & Greed Index",
  MARKETS_FEARGREED_STOCK_MARKET_GAUGE_TODAY: "Today",
  MARKETS_FEARGREED_GAUGE_REFERENCE_DATE: "Reference Date",
  MARKETS_FEARGREED_NO_DATA_YET: "No data exist yet. Please try again later.",
  MARKETS_FEARGREED_CRYPTO_MARKET_CHART_LEGEND: "Crypto Fear & Greed Index",
  MARKETS_FEARGREED_STOCK_MARKET_CHART_LEGEND: "Stocks Fear & Greed Index",
};

export const elFearGreedChart = {
  MARKETS_FEARGREED_CRYPTO_MARKET_GAUGE_HEADER: "Crypto Fear & Greed Index",
  MARKETS_FEARGREED_CRYPTO_MARKET_GAUGE_TODAY: "Σήμερα",
  MARKETS_FEARGREED_STOCK_MARKET_GAUGE_HEADER: "Stocks Fear & Greed Index",
  MARKETS_FEARGREED_STOCK_MARKET_GAUGE_TODAY: "Σήμερα",
  MARKETS_FEARGREED_GAUGE_REFERENCE_DATE: "Ημ/νία Αναφοράς",
  MARKETS_FEARGREED_NO_DATA_YET:
    "Δεν υπάρχουν ακόμη δεδομένα. Παρακαλώ δοκιμάστε αργότερα.",
  MARKETS_FEARGREED_CRYPTO_MARKET_CHART_LEGEND: "Crypto Fear & Greed Index",
  MARKETS_FEARGREED_STOCK_MARKET_CHART_LEGEND: "Stocks Fear & Greed Index",
};
