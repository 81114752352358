import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Box, Typography } from "@mui/material";
import { TAppState } from "@store/reducers";
import { signalSelector } from "@components/selectors";

const SignalsAnalysisCandle: React.FC = () => {
  const { t } = useTranslation();

  const formatDate = (date: string) =>
    date
      ? DateTime.fromFormat(date, "yyyy-MM-dd HH:mm:ss", {
          zone: "utc",
        }).toFormat("dd-MM-yyyy HH:mm:ss")
      : t("SIGNALS_ANALYSIS_NO_DATA");

  const {
    analysis: { data },
  } = useSelector((state: TAppState) => signalSelector(state));

  return (
    <Box
      sx={{
        padding: 2,
        border: "3px solid rgba(114, 108, 111, 0.40)",
        borderRadius: "15px",
        MozBorderRadius: "15px",
        marginBottom: 2,
      }}
    >
      <Typography>
        {t("CANDLE_OPENED_AT")}: {formatDate(data?.OPENED_AT)}
      </Typography>
      <Typography>
        {t("CANDLE_CLOSED_AT")}: {formatDate(data?.CLOSED_AT)}
      </Typography>
      <Typography sx={{ paddingRight: 1 }}>
        {t("CANDLE_OPEN")}: {data?.OPEN || t("SIGNALS_ANALYSIS_NO_DATA")}
      </Typography>
      <Typography sx={{ paddingRight: 1 }}>
        {t("CANDLE_CLOSE")}: {data?.CLOSE || t("SIGNALS_ANALYSIS_NO_DATA")}
      </Typography>
      <Typography sx={{ paddingRight: 1 }}>
        {t("CANDLE_HIGH")}: {data?.HIGH || t("SIGNALS_ANALYSIS_NO_DATA")}
      </Typography>
      <Typography sx={{ paddingRight: 1 }}>
        {t("CANDLE_LOW")}: {data?.LOW || t("SIGNALS_ANALYSIS_NO_DATA")}
      </Typography>
      <Typography>
        {t("VOLUME_LABEL_1")}: {data?.VOLUME || t("SIGNALS_ANALYSIS_NO_DATA")}
      </Typography>
    </Box>
  );
};

export default SignalsAnalysisCandle;
