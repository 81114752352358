import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Box, Typography } from "@mui/material";
import LineChart from "@common/LineChart/LineChart";
import { AppDispatch } from "@store/configureStore";
import { economySelector } from "@components/selectors";
import { TAppState } from "@store/reducers";
import { fetchEconomyGroupData } from "@actions/economy";
import { useGetEconomyGroupsQuery } from "@features/api";
import {
  getYieldLabels,
  getYieldDatasets,
} from "@pages/Economy/Charts/ChartLines";

const YieldChart: React.FC = () => {
  const { t } = useTranslation();
  const { data: groups, isLoading } = useGetEconomyGroupsQuery();
  const dispatch = useDispatch<AppDispatch>();
  const {
    yields: { data = [] },
  } = useSelector((state: TAppState) => economySelector(state));
  const frequency = useMemo(
    () => groups?.yields?.frequency || "M",
    [groups?.yields?.frequency]
  );

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        fetchEconomyGroupData({
          date: DateTime.now()
            .setZone("America/New_York")
            .minus({ days: 1 })
            .startOf("month")
            .minus({ months: 1 })
            .toFormat("yyyy-MM-dd"),
          group: "yields",
          frequency,
        })
      );
    }
  }, [dispatch, groups, frequency, isLoading]);
  return (
    <Box sx={{ width: "100%", paddingBottom: "4%" }}>
      {isLoading || data.length === 0 ? (
        <></>
      ) : (
        <Box>
          <LineChart
            labels={getYieldLabels()}
            datasets={getYieldDatasets(data)}
            height={300}
            title={t(`ECONOMY_YIELD_CHART_TITLE`) || undefined}
            pointRadius={3}
          />
          <Box sx={{ fontSize: "80%", paddingTop: "2%" }}>
            <Typography>{t(`ECONOMY_YIELD_CHART_DESCRIPTION`)}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default YieldChart;
