import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Menu from "@mui/material/Menu";
import { ROUTES } from "@constants/routes";
import { logout } from "@actions/user";
import { AppDispatch } from "@store/configureStore";
import { UserRoles } from "@constants/enums";
import { TAppState } from "@store/reducers";
import { commonSelector, userSelector } from "@components/selectors";

const UserMenu: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const { profile } = useSelector((state: TAppState) => userSelector(state));
  const { settings } = useSelector((state: TAppState) => commonSelector(state));

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    try {
      if (settings) {
        const { authActive } = settings;
        authActive && dispatch(logout()).unwrap();
        navigate(authActive ? ROUTES.LOGIN : ROUTES.HOME);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSettings = () => {
    handleCloseUserMenu();
    try {
      navigate(ROUTES.SETTINGS);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAdmin = () => {
    handleCloseUserMenu();
    try {
      navigate(ROUTES.ADMIN);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            alt={profile?.name || "?"}
            src="/static/images/avatar/2.jpg"
          />
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock
        sx={{ mt: "45px" }}
        id="menu-appbar"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {(profile?.role === UserRoles.Admin ||
          profile?.role === UserRoles.Partner) && (
          <MenuItem
            sx={{
              paddingRight: 1,
              borderBottom: "1px solid rgba(220,220,220, 0.2)",
            }}
          >
            <Box
              onClick={handleAdmin}
              sx={{
                textDecoration: "none",
                color: "inherit",
                minWidth: "130px",
                display: "flex",
              }}
            >
              <Typography sx={{ textAlign: "center", flex: 2 }}>
                {t("USER_MENU_ITEM_ADMIN")}
              </Typography>
              <AdminPanelSettingsIcon sx={{ flex: 1 }} />
            </Box>
          </MenuItem>
        )}
        <MenuItem
          sx={{
            paddingRight: 1,
            borderBottom: "1px solid rgba(220,220,220, 0.2)",
          }}
        >
          <Box
            onClick={handleSettings}
            sx={{
              textDecoration: "none",
              color: "inherit",
              minWidth: "130px",
              display: "flex",
            }}
          >
            <Typography sx={{ textAlign: "center", flex: 2 }}>
              {t("USER_MENU_ITEM_SETTINGS")}
            </Typography>
            <SettingsIcon sx={{ flex: 1 }} />
          </Box>
        </MenuItem>
        <MenuItem sx={{ paddingRight: 1 }}>
          <Box
            onClick={handleLogout}
            sx={{
              textDecoration: "none",
              color: "inherit",
              minWidth: "130px",
              display: "flex",
            }}
          >
            <Typography sx={{ textAlign: "center", flex: 2 }}>
              {t("USER_MENU_ITEM_LOGOUT")}
            </Typography>
            <LogoutIcon sx={{ flex: 1 }} />
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
