import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs } from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import AnalysisGridTab from "@pages/Analysis/Grid/AnalysisGridTab";
import AnalysisChartTab from "@pages/Analysis/Chart/AnalysisChartTab";
import "@styles/Pages/Analysis/analysis.sass";

const AnalysisPage: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("grid");

  const onTabChange = (e: SyntheticEvent, tab: string) => {
    setValue(tab);
  };

  return (
    <Box sx={{ padding: 1 }}>
      <Box sx={{ width: "100%", paddingBottom: "4%" }}>
        <Tabs value={value} onChange={onTabChange} variant="fullWidth">
          <Tab
            icon={<TableViewIcon />}
            iconPosition="start"
            value="grid"
            label={t("ANALYSIS_GRID_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<CandlestickChartIcon />}
            iconPosition="start"
            value="chart"
            label={t("ANALYSIS_CHART_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value === "grid" && <AnalysisGridTab />}
        {value === "chart" && <AnalysisChartTab />}
      </Box>
    </Box>
  );
};

export default AnalysisPage;
