import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Box, Typography } from "@mui/material";
import MeterChart from "@common/MeterChart/MeterChart";
import { TAppState } from "@store/reducers";
import { marketsSelector } from "@components/selectors";

const CryptoMeter: React.FC = () => {
  const {
    fearAndGreed: { crypto },
  } = useSelector((state: TAppState) => marketsSelector(state));
  const [current, setCurrent] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const filtered = crypto.filter(
      (fng) =>
        fng.FG_AT ===
        DateTime.utc().startOf("day").toFormat("yyyy-MM-dd HH:mm:ss")
    );
    const value = filtered.length > 0 ? filtered[0] : null;
    setCurrent(value);
  }, [crypto]);

  const getColor = (percent: number) => {
    if (percent <= 0.25) return "#FF4D17";
    else if (percent > 0.25 && percent <= 0.46) return "#FF8D18";
    else if (percent > 0.46 && percent <= 0.54) return "#FDB737";
    else if (percent > 0.54 && percent <= 0.76) return "#AEB335";
    else if (percent > 0.76) return "#4CB43C";
    else return "#FDB737";
  };

  const getChartColors = () => [
    getColor(0),
    getColor(0.26),
    getColor(0.47),
    getColor(0.55),
    getColor(0.77),
  ];

  return (
    <>
      <Box sx={{ height: 350, width: 400, margin: "auto" }}>
        <Typography variant="h5" sx={{ textAlign: "center", height: 50 }}>
          {t("MARKETS_FEARGREED_CRYPTO_MARKET_GAUGE_HEADER")}
        </Typography>
        {current ? (
          <>
            <Box
              sx={{
                textAlign: "center",
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <Typography component="span">
                {t("MARKETS_FEARGREED_CRYPTO_MARKET_GAUGE_TODAY")}:{" "}
              </Typography>
              <Typography
                component="span"
                sx={{ color: getColor(current.FG_VALUE / 100) }}
              >
                {current?.FG_CLASS}
              </Typography>
            </Box>
            <MeterChart
              arcsLength={[0.25, 0.21, 0.08, 0.22, 0.24]}
              percent={Math.round(current.FG_VALUE) / 100}
              colors={getChartColors()}
              formatText={(value) => value}
              needleColor="#40E0D0"
              needleBaseColor="#40E0D0"
              textColor="#40E0D0"
            />
            <Box sx={{ textAlign: "center" }}>
              {t("MARKETS_FEARGREED_GAUGE_REFERENCE_DATE")}:{" "}
              {DateTime.fromFormat(current.FG_AT, "yyyy-MM-dd HH:mm:ss", {
                zone: "utc",
              }).toFormat("dd/MM/yyyy")}
            </Box>
          </>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", height: 300 }}>
            <Typography variant="h5">
              {t("MARKETS_FEARGREED_NO_DATA_YET")}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CryptoMeter;
