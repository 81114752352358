import React from "react";
import { Box } from "@mui/material";
import RankingsGridFilters from "@pages/Markets/Rankings/Grid/RankingsGridFilters";
import RankingsGrid from "@pages/Markets/Rankings/Grid/RankingsGrid";
import RankingsFaq from "@pages/Markets/Rankings/Faq/RankingsFaq";

const RankingsTab: React.FC = () => {
  return (
    <Box className="rankings-page">
      <RankingsGridFilters />
      <RankingsGrid />
      <RankingsFaq />
    </Box>
  );
};

export default RankingsTab;
