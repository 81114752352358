import i18n from "i18next";
import {
  OpenInFull,
  CloseFullscreen,
  Upload,
  Save,
  Close,
  Delete,
  ContentCopy,
  Download,
  Settings,
  MoreVert,
  Launch,
  ScreenshotMonitor,
  ArrowLeft,
  ArrowRight,
  PlayCircle,
  StopCircle,
  ClearAll,
  ExpandMore,
  RestartAlt,
} from "@mui/icons-material";
import { BaseIconButton } from "@common/BaseButton/BaseIconButton";
import { BaseTextButton } from "@common/BaseButton/BaseTextButton";

type Props = {
  [key: string]: any;
};

export const OpenInFullButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<OpenInFull />}></BaseIconButton>
  );
};

export const CloseFullscreenButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton
      props={props}
      children={<CloseFullscreen />}
    ></BaseIconButton>
  );
};

export const UploadButton: React.FC<Props> = (props) => {
  return <BaseIconButton props={props} children={<Upload />}></BaseIconButton>;
};

export const SaveButton: React.FC<Props> = (props) => {
  return <BaseIconButton props={props} children={<Save />}></BaseIconButton>;
};

export const CloseButton: React.FC<Props> = (props) => {
  return <BaseIconButton props={props} children={<Close />}></BaseIconButton>;
};

export const DeleteButton: React.FC<Props> = (props) => {
  return <BaseIconButton props={props} children={<Delete />}></BaseIconButton>;
};

export const ContentCopyButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<ContentCopy />}></BaseIconButton>
  );
};

export const DownloadButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<Download />}></BaseIconButton>
  );
};

export const SettingsButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<Settings />}></BaseIconButton>
  );
};

export const ScreenshotButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton
      props={props}
      children={<ScreenshotMonitor />}
    ></BaseIconButton>
  );
};

export const MoreVerticalButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<MoreVert />}></BaseIconButton>
  );
};

export const LaunchButton: React.FC<Props> = (props) => {
  return <BaseIconButton props={props} children={<Launch />}></BaseIconButton>;
};

export const PreviousButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<ArrowLeft />}></BaseIconButton>
  );
};

export const NextButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<ArrowRight />}></BaseIconButton>
  );
};

export const PlayButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<PlayCircle />}></BaseIconButton>
  );
};

export const StopButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<StopCircle />}></BaseIconButton>
  );
};

export const ClearAllButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<ClearAll />}></BaseIconButton>
  );
};

export const ExpandMoreButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<ExpandMore />}></BaseIconButton>
  );
};

export const RestartButton: React.FC<Props> = (props) => {
  return (
    <BaseIconButton props={props} children={<RestartAlt />}></BaseIconButton>
  );
};

export const OkTextButton: React.FC<Props> = (props) => {
  return (
    <BaseTextButton
      props={props}
      text={i18n.t("BASE_TEXT_BUTTON_OK_LABEL")}
    ></BaseTextButton>
  );
};

export const CancelTextButton: React.FC<Props> = (props) => {
  return (
    <BaseTextButton
      props={props}
      text={i18n.t("BASE_TEXT_BUTTON_CANCEL_LABEL")}
    ></BaseTextButton>
  );
};
