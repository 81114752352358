/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { AppDispatch } from "@store/configureStore";
import { socket } from "@utilities/socket";
import { taskChanged } from "@store/reducers/admin";
import TaskItemCode from "@pages/Admin/ScheduledTasks/TaskItem/TaskItemCode";
import TaskItemRunning from "@pages/Admin/ScheduledTasks/TaskItem/TaskItemRunning";
import TaskItemDescription from "@app/components/Pages/Admin/ScheduledTasks/TaskItem/TaskItemDescription";
import TaskItemExpression from "@pages/Admin/ScheduledTasks/TaskItem/TaskItemExpression";
import TaskItemStatus from "@pages/Admin/ScheduledTasks/TaskItem/TaskItemStatus";
import TaskItemActions from "@pages/Admin/ScheduledTasks/TaskItem/TaskItemActions";

type Props = {
  task: any;
};

const TaskItem: React.FC<Props> = ({ task }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setContainerWidth(entry.contentRect.width);
        }
      });
      observer.observe(container);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const onSocket = (task: any) => {
      dispatch(taskChanged(task));
    };
    socket.on("cron_task_started", onSocket);
    socket.on("cron_task_finished", onSocket);
    socket.on("cron_task_enabled", onSocket);
    socket.on("cron_task_disabled", onSocket);
    return () => {
      socket.off("cron_task_started", onSocket);
      socket.off("cron_task_finished", onSocket);
      socket.off("cron_task_enabled", onSocket);
      socket.off("cron_task_disabled", onSocket);
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        mb: 2,
        display: "flex",
        height: 60,
        gap: "2%",
        padding: "10px",
        border: "1px solid rgba(211,211,211,0.3)",
        borderRadius: "5px",
      }}
    >
      <TaskItemCode task={task} />
      {containerWidth > 1000 && <TaskItemDescription task={task} />}
      {containerWidth > 500 && <TaskItemRunning task={task} />}
      {containerWidth > 800 && <TaskItemExpression task={task} />}
      {containerWidth > 800 && <TaskItemStatus task={task} />}
      {containerWidth > 250 && <TaskItemActions task={task} />}
    </Box>
  );
};

export default TaskItem;
