import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "@store/reducers";
import ChartTopBar from "@common/ChartCommon/TopBar/ChartTopBar";
import ChartDialog from "@common/ChartCommon/ChartDialog";
import ChartsFilterArea from "@pages/Charts/Live/ChartsFilterArea";
import PriceChart from "@pages/Charts/Common/Charts/PriceChart";
import RangeChart from "@pages/Charts/Common/Charts/RangeChart";
import NoRangeChart from "@pages/Charts/Common/Charts/NoRangeChart";
import HistogramChart from "@pages/Charts/Common/Charts/HistogramChart";
import { chartsSelector } from "@components/selectors";
import ChartLiveDialog from "@pages/Charts/Live/Dialog/ChartsLiveDialog";
import { createIndicatorsConfig } from "@pages/helpers";
import { useGetActiveIndicatorsQuery } from "@features/api";
import { TIndicatorsConfig } from "@app/types/entities";

const ChartsLiveTab: React.FC = () => {
  const {
    live: {
      data,
      filters: { period, pair, indicators, patterns, signals },
      popup: { active },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));
  const { data: indicatorData, isLoading: indicatorsLoading } =
    useGetActiveIndicatorsQuery();
  const [priceDialogIsOpen, setPriceDialogIsOpen] = useState(false);
  const [rangeDialogIsOpen, setRangeDialogIsOpen] = useState(false);
  const [noRangeDialogIsOpen, setNoRangeDialogIsOpen] = useState(false);
  const [histogramDialogIsOpen, setHistogramDialogIsOpen] = useState(false);
  const [lineBarConfig, setLineBarConfig] = useState<TIndicatorsConfig>();

  useEffect(() => {
    if (period && !indicatorsLoading) {
      setLineBarConfig(createIndicatorsConfig(period, indicatorData));
    }
  }, [indicatorData, indicatorsLoading, period]);

  return (
    <Box className="charts-live-page">
      {active && <ChartLiveDialog />}
      <Box>
        <ChartsFilterArea />
      </Box>
      <Box>
        <ChartTopBar
          dialogOpen={false}
          onDialogOpen={() => setPriceDialogIsOpen(true)}
        />
        <ChartDialog
          dialogOpen={priceDialogIsOpen}
          onDialogClose={() => setPriceDialogIsOpen(false)}
          chartChild={
            <PriceChart
              pair={pair}
              candleData={data}
              indicators={indicators}
              patterns={patterns}
              signals={signals}
              lineBarConfig={lineBarConfig}
              autoSize
              readFromCandles
            />
          }
        />
        <PriceChart
          pair={pair}
          candleData={data}
          indicators={indicators}
          patterns={patterns}
          signals={signals}
          lineBarConfig={lineBarConfig}
          readFromCandles
          publishActions
        />
      </Box>
      <Box>
        <ChartTopBar
          dialogOpen={false}
          onDialogOpen={() => setRangeDialogIsOpen(true)}
        />
        <ChartDialog
          dialogOpen={rangeDialogIsOpen}
          onDialogClose={() => setRangeDialogIsOpen(false)}
          chartChild={
            <RangeChart
              pair={pair}
              candleData={data}
              indicators={indicators}
              lineBarConfig={lineBarConfig}
            />
          }
        />
        <RangeChart
          pair={pair}
          candleData={data}
          indicators={indicators}
          lineBarConfig={lineBarConfig}
          subscribeActions
        />
      </Box>
      <Box>
        <ChartTopBar
          dialogOpen={false}
          onDialogOpen={() => setNoRangeDialogIsOpen(true)}
        />
        <ChartDialog
          dialogOpen={noRangeDialogIsOpen}
          onDialogClose={() => setNoRangeDialogIsOpen(false)}
          chartChild={
            <NoRangeChart
              pair={pair}
              candleData={data}
              indicators={indicators}
              lineBarConfig={lineBarConfig}
            />
          }
        />
        <NoRangeChart
          pair={pair}
          candleData={data}
          indicators={indicators}
          lineBarConfig={lineBarConfig}
          subscribeActions
        />
      </Box>
      <Box>
        <ChartTopBar
          dialogOpen={false}
          onDialogOpen={() => setHistogramDialogIsOpen(true)}
        />
        <ChartDialog
          dialogOpen={histogramDialogIsOpen}
          onDialogClose={() => setHistogramDialogIsOpen(false)}
          chartChild={
            <HistogramChart
              pair={pair}
              candleData={data}
              indicators={indicators}
              lineBarConfig={lineBarConfig}
            />
          }
        />
        <HistogramChart
          pair={pair}
          candleData={data}
          indicators={indicators}
          lineBarConfig={lineBarConfig}
          subscribeActions
        />
      </Box>
    </Box>
  );
};

export default ChartsLiveTab;
