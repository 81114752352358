import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LockOutlined } from "@mui/icons-material";
import { Box, Avatar, Typography, Button } from "@mui/material";
import { AppDispatch } from "@store/configureStore";
import { ROUTES } from "@constants/routes";
import { fetchSession, login, requestTwoFactor } from "@actions/user";
import { TAppState } from "@store/reducers";
import { commonSelector } from "@components/selectors";
import BaseEmail from "@common/BaseFields/BaseEmail/BaseEmail";
import BasePassword from "@common/BaseFields/BasePassword/BasePassword";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { settings } = useSelector((state: TAppState) => commonSelector(state));

  const handleLogin = async () => {
    if (email && password) {
      try {
        const { twoFactor } = await dispatch(
          login({ email, password })
        ).unwrap();
        if (twoFactor) {
          navigate(ROUTES.TFA, { state: { email, password } });
        } else if (settings?.forceTwoFactor && !twoFactor) {
          const { secret, uri } = await dispatch(requestTwoFactor()).unwrap();
          navigate(ROUTES.TFA_SETUP, {
            state: { email, password, twoFactor: { secret, uri } },
          });
        } else {
          await dispatch(fetchSession()).unwrap();
          navigate(ROUTES.HOME);
        }
      } catch (e) {
        setEmail("");
        setPassword("");
      }
    }
  };

  return (
    <Box
      sx={{
        mt: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 1,
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "primary.light" }}>
        <LockOutlined />
      </Avatar>
      <Typography variant="h5">{t("LOGIN_PAGE_HEADER")}</Typography>
      <Box sx={{ mt: 1, display: "grid" }}>
        <BaseEmail
          email={email}
          onChange={(email: string) => setEmail(email)}
          onKeyDown={(key: string) => key === "Enter" && handleLogin()}
        />
        <BasePassword
          password={password}
          onChange={(password: string) => setPassword(password)}
          onKeyDown={(key: string) => key === "Enter" && handleLogin()}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleLogin}
        >
          {t("LOGIN_PAGE_BUTTON")}
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
