import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ANALYSIS_FETCH_BY_OPENED_AT,
  ANALYSIS_FETCH_BY_PAIR_ID,
} from "@actions/_types";
import { setInterceptors, clearInterceptors } from "@features/interceptors/app";
import { analysesUrl } from "@constants/endpoints";

type TFetchAnalysisPayload = {
  periodId: number;
  analysisId?: number;
  pairId?: number;
  openedAt?: string;
  closedAt?: string;
};

const fetchAnalysis = async (params: TFetchAnalysisPayload) => {
  const {
    periodId,
    pairId = null,
    analysisId = null,
    openedAt = null,
    closedAt = null,
  } = params;
  const intercepted = setInterceptors(axios.create({ withCredentials: true }));
  let url = `${analysesUrl}?periodId=${periodId}`;
  url += analysisId ? `&analysisId=${analysisId}` : ``;
  url += pairId ? `&pairId=${pairId}` : ``;
  url += openedAt ? `&openedAt=${openedAt}` : ``;
  url += closedAt ? `&closedAt=${closedAt}` : ``;
  const response = await intercepted.instance.get(url);
  clearInterceptors(intercepted);
  return response.data;
};

export const fetchAnalysisByOpenedAt = createAsyncThunk(
  ANALYSIS_FETCH_BY_OPENED_AT,
  async (params: TFetchAnalysisPayload) => await fetchAnalysis(params)
);

export const fetchAnalysisByPair = createAsyncThunk(
  ANALYSIS_FETCH_BY_PAIR_ID,
  async (params: TFetchAnalysisPayload) => await fetchAnalysis(params)
);
