import { validateSignalByName } from "@common/helpers";

export const getCountConfig = (code: string) => {
  switch (code) {
    case "cross":
      return [
        {
          key: "CROSS_50_200",
          value: 1,
          color: "rgba(218, 165, 32, 0.5)",
          text: "SIGNALS_CROSS_IS_GOLDEN_50_200",
        },
        {
          key: "CROSS_50_200",
          value: 2,
          color: "rgba(128, 0, 128, 0.5)",
          text: "SIGNALS_CROSS_IS_DEATH_50_200",
        },
      ];
    case "macd4cdiv":
      return [
        {
          key: "CLASSIC_20_50_9",
          value: 1,
          color: "rgba(8, 153, 29, 0.5)",
          text: "SIGNALS_MACD4CDIV_C_BULL_20_50_9",
        },
        {
          key: "CLASSIC_20_50_9",
          value: 2,
          color: "rgba(242, 54, 69, 0.5)",
          text: "SIGNALS_MACD4CDIV_C_BEAR_20_50_9",
        },
        {
          key: "HIDDEN_20_50_9",
          value: 1,
          color: "rgba(8, 153, 29, 0.2)",
          text: "SIGNALS_MACD4CDIV_H_BULL_20_50_9",
        },
        {
          key: "HIDDEN_20_50_9",
          value: 2,
          color: "rgba(242, 54, 69, 0.2)",
          text: "SIGNALS_MACD4CDIV_H_BEAR_20_50_9",
        },
      ];
    case "escan":
      return [
        {
          key: "ESCAN_5",
          value: 1,
          color: "rgba(8, 153, 29, 0.5)",
          text: "SIGNALS_ESCAN_IS_BUY_5",
        },
        {
          key: "ESCAN_5",
          value: 2,
          color: "rgba(242, 54, 69, 0.5)",
          text: "SIGNALS_ESCAN_IS_SELL_5",
        },
      ];
    case "uscan":
      return [
        {
          key: "USCAN_5",
          value: 1,
          color: "rgba(8, 153, 29, 0.5)",
          text: "SIGNALS_USCAN_IS_BUY_5",
        },
        {
          key: "USCAN_5",
          value: 2,
          color: "rgba(242, 54, 69, 0.5)",
          text: "SIGNALS_USCAN_IS_SELL_5",
        },
      ];
    default:
      return [];
  }
};

export const getSignalMatches = (keyValue: any, data: Array<any>) => {
  return data.filter((row) => validateSignalByName(keyValue, row)).length;
};

export const getPercent = (keyValue: any, data: Array<any>, count: number) => {
  return Number((getSignalMatches(keyValue, data) / count).toFixed(4)) * 100;
};
