import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import LogItemLevel from "@pages/Admin/Logs/LogItem/LogItemLevel";
import LogItemMessage from "@pages/Admin/Logs/LogItem/LogItemMessage";
import LogItemType from "@pages/Admin/Logs/LogItem/LogItemType";
import LogItemDate from "@pages/Admin/Logs/LogItem/LogItemDate";
import LogItemActions from "@pages/Admin/Logs/LogItem/LogItemActions";

type Props = {
  log: any;
};

const LogItem: React.FC<Props> = ({ log }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setContainerWidth(entry.contentRect.width);
        }
      });
      observer.observe(container);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        mb: 2,
        display: "flex",
        height: 60,
        gap: "2%",
        padding: "10px",
        border: "1px solid rgba(211,211,211,0.3)",
        borderRadius: "5px",
      }}
    >
      <LogItemLevel log={log} />
      {containerWidth > 1000 && <LogItemMessage log={log} />}
      {containerWidth > 1500 && <LogItemType log={log} />}
      <LogItemDate log={log} />
      <LogItemActions log={log} />
    </Box>
  );
};

export default LogItem;
