import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { TAppState } from "@store/reducers";
import { signalSelector } from "@components/selectors";

const SignalsAnalysisHeader: React.FC = () => {
  const {
    analysis: {
      filters: { period, pair },
    },
  } = useSelector((state: TAppState) => signalSelector(state));

  return (
    <Box sx={{ paddingBottom: 2, marginBottom: 2 }}>
      <Typography variant="h5">
        {pair?.SYMBOL.replaceAll(pair.SYMBOL_PREFIX, "")} ({period?.SYMBOL}) /{" "}
        {pair?.TYPE_NAME} / {pair?.PROVIDER_NAME}
      </Typography>
    </Box>
  );
};

export default SignalsAnalysisHeader;
