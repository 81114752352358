import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { TAppState } from "@store/reducers";
import { AppDispatch } from "@store/configureStore";
import { commonSelector } from "@components/selectors";
import BaseSelect from "@common/BaseSelect/BaseSelect";
import { changeLanguage } from "@store/reducers/common";
import { setLanguageInStorage } from "@app/utilities/helpers";

const LanguageSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { language } = useSelector((state: TAppState) => commonSelector(state));

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t("SETTINGS_DISPLAY_LANGUAGE_HEADER")}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flex: 3, mr: 2 }}>
          <Typography>{t("SETTINGS_DISPLAY_LANGUAGE_MESSAGE")}</Typography>
        </Box>
        <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          <BaseSelect
            minWidth={200}
            maxWidth={200}
            inputLabel={t("APP_LANGUAGE_LABEL")}
            data={[
              { title: t("APP_LANGUAGE_OPTION_EL"), locale: "el" },
              { title: t("APP_LANGUAGE_OPTION_EN"), locale: "en" },
            ]}
            selected={[language]}
            onChange={(row: any) => {
              const { locale } = row;
              dispatch(changeLanguage(locale));
              setLanguageInStorage(locale);
              window.location.reload();
            }}
            displayKey="title"
            selectKey="locale"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LanguageSettings;
