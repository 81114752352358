import { FC, useEffect } from "react";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "@styles/main.sass";
import { light, dark } from "@app/theme";
import { store } from "@store/configureStore";
import Header from "@general/Header/Header";
import Footer from "@general/Footer/Footer";
import {
  getLanguageInStorage,
  getSettingsInStorage,
  getThemeInStorage,
  getUserInStorage,
} from "@utilities/helpers";
import CustomRouter from "@general/CustomRouter/CustomRouter";
import {
  changeLanguage,
  changeSettings,
  changeTheme,
} from "@store/reducers/common";
import { changeProfile } from "@store/reducers/user";

const App: FC = () => {
  /*
   * Cannot use a reducer hood here, only inside <Provider/> context
   * Therefore getting theme directly from local storage
   */
  const settings = getSettingsInStorage();
  const theme = getThemeInStorage();
  const language = getLanguageInStorage();
  const user = getUserInStorage();

  useEffect(() => {
    theme && store.dispatch(changeTheme(theme));
    language && store.dispatch(changeLanguage(language));
    settings && store.dispatch(changeSettings(settings));
    user && store.dispatch(changeProfile(user));
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme === "light" ? light : dark}>
        <CssBaseline />
        <Provider store={store}>
          <CustomRouter
            headerChild={[<Header key={1} />]}
            footerChild={[<Footer key={2} />]}
          />
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
