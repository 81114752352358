import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { CronExpressionParser } from "cron-parser";
import { Box, TextField, Typography } from "@mui/material";
import { TAppState } from "@store/reducers";
import { commonSelector } from "@components/selectors";

type Props = {
  task: any;
};

const TaskItemDetails: React.FC<Props> = ({ task }) => {
  const { t } = useTranslation();
  const [lastStarted, setLastStarted] = useState<any>(null);
  const [lastEnded, setLastEnded] = useState<any>(null);
  const [lastFailed, setLastFailed] = useState<any>(null);
  const [executions, setExecutions] = useState<any>({ next: [], previous: "" });
  const {
    settings: { serverTimezone },
    language,
  } = useSelector((state: TAppState) => commonSelector(state));

  useEffect(() => {
    task.LAST_STARTED !== null &&
      setLastStarted(
        DateTime.fromFormat(task.LAST_STARTED, "yyyy-MM-dd HH:mm:ss", {
          zone: serverTimezone,
          locale: language,
        })
      );
    task.LAST_ENDED !== null &&
      setLastEnded(
        DateTime.fromFormat(task.LAST_ENDED, "yyyy-MM-dd HH:mm:ss", {
          zone: serverTimezone,
          locale: language,
        })
      );
    task.LAST_FAILED !== null &&
      setLastEnded(
        DateTime.fromFormat(task.LAST_FAILED, "yyyy-MM-dd HH:mm:ss", {
          zone: serverTimezone,
          locale: language,
        })
      );
  }, [language, serverTimezone, task]);

  useEffect(() => {
    if (serverTimezone) {
      const getExecutions = (task: any, count: number) => {
        const executions = { next: [] as Array<any>, previous: null as any };
        try {
          const now = DateTime.utc();
          const interval = CronExpressionParser.parse(task.EXPRESSION, {
            currentDate: now.toISO(),
            endDate: now.plus({ months: 1 }).toISO(),
            tz: serverTimezone,
          });
          executions.next = interval.take(count).map((date) => date.toDate());
          executions.previous = interval.prev().toDate();
        } catch (e) {
          console.log(e);
        }
        return executions;
      };
      setExecutions(getExecutions(task, 5));
      setInterval(() => {
        setExecutions(getExecutions(task, 5));
      }, 5000);
    }
  }, [serverTimezone, task]);

  return (
    <Box sx={{ padding: "5%" }}>
      <Box sx={{ display: "flex", gap: 5, paddingBottom: 1 }}>
        <Typography sx={{ width: 200 }}>
          {t("ADMIN_SCHEDULED_TASKS_CODE")}:
        </Typography>
        <Typography>{task?.CODE}</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 5, paddingBottom: 1 }}>
        <Typography sx={{ width: 200 }}>
          {t("ADMIN_SCHEDULED_TASKS_EXPRESSION")}:
        </Typography>
        <Typography>{task?.EXPRESSION}</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 5, paddingBottom: 1 }}>
        <Typography sx={{ width: 200 }}>
          {t("ADMIN_SCHEDULED_TASKS_DESCRIPTION")}:
        </Typography>
        <Typography>{task?.DESCRIPTION}</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 5, paddingBottom: 1 }}>
        <Typography sx={{ width: 200 }}>
          {t("ADMIN_SCHEDULED_TASKS_STATUS")}:
        </Typography>
        <Typography>
          {task?.STATUS === 1
            ? t("ADMIN_SCHEDULED_TASKS_STATUS_ACTIVE")
            : t("ADMIN_SCHEDULED_TASKS_STATUS_INACTIVE")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 5, paddingBottom: 1 }}>
        <Typography sx={{ width: 200 }}>
          {t("ADMIN_SCHEDULED_TASKS_LAST_STARTED")}:
        </Typography>
        <Box>
          {lastStarted === null ? (
            <Typography>
              {t("ADMIN_SCHEDULED_TASKS_LAST_STARTED_NOT_YET")}
            </Typography>
          ) : (
            <Typography>
              {lastStarted.toFormat("dd-MM-yyyy HH:mm:ss")} {"("}
              {lastStarted?.offsetNameLong}
              {")"}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 5, paddingBottom: 1 }}>
        <Typography sx={{ width: 200 }}>
          {t("ADMIN_SCHEDULED_TASKS_LAST_ENDED")}:
        </Typography>
        <Box>
          {lastEnded === null ? (
            <Typography>
              {t("ADMIN_SCHEDULED_TASKS_LAST_ENDED_NOT_YET")}
            </Typography>
          ) : (
            <Typography>
              {lastEnded.toFormat("dd-MM-yyyy HH:mm:ss")} {"("}
              {lastEnded?.offsetNameLong}
              {")"}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 5, paddingBottom: 1 }}>
        <Typography sx={{ width: 200 }}>
          {t("ADMIN_SCHEDULED_TASKS_LAST_FAILED")}:
        </Typography>
        <Box>
          {lastFailed === null ? (
            <Typography>
              {t("ADMIN_SCHEDULED_TASKS_LAST_FAILED_NOT_YET")}
            </Typography>
          ) : (
            <Typography>
              {lastFailed.toFormat("dd-MM-yyyy HH:mm:ss")} {"("}
              {lastFailed?.offsetNameLong}
              {")"}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 5, paddingBottom: 1 }}>
        <Typography sx={{ width: 200 }}>
          {t("ADMIN_SCHEDULED_TASKS_TIMEZONE")}:
        </Typography>
        <Typography>{task?.TIMEZONE}</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 5, paddingBottom: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ width: 200 }}>
            {t("ADMIN_SCHEDULED_TASKS_NEXT_EXECUTIONS")}:
          </Typography>
        </Box>
        <Box>
          <TextField
            sx={{ minWidth: 100, width: 350, maxWidth: 350 }}
            multiline
            value={executions.next
              .map((value: any) =>
                DateTime.fromJSDate(value).toFormat("DDDD TT", {
                  locale: language,
                })
              )
              .join("\n")}
            fullWidth
            rows={5}
            onMouseDown={(e: any) => e.preventDefault()}
            onTouchStart={(e: any) => e.preventDefault()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TaskItemDetails;
