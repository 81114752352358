import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { AppDispatch } from "@store/configureStore";
import { fetchAppSettings } from "@actions/common";
import ScrollToTopButton from "@general/ScrollToTop/ScrollToTop";
import Notifications from "@general/Notifications/Notifications";
import Spinner from "@general/Spinner/Spinner";
import { FOOTER_MIN_HEIGHT, HEADER_MIN_HEIGHT } from "@constants/settings";

type Props = {
  pageChild: React.ReactNode;
};

const Content: React.FC<Props> = ({ pageChild }) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchAppSettings());
  });

  return (
    <Box
      className="app-content-container"
      sx={{
        minHeight: window.innerHeight - HEADER_MIN_HEIGHT - FOOTER_MIN_HEIGHT,
      }}
    >
      {pageChild}
      <Notifications />
      <Spinner />
      <ScrollToTopButton />
    </Box>
  );
};

export default Content;
