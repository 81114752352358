import React, { SyntheticEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BugReportIcon from "@mui/icons-material/BugReport";
import { AppDispatch } from "@store/configureStore";
import { TAppState } from "@store/reducers";
import { fetchSettings } from "@actions/user";
import LogsTab from "@pages/Admin/Logs/LogsTab";
import ScheduledTasksTab from "@pages/Admin/ScheduledTasks/ScheduledTasksTab";
import BacktestingTab from "@pages/Admin/Backtesting/BacktestingTab";
import { commonSelector } from "@components/selectors";

const AdminPage: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("logs");
  const dispatch = useDispatch<AppDispatch>();
  const {
    settings: { enableCronJobs },
  } = useSelector((state: TAppState) => commonSelector(state));

  const onTabChange = (e: SyntheticEvent, tab: string) => {
    setValue(tab);
  };

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  return (
    <Box sx={{ padding: 1 }}>
      <Box sx={{ width: "100%", paddingBottom: "4%" }}>
        <Tabs value={value} onChange={onTabChange} variant="fullWidth">
          <Tab
            icon={<DescriptionIcon />}
            iconPosition="start"
            value="logs"
            label={t("ADMIN_LOGS_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          {enableCronJobs && (
            <Tab
              icon={<ScheduleIcon />}
              iconPosition="start"
              value="scheduled-tasks"
              label={t("ADMIN_SCHEDULED_TASKS_TAB_LABEL")}
              sx={{ fontSize: "70%" }}
            />
          )}
          <Tab
            icon={<BugReportIcon />}
            iconPosition="start"
            value="backtesting"
            label={t("ADMIN_BACKTESTING_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value === "logs" && <LogsTab />}
        {value === "scheduled-tasks" && <ScheduledTasksTab />}
        {value === "backtesting" && <BacktestingTab />}
      </Box>
    </Box>
  );
};

export default AdminPage;
