import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { TAppState } from "@store/reducers";
import { AppDispatch } from "@store/configureStore";
import { commonSelector } from "@components/selectors";
import BaseSelect from "@common/BaseSelect/BaseSelect";
import { changeTheme } from "@store/reducers/common";
import { setThemeInStorage } from "@utilities/helpers";

const ThemeSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { theme } = useSelector((state: TAppState) => commonSelector(state));

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t("SETTINGS_DISPLAY_THEME_HEADER")}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flex: 3, mr: 2 }}>
          <Typography>{t("SETTINGS_DISPLAY_THEME_MESSAGE")}</Typography>
        </Box>
        <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          <BaseSelect
            minWidth={200}
            maxWidth={200}
            inputLabel={t("APP_THEME_LABEL")}
            data={[
              { title: t("APP_THEME_OPTION_LIGHT"), theme: "light" },
              { title: t("APP_THEME_OPTION_DARK"), theme: "dark" },
            ]}
            selected={[theme]}
            onChange={(row: any) => {
              const { theme } = row;
              dispatch(changeTheme(theme));
              setThemeInStorage(theme);
              window.location.reload();
            }}
            displayKey="title"
            selectKey="theme"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ThemeSettings;
