import React from "react";
import { Box } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ClearIcon from "@mui/icons-material/Clear";

type Props = {
  task: any;
};

const TaskItemRunning: React.FC<Props> = ({ task }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
      {task?.IS_RUNNING === 1 ? (
        <AutorenewIcon
          sx={{
            animation: "spin 2s linear infinite",
            "@keyframes spin": {
              "0%": {
                transform: "rotate(360deg)",
              },
              "100%": {
                transform: "rotate(0deg)",
              },
            },
          }}
        />
      ) : (
        <ClearIcon />
      )}
    </Box>
  );
};

export default TaskItemRunning;
