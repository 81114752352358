/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { AppDispatch } from "@store/configureStore";
import { fetchAppLogs } from "@actions/logs";
import { TAppState } from "@store/reducers";
import { adminSelector } from "@components/selectors";
import { ExpandMoreButton } from "@common/BaseButton/Buttons";
import LogItem from "@pages/Admin/Logs/LogItem/LogItem";

const LogList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const batchSize = 10;
  const [limit] = useState<number>(batchSize);
  const [offset, setOffset] = useState<number>(0);
  const {
    logs: {
      list: { last, all },
    },
  } = useSelector((state: TAppState) => adminSelector(state));

  const onMore = () => {
    setOffset(offset + batchSize);
  };

  useEffect(() => {
    dispatch(fetchAppLogs({ limit, offset }));
  }, [offset]);

  return (
    <Box sx={{ margin: "2px 0 2px 0" }}>
      <Box>
        {all.map((log, i) => (
          <LogItem key={i} log={log} />
        ))}
      </Box>
      {last.length > 0 && (
        <Box sx={{ textAlign: "center" }}>
          <ExpandMoreButton onClick={onMore} />
        </Box>
      )}
    </Box>
  );
};

export default LogList;
