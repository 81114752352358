export const getFaqConfig = () => {
  return [
    {
      title: "MARKETS_RANKING_FAQ_TITLE_MC_RANK",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_MC_RANK_1D",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK_1D",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_MC_RANK_7D",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK_7D",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_MC_RANK_30D",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_MC_RANK_30D",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_LQ_RANK",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_LQ_RANK_1D",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK_1D",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_LQ_RANK_7D",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK_7D",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_LQ_RANK_30D",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_LQ_RANK_30D",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_VL_RANK",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_VL_RANK_1D",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK_1D",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_VL_RANK_7D",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK_7D",
    },
    {
      title: "MARKETS_RANKING_FAQ_TITLE_VL_RANK_30D",
      description: "MARKETS_RANKING_FAQ_DESCRIPTION_VL_RANK_30D",
    },
  ];
};
