import i18n from "i18next";

export const getLegendLabel = (type: string, dynamic: any) => {
  switch (type) {
    case "dominance":
      return i18n.t("MARKET_CRYPTO_METRICS_DOMINANCE", dynamic);
    case "exchanges_supply_usd":
      return i18n.t("MARKET_CRYPTO_METRICS_EXCHANGES_SUPPLY_USD", dynamic);
    case "exchanges_inflows_ntv":
      return i18n.t("MARKET_CRYPTO_METRICS_EXCHANGES_INFLOWS_NTV", dynamic);
    case "exchanges_outflows_ntv":
      return i18n.t("MARKET_CRYPTO_METRICS_EXCHANGES_OUTFLOWS_NTV", dynamic);
    default:
      return dynamic;
  }
};

export const getLineConfig = (type: string) => {
  switch (type) {
    case "dominance":
      return {
        lineWidth: 2,
        lineColor: "#ffa200",
        topColor: "rgba(255, 162, 0, 0.60)",
        bottomColor: "rgba(255, 162, 0, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "exchanges_supply_usd":
      return {
        lineWidth: 2,
        lineColor: "#BF40BF",
        topColor: "rgba(191, 64, 191, 0.60)",
        bottomColor: "rgba(191, 64, 191, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "exchanges_inflows_ntv":
      return {
        lineWidth: 2,
        lineColor: "#FF3131",
        topColor: "rgba(255, 49, 49, 0.60)",
        bottomColor: "rgba(255, 49, 49, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    case "exchanges_outflows_ntv":
      return {
        lineWidth: 2,
        lineColor: "#0FFF50",
        topColor: "rgba(15, 255, 80, 0.60)",
        bottomColor: "rgba(15, 255, 80, 0.30)",
        lastValueVisible: true,
        priceLineVisible: false,
      };
    /* Default */
    default:
      return {
        color: "#21B6A8",
        topColor: "rgba(33, 182, 168, 0.60)",
        bottomColor: "rgba(33, 182, 168, 0.30)",
        lineWidth: 2,
        lastValueVisible: true,
        priceLineVisible: false,
      };
  }
};
