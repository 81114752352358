import React from "react";
import { Box } from "@mui/material";
import CryptoMetricsFilters from "@pages/Markets/CryptoMetrics/CryptoMetricsFilters";
import CryptoMetricsCharts from "@pages/Markets/CryptoMetrics/CryptoMetricsCharts";

const CryptoMetricsTab: React.FC = () => {
  return (
    <Box className="crypto-metrics-page">
      <Box>
        <CryptoMetricsFilters />
        <CryptoMetricsCharts />
      </Box>
    </Box>
  );
};

export default CryptoMetricsTab;
