import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container, Box } from "@mui/material";
import logo from "@images/logo192.png";
import { AppDispatch } from "@store/configureStore";
import { TAppState } from "@store/reducers";
import { commonSelector } from "@components/selectors";
import "@styles/Pages/Home/home.sass";
import { fetchSession } from "@actions/user";

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const {
    settings: { authActive },
  } = useSelector((state: TAppState) => commonSelector(state));

  useEffect(() => {
    if (authActive) dispatch(fetchSession());
  });

  return (
    <Container sx={{ padding: 1 }}>
      <Box sx={{ textAlign: "center", mt: 20 }}>
        <Box
          component="img"
          sx={{ height: 150, width: 150 }}
          src={logo}
          alt={t("APP_NAME")}
        />
      </Box>
    </Container>
  );
};

export default HomePage;
