import { enSignalsGrid, elSignalsGrid } from "@pages/Signals/Grid/translations";
import {
  enSignalsAnalysis,
  elSignalsAnalysis,
} from "@pages/Signals/Analysis/translations";

export const enSignalsPage = {
  SIGNAL_GRID_TAB_LABEL: "Tables",
  SIGNAL_ANALYSIS_TAB_LABEL: "Analyses",
  ...enSignalsGrid,
  ...enSignalsAnalysis,
};

export const elSignalsPage = {
  SIGNAL_GRID_TAB_LABEL: "Πίνακες",
  SIGNAL_ANALYSIS_TAB_LABEL: "Αναλύσεις",
  ...elSignalsGrid,
  ...elSignalsAnalysis,
};
