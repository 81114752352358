import {
  enAnalysisGrid,
  elAnalysisGrid,
} from "@pages/Analysis/Grid/translations";
import {
  enAnalysisChart,
  elAnalysisChart,
} from "@pages/Analysis/Chart/translations";

export const enAnalysisPage = {
  ANALYSIS_GRID_TAB_LABEL: "Tables",
  ANALYSIS_CHART_TAB_LABEL: "Charts",
  ...enAnalysisGrid,
  ...enAnalysisChart,
};

export const elAnalysisPage = {
  ANALYSIS_GRID_TAB_LABEL: "Πίνακες",
  ANALYSIS_CHART_TAB_LABEL: "Γραφήματα",
  ...elAnalysisGrid,
  ...elAnalysisChart,
};
