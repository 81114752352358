import React from "react";
import { Box } from "@mui/material";
import { CloseButton } from "@common/BaseButton/Buttons";

type Props = {
  task?: any;
  onClose?: (open: boolean) => void;
};

const TaskItemDetailsTopBar: React.FC<Props> = ({ task, onClose }) => {
  return (
    <Box
      sx={{
        textAlign: "right",
        width: "100%",
        background: "rgba(96, 130, 182, 0.6)",
      }}
    >
      <CloseButton onClick={onClose} />
    </Box>
  );
};

export default TaskItemDetailsTopBar;
