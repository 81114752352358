import { AppModes } from "@constants/enums";

export const APP = {
  MODE: AppModes.Production,
};

export const SNACKBAR_DURATION = 3000;

export const POLLING_MILLIS = 5000;

export const HEADER_MIN_HEIGHT = 65;

export const FOOTER_MIN_HEIGHT = 100;

export const API = {
  HTTP_URL: `https://api.dcatrader.com`,
  WS_URL: `wss://api.dcatrader.com`,
  TIMEOUT: 30000,
  EXTENDED_TIMEOUT: 70000,
};

export const TWO_FACTOR_AUTH = {
  AUTH_CODE_LENGTH: 6,
};
