import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Spinner: React.FC = () => {
  return (
    <Box
      className="app-spinner-container hide"
      sx={{
        position: "fixed",
        top: "45%",
        left: "50%",
        zIndex: 300000000,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
