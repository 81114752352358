import React from "react";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid";

type Props = {
  gridKey: string;
  columns: GridColDef<GridValidRowModel>[];
  data: Array<any>;
  visibility?: GridColumnVisibilityModel | undefined;
  getRowClassName?: (params: any) => string;
  sortModel?: Array<any>;
  slots?: any;
  slotProps?: any;
};

const Grid: React.FC<Props> = ({
  gridKey,
  columns,
  data,
  visibility = undefined,
  getRowClassName = (params: any) => "",
  sortModel = [],
  slots,
  slotProps,
}) => {
  const rows = data.map((row) => {
    let other = {};
    for (const c of columns) {
      other[c.field] = row[c.field];
    }
    return { id: row[gridKey], ...other };
  });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      getRowClassName={(params: any) => getRowClassName(params)}
      initialState={{
        sorting: {
          sortModel,
        },
        columns: {
          columnVisibilityModel: visibility,
        },
      }}
      slots={slots}
      slotProps={slotProps}
    />
  );
};

export default Grid;
