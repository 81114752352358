import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ECONOMY_ANALYSIS_FETCH_BY_EXTERNAL_ID,
  ECONOMY_ANALYSIS_FETCH_ECONOMY_GROUP,
} from "@actions/_types";
import { setInterceptors, clearInterceptors } from "@features/interceptors/app";
import { economyUrl } from "@constants/endpoints";

type TFetchEconomyByExternalIdPayload = {
  externalId: string;
};

type TFetchEconomyGroupDataPayload = {
  date?: string | null;
  group: string;
  frequency: string;
};

export const fetchEconomyByExternalId = createAsyncThunk(
  ECONOMY_ANALYSIS_FETCH_BY_EXTERNAL_ID,
  async (params: TFetchEconomyByExternalIdPayload) => {
    const { externalId } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${economyUrl}?externalId=${externalId}`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const fetchEconomyGroupData = createAsyncThunk(
  ECONOMY_ANALYSIS_FETCH_ECONOMY_GROUP,
  async (params: TFetchEconomyGroupDataPayload) => {
    const { date = null, group, frequency } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${economyUrl}/group?${
        date !== null ? "date=" + date : ""
      }&group=${group}&frequency=${frequency}`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);
