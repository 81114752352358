import React from "react";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
} from "react-router-dom";
import { ROUTES } from "@constants/routes";
import { history } from "@utilities/history";
import Content from "@general/Content/Content";
import LoginPage from "@pages/Login/Login";
import TfaPage from "@pages/Tfa/Tfa";
import TfaSetupPage from "@pages/TfaSetup/TfaSetup";
import HomePage from "@pages/Home/Home";
import NotFoundPage from "@pages/NotFound/NotFound";
import SignalsPage from "@pages/Signals/SignalsPage";
import AnalysisPage from "@pages/Analysis/AnalysisPage";
import ChartsPage from "@pages/Charts/ChartsPage";
import EconomyPage from "@pages/Economy/EconomyPage";
import MarketsPage from "@pages/Markets/MarketsPage";
import SettingsPage from "@pages/Settings/SettingsPage";
import AdminPage from "@pages/Admin/AdminPage";

type Props = {
  headerChild: React.ReactNode;
  footerChild: React.ReactNode;
};

const CustomRouter: React.FC<Props> = ({ headerChild, footerChild }) => {
  return (
    <HistoryRouter
      history={history}
      future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
    >
      {headerChild}
      <Routes>
        <Route
          path={ROUTES.WILDCARD}
          element={<Content pageChild={<NotFoundPage />} />}
        />
        <Route
          path={ROUTES.LOGIN}
          element={<Content pageChild={<LoginPage />} />}
        />
        <Route
          path={ROUTES.TFA}
          element={<Content pageChild={<TfaPage />} />}
        />
        <Route
          path={ROUTES.TFA_SETUP}
          element={<Content pageChild={<TfaSetupPage />} />}
        />
        <Route
          path={ROUTES.HOME}
          element={<Content pageChild={<HomePage />} />}
        />
        <Route
          path={ROUTES.SIGNALS}
          element={<Content pageChild={<SignalsPage />} />}
        />
        <Route
          path={ROUTES.ANALYSIS}
          element={<Content pageChild={<AnalysisPage />} />}
        />
        <Route
          path={ROUTES.CHARTS}
          element={<Content pageChild={<ChartsPage />} />}
        />
        <Route
          path={ROUTES.MARKETS}
          element={<Content pageChild={<MarketsPage />} />}
        />
        <Route
          path={ROUTES.ECONOMY}
          element={<Content pageChild={<EconomyPage />} />}
        />
        <Route
          path={ROUTES.SETTINGS}
          element={<Content pageChild={<SettingsPage />} />}
        />
        <Route
          path={ROUTES.ADMIN}
          element={<Content pageChild={<AdminPage />} />}
        />
      </Routes>
      {footerChild}
    </HistoryRouter>
  );
};

export default CustomRouter;
