import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { first } from "lodash";
import { Box, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "@store/configureStore";
import { TAppState } from "@store/reducers";
import BaseSelect from "@common/BaseSelect/BaseSelect";
import { DownloadButton } from "@common/BaseButton/Buttons";
import {
  liveJsonChanged,
  liveProviderChanged,
  livePairChanged,
  liveDatasetPairsChanged,
  liveDatasetProvidersChanged,
} from "@store/reducers/charts";
import { chartsSelector } from "@components/selectors";
import { isCandleJson } from "@utilities/helpers";
import {
  useGetLiveTechnicalProvidersQuery,
  useGetLivePairsQuery,
} from "@features/api";
import { fetchLiveCandles } from "@actions/charts";
import { filterPairsByProvider } from "@utilities/filters";

type Props = {};

const ChartsLiveFields: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { data: providerData, isLoading: providersLoading } =
    useGetLiveTechnicalProvidersQuery();
  const { data: pairData, isLoading: pairsLoading } = useGetLivePairsQuery();
  const {
    live: {
      json,
      dataset: { providers, pairs },
      filters: { period, provider, pair },
    },
  } = useSelector((state: TAppState) => chartsSelector(state));

  const providerSelected = (provider: any) => {
    dispatch(liveProviderChanged(provider));
    const pairsForProvider = filterPairsByProvider(pairData, provider);
    dispatch(liveDatasetPairsChanged(pairsForProvider));
    dispatch(livePairChanged(first(pairsForProvider)));
  };

  const pairSelected = (pair: any) => {
    dispatch(livePairChanged(pair));
  };

  const download = () => {
    dispatch(fetchLiveCandles({ period, provider, pair }));
  };

  const onJsonChange = (e) => {
    dispatch(liveJsonChanged(e.target.value));
  };

  useEffect(() => {
    if (providerData && !providersLoading) {
      dispatch(liveDatasetProvidersChanged(providerData));
      dispatch(liveProviderChanged(first(providerData)));
    }
  }, [dispatch, providerData, providersLoading]);

  useEffect(() => {
    if (pairData && !pairsLoading) {
      const filtered = filterPairsByProvider(pairData, provider);
      dispatch(liveDatasetPairsChanged(filtered));
      dispatch(livePairChanged(first(filtered)));
    }
  }, [dispatch, provider, pairData, pairsLoading]);

  return (
    <Stack sx={{ padding: "2%" }}>
      <Stack
        spacing={{ xs: 2, sm: 2 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        marginTop={2}
        marginBottom={2}
      >
        <Box>
          <BaseSelect
            data={providers}
            loading={providersLoading}
            selected={[provider?.CODE || ""]}
            onChange={providerSelected}
            selectKey="CODE"
            displayKey="NAME"
            inputLabel={t("PROVIDER_SELECT_INPUT_LABEL")}
            label={t("PROVIDER_SELECT_LABEL")}
            minWidth={200}
            maxWidth={200}
            optionInfo={(row) => <Box>{row.TYPE_DESCRIPTION}</Box>}
            optionInfoStyle={{
              fontSize: "70%",
              color: "rgba(212,255,0,0.8)",
              paddingBottom: "1%",
            }}
          />
        </Box>
        <Box>
          <BaseSelect
            data={pairs}
            loading={pairsLoading}
            onChange={pairSelected}
            selected={[pair?.ID || ""]}
            selectKey="ID"
            displayKey="SYMBOL"
            inputLabel={t("PAIR_SELECT_INPUT_LABEL")}
            label={t("PAIR_SELECT_LABEL")}
            minWidth={200}
            maxWidth={200}
            selectedDisplay={(row, key) =>
              row[key].replaceAll(row.SYMBOL_PREFIX, "")
            }
            optionsDisplay={(row, key) =>
              row[key].replaceAll(row.SYMBOL_PREFIX, "")
            }
            optionInfo={(row) => (
              <Box>
                <Box>
                  {row.TYPE_NAME} - {row.PROVIDER_NAME}
                </Box>
                {row.NAME && <Box>{row.NAME}</Box>}
              </Box>
            )}
            optionInfoStyle={{
              fontSize: "70%",
              color: "rgba(212,255,0,0.8)",
              paddingBottom: "1%",
            }}
            sortFn={(row: any) => [
              row.SYMBOL.replaceAll(row.SYMBOL_PREFIX, ""),
            ]}
            hasSearch
            searchKey="SYMBOL"
          />
        </Box>
        <Box>
          <DownloadButton onClick={() => download()} />
        </Box>
      </Stack>
      <Box>
        <TextField
          multiline
          onChange={onJsonChange}
          value={json}
          fullWidth
          rows={11}
          label={t("CHARTS_LIVE_JSON_LABEL")}
          error={!isCandleJson(json)}
        />
      </Box>
    </Stack>
  );
};

export default ChartsLiveFields;
