import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Grid from "@common/Grid/Grid";
import { TAppState } from "@store/reducers";
import { signalSelector } from "@components/selectors";
import getColumns, {
  gridKey,
  getVisibility,
  getSignalRowClass,
  getTogglableColumns,
} from "@pages/Signals/Grid/SignalsGridConfig";
import { filterSignalData } from "@common/helpers";

const SignalsGrid: React.FC = () => {
  const {
    list: {
      data,
      filters: { signal },
    },
  } = useSelector((state: TAppState) => signalSelector(state));
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Grid
        gridKey={gridKey}
        columns={[...getColumns(signal?.CODE)]}
        data={filterSignalData(signal?.CODE, data)}
        visibility={getVisibility()}
        getRowClassName={(params) => {
          const { row } = params;
          return getSignalRowClass(signal?.CODE, row);
        }}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          columnsManagement: {
            getTogglableColumns,
          },
        }}
      />
    </Box>
  );
};

export default SignalsGrid;
