import React from "react";
import { Box } from "@mui/material";
import TradingChart from "@common/TradingChart/TradingChart";
import { getIndicatorLines } from "@common/helpers";
import { setLineBarConfig } from "@pages/Charts/ChartsLines";

type Props = {
  pair?: any;
  candleData: Array<any>;
  indicators: Array<any>;
  lineBarConfig: any;
  renderEmpty?: boolean;
  height?: number | undefined;
  autoSize?: boolean;
  autoHeight?: boolean;
  autoWidth?: boolean;
  subscribeActions?: boolean;
};

const HistogramChart: React.FC<Props> = ({
  lineBarConfig,
  pair,
  candleData = [],
  indicators = [],
  height = 150,
  renderEmpty = true,
  autoSize = false,
  autoHeight = false,
  autoWidth = true,
  subscribeActions = false,
}) => {
  const histogramBarConfig = setLineBarConfig(lineBarConfig?.histogram);

  return (
    <Box sx={{ width: "100%" }}>
      {candleData.length === 0 && !renderEmpty ? (
        <></>
      ) : (
        <TradingChart
          autoSize={autoSize}
          autoHeight={autoHeight}
          autoWidth={autoWidth}
          height={height}
          fitContent={false}
          symbol={pair?.SYMBOL?.replaceAll(pair?.SYMBOL_PREFIX, "")}
          provider={pair?.PROVIDER_NAME}
          name={pair?.NAME}
          histogramSeries={getIndicatorLines(
            candleData,
            histogramBarConfig,
            indicators
          )}
          eventConfig={
            subscribeActions && pair
              ? {
                  crosshairMove: {
                    subscribeEvent: `CrosshairMove/Pair/${pair?.ID}`,
                    follow: true,
                  },
                  timeRangeChange: {
                    subscribeEvent: `TimeRangeChange/Pair/${pair?.ID}`,
                    follow: true,
                  },
                }
              : undefined
          }
        />
      )}
    </Box>
  );
};

export default HistogramChart;
