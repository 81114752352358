export const enTaskItem = {
  ADMIN_SCHEDULED_TASKS_CODE: "Execution code",
  ADMIN_SCHEDULED_TASKS_EXPRESSION: "Cron Expression",
  ADMIN_SCHEDULED_TASKS_DESCRIPTION: "Description",
  ADMIN_SCHEDULED_TASKS_STATUS: "Status",
  ADMIN_SCHEDULED_TASKS_STATUS_ACTIVE: "Active",
  ADMIN_SCHEDULED_TASKS_STATUS_INACTIVE: "Inactive",
  ADMIN_SCHEDULED_TASKS_LAST_STARTED: "Last started",
  ADMIN_SCHEDULED_TASKS_LAST_STARTED_NOT_YET: "Not started",
  ADMIN_SCHEDULED_TASKS_LAST_ENDED: "Last ended",
  ADMIN_SCHEDULED_TASKS_LAST_ENDED_NOT_YET: "Not ended",
  ADMIN_SCHEDULED_TASKS_LAST_FAILED: "Last failed",
  ADMIN_SCHEDULED_TASKS_LAST_FAILED_NOT_YET: "Not failed",
  ADMIN_SCHEDULED_TASKS_TIMEZONE: "Execution timezone",
  ADMIN_SCHEDULED_TASKS_NEXT_EXECUTIONS: "Next executions",
};

export const elTaskItem = {
  ADMIN_SCHEDULED_TASKS_CODE: "Κωδικός εκτέλεσης",
  ADMIN_SCHEDULED_TASKS_EXPRESSION: "Χρόνος εκτέλεσης",
  ADMIN_SCHEDULED_TASKS_DESCRIPTION: "Περιγραφή",
  ADMIN_SCHEDULED_TASKS_STATUS: "Κατάσταση",
  ADMIN_SCHEDULED_TASKS_STATUS_ACTIVE: "Ενεργό",
  ADMIN_SCHEDULED_TASKS_STATUS_INACTIVE: "Ανενεργό",
  ADMIN_SCHEDULED_TASKS_LAST_STARTED: "Τελευταία έναρξη εκτέλεσης",
  ADMIN_SCHEDULED_TASKS_LAST_STARTED_NOT_YET: "Δεν έχει ξεκινήσει",
  ADMIN_SCHEDULED_TASKS_LAST_ENDED: "Τελευταία λήξη εκτέλεσης",
  ADMIN_SCHEDULED_TASKS_LAST_ENDED_NOT_YET: "Δεν έχει ολοκληρωθεί",
  ADMIN_SCHEDULED_TASKS_LAST_FAILED: "Τελευταία αποτυχία",
  ADMIN_SCHEDULED_TASKS_LAST_FAILED_NOT_YET: "Δεν έχει αποτύχει",
  ADMIN_SCHEDULED_TASKS_TIMEZONE: "Ζώνη ώρας εκτέλεσης",
  ADMIN_SCHEDULED_TASKS_NEXT_EXECUTIONS: "Επόμενες εκτελέσεις",
};
