import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@store/configureStore";
import { TAppState } from "@store/reducers";
import { httpErrorChanged } from "@store/reducers/common";
import { SNACKBAR_DURATION } from "@constants/settings";
import BaseSnackbar from "@common/BaseSnackbar/BaseSnackbar";
import { commonSelector } from "@components/selectors";

const Notifications: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    http: { errorStatus, errorMessage },
  } = useSelector((state: TAppState) => commonSelector(state));

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(httpErrorChanged({}));
    }, SNACKBAR_DURATION);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <BaseSnackbar
      isOpen={errorStatus}
      isAlert
      alertSeverity="error"
      message={errorMessage}
      horizontal="left"
      autoHideDuration={SNACKBAR_DURATION}
      snackBarSx={{
        bottom: "120px !important",
      }}
    />
  );
};

export default Notifications;
