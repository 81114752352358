import React from "react";
import { Box, Typography } from "@mui/material";
import { TTextAlign } from "@app/types/entities";

type Props = {
  width?: string | number;
  background: string;
  text: string;
  textAlign?: TTextAlign | undefined;
  fontSize?: string | number;
  shape?: "circle" | "rectangle";
  shapeWidth?: string | number;
  shapeHeight?: string | number;
  paddingBottom?: string | number;
};

const ColorLegend: React.FC<Props> = ({
  width = "100px",
  background,
  text,
  textAlign = "center",
  fontSize = "85%",
  shape = "rectangle",
  shapeWidth = "50px",
  shapeHeight = "50px",
  paddingBottom = "3%",
}) => {
  return (
    <Box sx={{ width }}>
      <Box sx={{ display: "flex", justifyContent: "center", paddingBottom }}>
        <Box
          sx={{
            background,
            width: shapeWidth,
            height: shapeHeight,
            borderRadius: shape === "circle" ? "50%" : 0,
          }}
        ></Box>
      </Box>
      <Box sx={{ textAlign, fontSize }}>
        <Typography>{text}</Typography>
      </Box>
    </Box>
  );
};

export default ColorLegend;
