import { createSlice } from "@reduxjs/toolkit";
import { AXIOS_STATUSES, TUserProfile } from "@app/types/entities";
import { fetchSession, login, logout } from "@actions/user";
import {
  getSettingsInStorage,
  getUserInStorage,
  removeUserInStorage,
  setUserInStorage,
} from "@utilities/helpers";

export interface TUserState {
  auth: {
    loggedIn: boolean;
  };
  profile: TUserProfile;
  status: string | null;
  error: string | null;
}

const initialState: TUserState = {
  auth: {
    loggedIn: getSettingsInStorage()?.forceTwoFactor
      ? getUserInStorage()?.twoFactor
      : getUserInStorage()?.userId
      ? true
      : false,
  },
  profile: {
    userId: null,
    name: null,
    email: null,
    lastLogin: null,
    role: null,
    twoFactor: null,
  },
  status: null,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeLoggedIn: (state, action) => {
      state.auth.loggedIn = action.payload;
    },
    changeProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSession.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
        state.error = null;
      })
      .addCase(fetchSession.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        const { userId = null } = action.payload;
        if (userId) {
          setUserInStorage(action.payload);
          state.auth.loggedIn = getSettingsInStorage()?.forceTwoFactor
            ? action.payload.twoFactor
            : true;
          state.profile = action.payload;
        }
      })
      .addCase(fetchSession.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
        state.error = action.error.message || "Fetch session failed";
      })
      .addCase(login.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "idle";
        setUserInStorage(action.payload);
        state.profile = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Logout failed";
      })
      .addCase(logout.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.status = "idle";
        removeUserInStorage();
        state.auth.loggedIn = false;
        state.profile = initialState.profile;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Logout failed";
      });
  },
});

export const { changeLoggedIn, changeProfile } = userSlice.actions;
export default userSlice.reducer;
