import React from "react";
import { includes } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { TAppState } from "@store/reducers";
import { AppDispatch } from "@store/configureStore";
import { chartActiveMetricChanged } from "@store/reducers/analysis";
import { analysisSelector } from "@components/selectors";
import { getLegendsConfig } from "@pages/Analysis/Chart/AnalysisChartConfig";

type Props = {};

const AnalysisChartLegends: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    chart: {
      activeMetrics,
      filters: { analysis },
    },
  } = useSelector((state: TAppState) => analysisSelector(state));

  const legendConfig = getLegendsConfig(analysis?.CODE);

  const onLegendChange = (event: any, checked: any) => {
    const {
      target: { value },
    } = event;
    dispatch(chartActiveMetricChanged({ value, checked }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
        fontSize: "80%",
        margin: "2%",
        height: "140px",
        overflowX: "auto",
        overflowY: "hidden",
        width: "98%",
      }}
    >
      {legendConfig.map((legend, k1) => (
        <Box
          sx={{
            background: "rgba(114, 108, 111, 0.24)",
            padding: "20px",
            borderRadius: "15%",
            height: "110px",
            minWidth: "180px",
            maxWidth: "180px",
            flexBasis: "60%",
            flexShrink: 1,
          }}
          key={k1}
        >
          <FormControlLabel
            value="bottom"
            control={
              <Switch
                checked={includes(activeMetrics, legend.key)}
                value={legend.key}
                size="small"
                onChange={onLegendChange}
                color="primary"
              />
            }
            label={
              <Typography sx={{ fontSize: "80%", whiteSpace: "nowrap" }}>
                {legend.label}
              </Typography>
            }
            labelPlacement="start"
            sx={{
              marginLeft: 0,
            }}
          />
          {legend.body.map((part, k2) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              key={k2}
            >
              <Box
                sx={{
                  background: `${part.color}`,
                  height: 16,
                  width: 16,
                  borderRadius: "50%",
                  marginRight: 1,
                }}
              />
              <Typography>{part.text}</Typography>
            </Box>
          ))}
          <Box>
            <Typography>{legend.extraText}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AnalysisChartLegends;
