import {
  enFearGreedChart,
  elFearGreedChart,
} from "@pages/Markets/FearGreed/Chart/translations";

export const enFearGreedTab = {
  ...enFearGreedChart,
};

export const elFearGreedTab = {
  ...elFearGreedChart,
};
