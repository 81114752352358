import React from "react";
import { Box } from "@mui/material";
import SignalsAnalysisFilters from "@pages/Signals/Analysis/SignalsAnalysisFilters";
import SignalsAnalysisContent from "@pages/Signals/Analysis/SignalsAnalysisContent";

const SignalsAnalysisTab: React.FC = () => {
  return (
    <Box sx={{ height: "100%", letterSpacing: 1 }}>
      <SignalsAnalysisFilters />
      <SignalsAnalysisContent />
    </Box>
  );
};

export default SignalsAnalysisTab;
