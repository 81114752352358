import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { TAppState } from "@store/reducers";
import { AppDispatch } from "@store/configureStore";
import { updateSettings } from "@actions/user";
import { settingsSelector } from "@components/selectors";
import BaseSwitch from "@common/BaseSwitch/BaseSwitch";

const MarketSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    notifications: { emailPairs, emailSignals },
  } = useSelector((state: TAppState) => settingsSelector(state));

  const toggleEmail = async (enable = false, field: string) => {
    try {
      await dispatch(updateSettings({ [field]: enable })).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t("SETTINGS_NOTIFICATIONS_MARKET_HEADER")}
      </Typography>
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("SETTINGS_NOTIFICATIONS_EMAIL_HEADER")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Box sx={{ flex: 3, mr: 2 }}>
            <Typography>
              {t("SETTINGS_NOTIFICATIONS_EMAIL_MARKET_PAIRS_MESSAGE")}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
            <BaseSwitch
              checked={emailPairs}
              onChange={(active: any) => toggleEmail(active, "emailPairs")}
              label={
                (emailPairs
                  ? t("SETTINGS_NOTIFICATIONS_STATUS_ON")
                  : t("SETTINGS_NOTIFICATIONS_STATUS_OFF")) || ""
              }
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Box sx={{ flex: 3, mr: 2 }}>
            <Typography>
              {t("SETTINGS_NOTIFICATIONS_EMAIL_MARKET_SIGNALS_MESSAGE")}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
            <BaseSwitch
              checked={emailSignals}
              onChange={(active: any) => toggleEmail(active, "emailSignals")}
              label={
                (emailSignals
                  ? t("SETTINGS_NOTIFICATIONS_STATUS_ON")
                  : t("SETTINGS_NOTIFICATIONS_STATUS_OFF")) || ""
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MarketSettings;
