import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  LOGS_APP_FETCH,
  LOGS_APP_CLEAR,
  LOGS_APP_DELETE,
} from "@actions/_types";
import { setInterceptors, clearInterceptors } from "@features/interceptors/app";
import { appLogsUrl } from "@constants/endpoints";

type TFetchAppLogsPayload = {
  limit?: number;
  offset?: number;
  sort?: string;
};

type TDeleteAppLogsPayload = {
  id: number;
};

export const fetchAppLogs = createAsyncThunk(
  LOGS_APP_FETCH,
  async (params: TFetchAppLogsPayload) => {
    const { limit = 0, offset = 0, sort = "desc" } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    let url = `${appLogsUrl}?limit=${limit}&offset=${offset}&sort=${sort}`;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const clearAppLogs = createAsyncThunk(LOGS_APP_CLEAR, async () => {
  const intercepted = setInterceptors(axios.create({ withCredentials: true }));
  let url = `${appLogsUrl}/clear`;
  const response = await intercepted.instance.get(url);
  clearInterceptors(intercepted);
  return response.data;
});

export const deleteAppLog = createAsyncThunk(
  LOGS_APP_DELETE,
  async (params: TDeleteAppLogsPayload) => {
    const { id } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    let url = `${appLogsUrl}/remove?id=${id}`;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);
