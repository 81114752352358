/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { DateTime } from "luxon";
import { Box, Stack } from "@mui/material";
import BaseSelect from "@common/BaseSelect/BaseSelect";
import BaseDatePicker from "@common/BaseDatePicker/BaseDatePicker";
import { AppDispatch } from "@store/configureStore";
import { TAppState } from "@store/reducers";
import {
  listPeriodChanged,
  listSignalChanged,
  listOpenedAtChanged,
  listDatasetSignalsChanged,
  listDatasetPeriodsChanged,
} from "@store/reducers/signal";
import { signalSelector } from "@components/selectors";
import {
  useGetActivePeriodsQuery,
  useGetActiveSignalsQuery,
} from "@features/api";
import { getMaxDate, disableDates } from "@utilities/helpers";
import { filterSignals } from "@utilities/filters";
import { fetchSignal } from "@actions/signal";
import { fetchLastSynced } from "@actions/period";

const SignalsGridFilters: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { data: periodData, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: signalData, isLoading: signalsLoading } =
    useGetActiveSignalsQuery();
  const {
    list: {
      dataset: { periods, signals },
      filters: { period, openedAt, signal },
    },
  } = useSelector((state: TAppState) => signalSelector(state));

  const periodSelected = (period: any) => {
    dispatch(listPeriodChanged(period));
    const filtered = filterSignals(signalData, period);
    dispatch(listDatasetSignalsChanged(filtered));
    dispatch(listSignalChanged(filtered ? first(filtered) : null));
  };

  const signalSelected = (signal: any) => {
    const signalId = signal.ID;
    const periodId = period.ID;
    dispatch(listSignalChanged(signal));
    dispatch(
      fetchSignal({
        signalId,
        periodId,
        openedAt: openedAt.toFormat("yyyy-MM-dd HH:mm:ss"),
      })
    );
  };

  const dateSelected = (openedAt: any) => {
    dispatch(listOpenedAtChanged(openedAt));
  };

  useEffect(() => {
    if (periodData && !periodsLoading) {
      dispatch(listDatasetPeriodsChanged(periodData));
      dispatch(listPeriodChanged(first(periodData)));
    }
  }, [dispatch, periodData, periodsLoading]);

  useEffect(() => {
    if (period && signalData && !signalsLoading) {
      const filtered = filterSignals(signalData, period);
      dispatch(listDatasetSignalsChanged(filtered));
      dispatch(listSignalChanged(first(filtered)));
    }
  }, [dispatch, period, signalData, signalsLoading]);

  useEffect(() => {
    const periodId = period?.ID;
    if (periodId) {
      const setOpenedAt = async () => {
        const {
          payload: { openedAt },
        } = await dispatch(fetchLastSynced({ periodId }));
        dispatch(
          listOpenedAtChanged(
            DateTime.fromFormat(openedAt, "yyyy-MM-dd HH:mm:ss", {
              zone: "utc",
            })
          )
        );
      };
      setOpenedAt();
    }
  }, [dispatch, period]);

  useEffect(() => {
    if (period && signal && openedAt) {
      const signalId = signal?.ID;
      const periodId = period?.ID;
      dispatch(
        fetchSignal({
          signalId,
          periodId,
          openedAt: openedAt.toFormat("yyyy-MM-dd HH:mm:ss"),
        })
      );
    }
  }, [dispatch, openedAt]);

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginBottom={5}
    >
      <Box>
        <BaseSelect
          data={periods}
          loading={periodsLoading}
          onChange={periodSelected}
          selected={[period?.CODE || ""]}
          selectedDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          optionsDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
        />
      </Box>
      <Box>
        <BaseSelect
          data={signals}
          loading={signalsLoading}
          onChange={signalSelected}
          selected={[signal?.CODE || ""]}
          selectedDisplay={(row: any) =>
            `${t(`SIGNAL_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
          }
          optionsDisplay={(row: any) =>
            `${t(`SIGNAL_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
          }
          selectKey="CODE"
          inputLabel={t("SIGNAL_SELECT_INPUT_LABEL")}
          label={t("SIGNAL_SELECT_LABEL")}
          minWidth={250}
          maxWidth={250}
        />
      </Box>
      <Box>
        <BaseDatePicker
          label={t("OPENED_AT_DATE_PICKER_LABEL")}
          defaultValue={openedAt}
          maxDate={getMaxDate(period)}
          onChange={dateSelected}
          timeSteps={{ hours: 24, minutes: 60 }}
          shouldDisableDate={disableDates(period)}
        />
      </Box>
    </Stack>
  );
};

export default SignalsGridFilters;
