import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Box, Typography } from "@mui/material";

const DateDisplay: React.FC = () => {
  const [localDate, setLocalDate] = useState("");
  const [utcDate, setUtcDate] = useState("");
  const [newYorkDate, setNewYorkDate] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      /*
       * `DDDD TT` full date format
       */
      setLocalDate(DateTime.local().toFormat("dd/MM/yyyy HH:mm:ss"));
      setUtcDate(DateTime.utc().toFormat("dd/MM/yyyy HH:mm:ss"));
      setNewYorkDate(
        DateTime.now()
          .setZone("America/New_York")
          .toFormat("dd/MM/yyyy HH:mm:ss")
      );
    }, 200);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 300,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ color: "white", width: 70, marginRight: "10px" }}>
          {t("LOCAL_DATETIME_LABEL")}{" "}
        </Typography>
        <Typography sx={{ flex: 3, color: "orange" }}>{localDate}</Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ color: "white", width: 70, marginRight: "10px" }}>
          {t("UTC_DATETIME_LABEL")}{" "}
        </Typography>
        <Typography component="span" sx={{ flex: 3, color: "#CF9FFF" }}>
          {utcDate}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ color: "white", width: 70, marginRight: "10px" }}>
          {t("NEW_YORK_DATETIME_LABEL")}{" "}
        </Typography>
        <Typography sx={{ flex: 3, color: "#32CD32" }}>
          {newYorkDate}
        </Typography>
      </Box>
    </Box>
  );
};

export default DateDisplay;
