import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { pretty } from "@utilities/helpers";
import { ContentCopyButton } from "@common/BaseButton/Buttons";

type Props = {
  title: string;
  description: string;
  prettyPrint?: boolean;
  enableCopy?: boolean;
};

const FaqAccordion: React.FC<Props> = ({
  title,
  description,
  prettyPrint = false,
}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {prettyPrint ? (
          <Typography component="pre">{pretty(description)}</Typography>
        ) : (
          <Typography>{description}</Typography>
        )}
        {prettyPrint && (
          <ContentCopyButton
            sx={{ float: "right" }}
            onClick={() =>
              navigator.clipboard.writeText(
                prettyPrint ? pretty(description) : description
              )
            }
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqAccordion;
