import { enLoginPage, elLoginPage } from "@pages/Login/translations";
import { enTfaPage, elTfaPage } from "@pages/Tfa/translations";
import { enTfaSetupPage, elTfaSetupPage } from "@pages/TfaSetup/translations";
import { enNotFoundPage, elNotFoundPage } from "@pages/NotFound/translations";
import { enSignalsPage, elSignalsPage } from "@pages/Signals/translations";
import { enAnalysisPage, elAnalysisPage } from "@pages/Analysis/translations";
import { enChartsPage, elChartsPage } from "@pages/Charts/translations";
import { enEconomyPage, elEconomyPage } from "@pages/Economy/translations";
import { enMarketsPage, elMarketsPage } from "@pages/Markets/translations";
import { enSettingsPage, elSettingsPage } from "@pages/Settings/translations";
import { enAdminPage, elAdminPage } from "@pages/Admin/translations";

export const enPages = {
  PERIOD_SELECT_INPUT_LABEL: "Period",
  PERIOD_SELECT_LABEL: "Period",
  PERIOD_SELECT_DISPLAY_LABEL_1D: "Daily",
  PERIOD_SELECT_DISPLAY_LABEL_1W: "Weekly",
  ANALYSIS_SELECT_DISPLAY_LABEL_MOVEMENT: "Movement",
  ANALYSIS_SELECT_DISPLAY_LABEL_VOLATILITY: "Volatility",
  SIGNAL_SELECT_DISPLAY_LABEL_CROSS: "Cross Signal",
  SIGNAL_SELECT_DISPLAY_LABEL_MACD4CDIV: "Macd Divergence Signal",
  SIGNAL_SELECT_DISPLAY_LABEL_ESCAN: "Enhanced Scan Signal",
  SIGNAL_SELECT_DISPLAY_LABEL_USCAN: "Ultra Scan Signal",
  PATTERN_SELECT_DISPLAY_LABEL_FRACTAL_TREND: "Fractal Trend",
  PATTERN_SELECT_DISPLAY_LABEL_FRACTAL_PRICE_TREND: "Fractal Price Trend",
  PAIR_SELECT_INPUT_LABEL: "Pair",
  PAIR_SELECT_LABEL: "Pair",
  CRYPTO_ASSET_SELECT_INPUT_LABEL: "Asset",
  CRYPTO_ASSET_SELECT_LABEL: "Asset",
  ASSET_TYPE_SELECT_INPUT_LABEL: "Asset Type",
  ASSET_TYPE_SELECT_LABEL: "Asset Type",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_CRYPTO: "Cryptocurrency",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_STOCK: "Stock",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_ETF: "ETF",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_INDEX: "Index",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_FUTURE: "Future",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_SECURITY: "Security",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_EQUITY: "Equity",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_CURRENCY: "Currency",
  PROVIDER_SELECT_INPUT_LABEL: "Provider",
  PROVIDER_SELECT_LABEL: "Provider",
  INDICATOR_SELECT_INPUT_LABEL: "Indicator",
  INDICATOR_SELECT_LABEL: "Indicator",
  PATTERN_SELECT_INPUT_LABEL: "Pattern",
  PATTERN_SELECT_LABEL: "Pattern",
  SIGNAL_SELECT_INPUT_LABEL: "Signal",
  SIGNAL_SELECT_LABEL: "Signal",
  ANALYSIS_SELECT_INPUT_LABEL: "Analysis",
  ANALYSIS_SELECT_LABEL: "Analysis",
  OPENED_AT_DATE_PICKER_LABEL: "Opened At",
  CANDLE_OPENED_AT: "Opened At",
  CANDLE_CLOSED_AT: "Closed At",
  CANDLE_OPEN: "Open",
  CANDLE_CLOSE: "Close",
  CANDLE_HIGH: "High",
  CANDLE_LOW: "Low",
  ALL_TIME_HIGH_LABEL: "All Time High",
  ALL_TIME_LOW_LABEL: "All Time Low",
  INDICATOR_DISPLAY_LABEL_RSI: "Relative Strength Index",
  INDICATOR_DISPLAY_LABEL_BOLL: "Bollinger Bands",
  INDICATOR_DISPLAY_LABEL_STOCH: "Stochastic",
  INDICATOR_DISPLAY_LABEL_LOWESTLOW: "Lowest Low",
  INDICATOR_DISPLAY_LABEL_HIGHESTHIGH: "Highest High",
  INDICATOR_DISPLAY_LABEL_SMA: "Simple Moving Average",
  INDICATOR_DISPLAY_LABEL_ADX: "Average Directional Index",
  INDICATOR_DISPLAY_LABEL_CCI: "Commodity Channel Index",
  INDICATOR_DISPLAY_LABEL_KELTNER: "Keltner Channel Index",
  INDICATOR_DISPLAY_LABEL_DONCHIAN: "Donchian Channel Index",
  INDICATOR_DISPLAY_LABEL_AROON: "Aroon Channel Index",
  INDICATOR_DISPLAY_LABEL_VIX: "Volatility Index",
  INDICATOR_DISPLAY_LABEL_MACD4C: "Moving Average Convergence/Divergence 4C",
  INDICATOR_DISPLAY_LABEL_FRACTAL: "William Fractals",
  INDICATOR_DISPLAY_LABEL_MFI: "Money Flow Index",
  VOLUME_DISPLAY_LABEL: "Volume",
  INDICATOR_LABEL_BOLL_1: "Bollinger (20, 2)",
  INDICATOR_LABEL_KELTNER_1: "Keltner (20, 2)",
  INDICATOR_LABEL_DONCHIAN_1: "Donchian (20)",
  INDICATOR_LABEL_LOWESTLOW_1: "Lowest Low (5)",
  INDICATOR_LABEL_LOWESTLOW_2: "Lowest Low (7)",
  INDICATOR_LABEL_HIGHESTHIGH_1: "Highest High (5)",
  INDICATOR_LABEL_HIGHESTHIGH_2: "Highest High (7)",
  INDICATOR_LABEL_SMA_1: "Sma (50)",
  INDICATOR_LABEL_SMA_2: "Sma (200)",
  INDICATOR_LABEL_AROON_1: "Aroon (14)",
  INDICATOR_LABEL_AROON_2: "Aroon (25)",
  INDICATOR_LABEL_ADX_1: "Adx (14)",
  INDICATOR_LABEL_ADX_2: "Adx (20)",
  INDICATOR_LABEL_RSI_1: "Rsi (7)",
  INDICATOR_LABEL_RSI_2: "Rsi (14)",
  INDICATOR_LABEL_RSI_3: "Rsi (21)",
  INDICATOR_LABEL_STOCH_1: "Stoch (5, 3, 3)",
  INDICATOR_LABEL_STOCH_2: "Stoch (14, 1, 3)",
  INDICATOR_LABEL_STOCH_3: "Stoch (21, 3, 3)",
  INDICATOR_LABEL_VIX_1: "Vix (22)",
  INDICATOR_LABEL_CCI_1: "Cci (14)",
  INDICATOR_LABEL_CCI_2: "Cci (20)",
  INDICATOR_LABEL_CCI_3: "Cci (21)",
  INDICATOR_LABEL_MFI_1: "Mfi (7)",
  INDICATOR_LABEL_MFI_2: "Mfi (14)",
  INDICATOR_LABEL_MACD4C_1: "Macd4c (20, 50, 9)",
  INDICATOR_LABEL_FRACTAL_1: "Fractal",
  VOLUME_LABEL_1: "Volume",
  INDICATOR_OUTPUT_BOLL_UPPER_20_2: "Upper",
  INDICATOR_OUTPUT_BOLL_MIDDLE_20_2: "Middle",
  INDICATOR_OUTPUT_BOLL_LOWER_20_2: "Lower",
  INDICATOR_OUTPUT_LOWEST_LOW_5: "Lowest low",
  INDICATOR_OUTPUT_LOWEST_LOW_7: "Lowest low",
  INDICATOR_OUTPUT_HIGHEST_HIGH_5: "Highest high",
  INDICATOR_OUTPUT_HIGHEST_HIGH_7: "Highest high",
  INDICATOR_OUTPUT_SMA_50: "Sma",
  INDICATOR_OUTPUT_SMA_200: "Sma",
  INDICATOR_OUTPUT_KELTNER_UPPER_20: "Upper",
  INDICATOR_OUTPUT_KELTNER_MIDDLE_20: "Middle",
  INDICATOR_OUTPUT_KELTNER_LOWER_20: "Lower",
  INDICATOR_OUTPUT_DONCHIAN_UPPER_20: "Upper",
  INDICATOR_OUTPUT_DONCHIAN_BASE_20: "Base",
  INDICATOR_OUTPUT_DONCHIAN_LOWER_20: "Lower",
  INDICATOR_OUTPUT_RSI_7: "Rsi",
  INDICATOR_OUTPUT_RSI_14: "Rsi",
  INDICATOR_OUTPUT_RSI_21: "Rsi",
  INDICATOR_OUTPUT_STOCH_K_5_3_3: "K",
  INDICATOR_OUTPUT_STOCH_D_5_3_3: "D",
  INDICATOR_OUTPUT_STOCH_K_14_1_3: "K",
  INDICATOR_OUTPUT_STOCH_D_14_1_3: "D",
  INDICATOR_OUTPUT_STOCH_K_21_3_3: "K",
  INDICATOR_OUTPUT_STOCH_D_21_3_3: "D",
  INDICATOR_OUTPUT_ADX_14: "Adx",
  INDICATOR_OUTPUT_MDI_14: "Mdi",
  INDICATOR_OUTPUT_PDI_14: "Pdi",
  INDICATOR_OUTPUT_ADX_20: "Adx",
  INDICATOR_OUTPUT_MDI_20: "Mdi",
  INDICATOR_OUTPUT_PDI_20: "Pdi",
  INDICATOR_OUTPUT_AROON_UPPER_14: "Upper",
  INDICATOR_OUTPUT_AROON_LOWER_14: "Lower",
  INDICATOR_OUTPUT_AROON_UPPER_25: "Upper",
  INDICATOR_OUTPUT_AROON_LOWER_25: "Lower",
  INDICATOR_OUTPUT_VIX_22: "Vix",
  INDICATOR_OUTPUT_MFI_7: "Mfi",
  INDICATOR_OUTPUT_MFI_14: "Mfi",
  INDICATOR_OUTPUT_CCI_14: "Cci",
  INDICATOR_OUTPUT_CCI_20: "Cci",
  INDICATOR_OUTPUT_CCI_21: "Cci",
  INDICATOR_OUTPUT_MACD4C_20_50_9: "Macd",
  INDICATOR_OUTPUT_SIGNAL4C_20_50_9: "Signal",
  INDICATOR_OUTPUT_HISTOGRAM4C_20_50_9: "Histogram",
  LOG_LEVEL_INFO_LABEL: "Info",
  LOG_LEVEL_WARNING_LABEL: "Warning",
  LOG_LEVEL_ERROR_LABEL: "Error",
  LOG_LEVEL_UNKNOWN_LABEL: "Unknown",
  LOG_TYPE_SYNC_LABEL: "Synchronization",
  LOG_TYPE_CALCULATION_LABEL: "Calculation",
  LOG_TYPE_REPORT_LABEL: "Report execution",
  LOG_TYPE_MAIL_LABEL: "Email process",
  LOG_TYPE_USER_LABEL: "User action",
  LOG_TYPE_SECURITY_LABEL: "Security",
  LOG_TYPE_GENERIC_LABEL: "Generic",
  LOG_TYPE_UNKNOWN_LABEL: "Unknown",
  ...enLoginPage,
  ...enTfaPage,
  ...enTfaSetupPage,
  ...enNotFoundPage,
  ...enSignalsPage,
  ...enAnalysisPage,
  ...enChartsPage,
  ...enEconomyPage,
  ...enMarketsPage,
  ...enSettingsPage,
  ...enAdminPage,
};

export const elPages = {
  PERIOD_SELECT_INPUT_LABEL: "Περίοδος",
  PERIOD_SELECT_LABEL: "Περίοδος",
  PERIOD_SELECT_DISPLAY_LABEL_1D: "Ημερήσια",
  PERIOD_SELECT_DISPLAY_LABEL_1W: "Εβδομαδιαία",
  ANALYSIS_SELECT_DISPLAY_LABEL_MOVEMENT: "Movement",
  ANALYSIS_SELECT_DISPLAY_LABEL_VOLATILITY: "Volatility",
  SIGNAL_SELECT_DISPLAY_LABEL_CROSS: "Σήμα Cross",
  SIGNAL_SELECT_DISPLAY_LABEL_MACD4CDIV: "Σήμα Macd Divergence",
  SIGNAL_SELECT_DISPLAY_LABEL_ESCAN: "Σήμα Enhanced Scan",
  SIGNAL_SELECT_DISPLAY_LABEL_USCAN: "Σήμα Ultra Scan",
  PATTERN_SELECT_DISPLAY_LABEL_FRACTAL_TREND: "Fractal Trend",
  PATTERN_SELECT_DISPLAY_LABEL_FRACTAL_PRICE_TREND: "Fractal Price Trend",
  PAIR_SELECT_INPUT_LABEL: "Ζεύγος",
  PAIR_SELECT_LABEL: "Ζεύγος",
  CRYPTO_ASSET_SELECT_INPUT_LABEL: "Κρυπτονόμισμα",
  CRYPTO_ASSET_SELECT_LABEL: "Κρυπτονόμισμα",
  ASSET_TYPE_SELECT_INPUT_LABEL: "Τύπος",
  ASSET_TYPE_SELECT_LABEL: "Τύπος",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_CRYPTO: "Κρυπτονόμισμα",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_STOCK: "Μετοχή",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_ETF: "ETF",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_INDEX: "Δείκτης",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_FUTURE: "Συμβόλαιο Μελλοντικής Εκπλήρωσης",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_SECURITY: "Χρεόγραφο",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_EQUITY: "Αξιόγραφο",
  ASSET_TYPE_SELECT_DISPLAY_LABEL_CURRENCY: "Νόμισμα",
  PROVIDER_SELECT_INPUT_LABEL: "Πάροχος",
  PROVIDER_SELECT_LABEL: "Πάροχος",
  INDICATOR_SELECT_INPUT_LABEL: "Δείκτης",
  INDICATOR_SELECT_LABEL: "Δείκτης",
  PATTERN_SELECT_INPUT_LABEL: "Μοτίβο",
  PATTERN_SELECT_LABEL: "Μοτίβο",
  SIGNAL_SELECT_INPUT_LABEL: "Σήμα",
  SIGNAL_SELECT_LABEL: "Σήμα",
  ANALYSIS_SELECT_INPUT_LABEL: "Ανάλυση",
  ANALYSIS_SELECT_LABEL: "Ανάλυση",
  OPENED_AT_DATE_PICKER_LABEL: "Άνοιξε",
  CANDLE_OPENED_AT: "Άνοιξε",
  CANDLE_CLOSED_AT: "Έκλεισε",
  CANDLE_OPEN: "Άνοιγμα",
  CANDLE_CLOSE: "Κλείσιμο",
  CANDLE_HIGH: "Υψηλό",
  CANDLE_LOW: "Χαμηλό",
  ALL_TIME_HIGH_LABEL: "Ιστορικό Υψηλό",
  ALL_TIME_LOW_LABEL: "Ιστορικό Χαμηλό",
  INDICATOR_DISPLAY_LABEL_RSI: "Relative Strength Index",
  INDICATOR_DISPLAY_LABEL_BOLL: "Bollinger Bands",
  INDICATOR_DISPLAY_LABEL_STOCH: "Stochastic",
  INDICATOR_DISPLAY_LABEL_LOWESTLOW: "Lowest Low",
  INDICATOR_DISPLAY_LABEL_HIGHESTHIGH: "Highest High",
  INDICATOR_DISPLAY_LABEL_SMA: "Simple Moving Average",
  INDICATOR_DISPLAY_LABEL_ADX: "Average Directional Index",
  INDICATOR_DISPLAY_LABEL_CCI: "Commodity Channel Index",
  INDICATOR_DISPLAY_LABEL_KELTNER: "Keltner Channel Index",
  INDICATOR_DISPLAY_LABEL_DONCHIAN: "Donchian Channel Index",
  INDICATOR_DISPLAY_LABEL_AROON: "Aroon Channel Index",
  INDICATOR_DISPLAY_LABEL_VIX: "Volatility Index",
  INDICATOR_DISPLAY_LABEL_MACD4C: "Moving Average Convergence/Divergence 4C",
  INDICATOR_DISPLAY_LABEL_FRACTAL: "William Fractals",
  INDICATOR_DISPLAY_LABEL_MFI: "Money Flow Index",
  VOLUME_DISPLAY_LABEL: "Όγκος",
  INDICATOR_LABEL_BOLL_1: "Bollinger (20, 2)",
  INDICATOR_LABEL_KELTNER_1: "Keltner (20, 2)",
  INDICATOR_LABEL_DONCHIAN_1: "Donchian (20)",
  INDICATOR_LABEL_LOWESTLOW_1: "Lowest Low (5)",
  INDICATOR_LABEL_LOWESTLOW_2: "Lowest Low (7)",
  INDICATOR_LABEL_HIGHESTHIGH_1: "Highest High (5)",
  INDICATOR_LABEL_HIGHESTHIGH_2: "Highest High (7)",
  INDICATOR_LABEL_SMA_1: "Sma (50)",
  INDICATOR_LABEL_SMA_2: "Sma (200)",
  INDICATOR_LABEL_AROON_1: "Aroon (14)",
  INDICATOR_LABEL_AROON_2: "Aroon (25)",
  INDICATOR_LABEL_ADX_1: "Adx (14)",
  INDICATOR_LABEL_ADX_2: "Adx (20)",
  INDICATOR_LABEL_RSI_1: "Rsi (7)",
  INDICATOR_LABEL_RSI_2: "Rsi (14)",
  INDICATOR_LABEL_RSI_3: "Rsi (21)",
  INDICATOR_LABEL_STOCH_1: "Stoch (5, 3, 3)",
  INDICATOR_LABEL_STOCH_2: "Stoch (14, 1, 3)",
  INDICATOR_LABEL_STOCH_3: "Stoch (21, 3, 3)",
  INDICATOR_LABEL_VIX_1: "Vix (22)",
  INDICATOR_LABEL_CCI_1: "Cci (14)",
  INDICATOR_LABEL_CCI_2: "Cci (20)",
  INDICATOR_LABEL_CCI_3: "Cci (21)",
  INDICATOR_LABEL_MFI_1: "Mfi (7)",
  INDICATOR_LABEL_MFI_2: "Mfi (14)",
  INDICATOR_LABEL_MACD4C_1: "Macd4c (20, 50, 9)",
  INDICATOR_LABEL_FRACTAL_1: "Fractal",
  VOLUME_LABEL_1: "Όγκος",
  INDICATOR_OUTPUT_BOLL_UPPER_20_2: "Upper",
  INDICATOR_OUTPUT_BOLL_MIDDLE_20_2: "Middle",
  INDICATOR_OUTPUT_BOLL_LOWER_20_2: "Lower",
  INDICATOR_OUTPUT_LOWEST_LOW_5: "Lowest low",
  INDICATOR_OUTPUT_LOWEST_LOW_7: "Lowest low",
  INDICATOR_OUTPUT_HIGHEST_HIGH_5: "Highest high",
  INDICATOR_OUTPUT_HIGHEST_HIGH_7: "Highest high",
  INDICATOR_OUTPUT_SMA_50: "Sma",
  INDICATOR_OUTPUT_SMA_200: "Sma",
  INDICATOR_OUTPUT_KELTNER_UPPER_20: "Upper",
  INDICATOR_OUTPUT_KELTNER_MIDDLE_20: "Middle",
  INDICATOR_OUTPUT_KELTNER_LOWER_20: "Lower",
  INDICATOR_OUTPUT_DONCHIAN_UPPER_20: "Upper",
  INDICATOR_OUTPUT_DONCHIAN_BASE_20: "Base",
  INDICATOR_OUTPUT_DONCHIAN_LOWER_20: "Lower",
  INDICATOR_OUTPUT_RSI_7: "Rsi",
  INDICATOR_OUTPUT_RSI_14: "Rsi",
  INDICATOR_OUTPUT_RSI_21: "Rsi",
  INDICATOR_OUTPUT_STOCH_K_5_3_3: "K",
  INDICATOR_OUTPUT_STOCH_D_5_3_3: "D",
  INDICATOR_OUTPUT_STOCH_K_14_1_3: "K",
  INDICATOR_OUTPUT_STOCH_D_14_1_3: "D",
  INDICATOR_OUTPUT_STOCH_K_21_3_3: "K",
  INDICATOR_OUTPUT_STOCH_D_21_3_3: "D",
  INDICATOR_OUTPUT_ADX_14: "Adx",
  INDICATOR_OUTPUT_MDI_14: "Mdi",
  INDICATOR_OUTPUT_PDI_14: "Pdi",
  INDICATOR_OUTPUT_ADX_20: "Adx",
  INDICATOR_OUTPUT_MDI_20: "Mdi",
  INDICATOR_OUTPUT_PDI_20: "Pdi",
  INDICATOR_OUTPUT_AROON_UPPER_14: "Upper",
  INDICATOR_OUTPUT_AROON_LOWER_14: "Lower",
  INDICATOR_OUTPUT_AROON_UPPER_25: "Upper",
  INDICATOR_OUTPUT_AROON_LOWER_25: "Lower",
  INDICATOR_OUTPUT_VIX_22: "Vix",
  INDICATOR_OUTPUT_MFI_7: "Mfi",
  INDICATOR_OUTPUT_MFI_14: "Mfi",
  INDICATOR_OUTPUT_CCI_14: "Cci",
  INDICATOR_OUTPUT_CCI_20: "Cci",
  INDICATOR_OUTPUT_CCI_21: "Cci",
  INDICATOR_OUTPUT_MACD4C_20_50_9: "Macd",
  INDICATOR_OUTPUT_SIGNAL4C_20_50_9: "Signal",
  INDICATOR_OUTPUT_HISTOGRAM4C_20_50_9: "Histogram",
  LOG_LEVEL_INFO_LABEL: "Ενημέρωση",
  LOG_LEVEL_WARNING_LABEL: "Προειδοποίηση",
  LOG_LEVEL_ERROR_LABEL: "Σφάλμα",
  LOG_LEVEL_UNKNOWN_LABEL: "'Aγνωστο",
  LOG_TYPE_SYNC_LABEL: "Συγχρονισμός",
  LOG_TYPE_CALCULATION_LABEL: "Υπολογισμοί",
  LOG_TYPE_REPORT_LABEL: "Εκτέλεση αναφορών",
  LOG_TYPE_MAIL_LABEL: "Διαδικασία email",
  LOG_TYPE_USER_LABEL: "Ενέργεια χρήστη",
  LOG_TYPE_SECURITY_LABEL: "Ασφάλεια",
  LOG_TYPE_GENERIC_LABEL: "Γενικό",
  LOG_TYPE_UNKNOWN_LABEL: "Άγνωστο",
  ...elLoginPage,
  ...elTfaPage,
  ...elTfaSetupPage,
  ...elNotFoundPage,
  ...elSignalsPage,
  ...elAnalysisPage,
  ...elChartsPage,
  ...elEconomyPage,
  ...elMarketsPage,
  ...elSettingsPage,
  ...elAdminPage,
};
