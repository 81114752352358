/* eslint-disable react-hooks/exhaustive-deps */
import React, { Ref, useEffect, useRef, useState } from "react";
import { IChartApi, createChart } from "lightweight-charts";
import i18n from "i18next";
import { Box } from "@mui/material";
import { EChartDrawTypes } from "@constants/enums";
import { TChartCandles, TChartLines } from "@app/types/entities";
import { sortMarkers, toPrecision } from "@common/helpers";
import CandleLegend from "@common/ChartCommon/Legends/CandleLegend";
import LineLegend from "@common/ChartCommon/Legends/LineLegend";
import AreaLegend from "@common/ChartCommon/Legends/AreaLegend";
import {
  getCandleOptions,
  getCrosshair,
  getPriceScale,
  getTimeScale,
  getGridLine,
  getLayout,
  getWatermark,
  getOverlayPriceScale,
  getLocalization,
  getHandleScroll,
  getHandleScale,
  getKineticOptions,
  getTrackingModeOptions,
} from "@common/ChartCommon/Settings/ChartOptions";
import { publish, subscribe, unsubscribe } from "@utilities/tools";

const eventPrefix = "LeftRightChart";

type EventConfig = {
  crosshairMove?: {
    publishEvent?: string | null;
    subscribeEvent?: string | null;
    action?: (params: any) => void;
    follow?: boolean;
  };
  timeRangeChange?: {
    publishEvent?: string | null;
    subscribeEvent?: string | null;
    action?: (params: any) => void;
    follow?: boolean;
  };
  logicalRangeChange?: {
    publishEvent?: string | null;
    subscribeEvent?: string | null;
    action?: (params: any) => void;
    follow?: boolean;
  };
};

type Props = {
  leftSymbol?: string;
  rightSymbol?: string;
  leftName?: string;
  rightName?: string;
  leftDrawType?: EChartDrawTypes;
  rightDrawType?: EChartDrawTypes;
  leftConfig?: any;
  rightConfig: any;
  leftData?: TChartCandles | TChartLines;
  rightData: TChartCandles | TChartLines;
  showLeftLegend?: boolean;
  showRightLegend?: boolean;
  leftLegendLabel?: string | null;
  rightLegendLabel?: string | null;
  leftMarkers?: any;
  rightMarkers?: any;
  height?: number | undefined;
  width?: number | undefined;
  fullScreen?: boolean;
  autoSize?: boolean;
  horizontalLineConfig?: any;
  horizontalLineData?: TChartLines;
  enableLeftScale?: boolean;
  enableRightScale?: boolean;
  minMove?: number | null;
  autoWidth?: boolean;
  autoHeight?: boolean;
  fitContent?: boolean;
  eventConfig?: EventConfig;
};

const LeftRightChart = React.forwardRef(
  (
    {
      leftSymbol = "",
      rightSymbol = "",
      leftName = "",
      rightName = "",
      leftDrawType = EChartDrawTypes.Line,
      rightDrawType = EChartDrawTypes.Line,
      leftConfig = {},
      rightConfig = {},
      leftData = [],
      rightData = [],
      height = 500,
      width = 500,
      showLeftLegend = false,
      showRightLegend = true,
      leftLegendLabel = "",
      rightLegendLabel = "",
      leftMarkers = [],
      rightMarkers = [],
      enableLeftScale = false,
      enableRightScale = true,
      minMove = null,
      autoSize = true,
      autoWidth = true,
      autoHeight = false,
      fitContent = true,
      horizontalLineConfig = {},
      horizontalLineData = [],
      eventConfig = {
        crosshairMove: {
          action: (params: any) => null,
          publishEvent: null,
          subscribeEvent: null,
          follow: false,
        },
        timeRangeChange: {
          action: (params: any) => null,
          publishEvent: null,
          subscribeEvent: null,
          follow: false,
        },
        logicalRangeChange: {
          action: (params: any) => null,
          publishEvent: null,
          subscribeEvent: null,
          follow: false,
        },
      },
    }: Props,
    ref: Ref<{}>
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const legendsRef = useRef<any>(null);
    const leftTitleLegendRef = useRef() as any;
    const rightTitleLegendRef = useRef() as any;
    const leftLegendRef = useRef() as any;
    const rightLegendRef = useRef() as any;
    const [legendsHeight, setLegendsHeight] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);

    let storedLeft: any, storedRight: any;

    const filterByTime = (stored: any, time: any) =>
      stored.filter((row: any) => row.time.toSeconds() === time.toSeconds());

    useEffect(() => {
      if (eventConfig) {
        const { crosshairMove } = eventConfig;
        if (crosshairMove) {
          const {
            action = null,
            subscribeEvent = null,
            follow = false,
          } = crosshairMove;
          if (subscribeEvent) {
            const moveCrosshair = (param: any) => {
              const chart = chartRef.current;
              if (chart) {
                const { time } = param;
                if (time) {
                  if (storedLeft) {
                    const leftRow = filterByTime(storedLeft, time);
                    if (leftRow) {
                      const { value, time } = leftRow;
                      chart.setCrosshairPosition(value, time, storedLeft);
                    }
                  }
                  if (storedRight) {
                    const rightRow = filterByTime(storedRight, time);
                    if (rightRow) {
                      const { value, time } = rightRow;
                      chart.setCrosshairPosition(value, time, storedRight);
                    }
                  }
                } else {
                  if (chart) chart.clearCrosshairPosition();
                }
              }
            };
            action && subscribe(`${eventPrefix}/${subscribeEvent}`, action);
            follow &&
              subscribe(`${eventPrefix}/${subscribeEvent}`, moveCrosshair);
            return () => {
              action && unsubscribe(`${eventPrefix}/${subscribeEvent}`, action);
              follow &&
                unsubscribe(`${eventPrefix}/${subscribeEvent}`, moveCrosshair);
            };
          }
        }
      }
    });

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        const crosshairSubscription = (params: any) => {
          const event = eventConfig?.crosshairMove?.publishEvent;
          event && publish(`${eventPrefix}/${event}`, params);
        };
        chart.subscribeCrosshairMove(crosshairSubscription);
        return () => {
          chart.unsubscribeCrosshairMove(crosshairSubscription);
        };
      }
    });

    useEffect(() => {
      if (eventConfig) {
        const { timeRangeChange } = eventConfig;
        if (timeRangeChange) {
          const {
            action = null,
            subscribeEvent = null,
            follow = false,
          } = timeRangeChange;
          if (subscribeEvent) {
            const changeVisibleTimeRange = ({ from, to }: any) => {
              const chart = chartRef.current;
              if (chart) {
                if (from && to && (storedLeft || storedRight)) {
                  chart.timeScale().setVisibleRange({ from, to });
                }
              }
            };
            action && subscribe(`${eventPrefix}/${subscribeEvent}`, action);
            follow &&
              subscribe(
                `${eventPrefix}/${subscribeEvent}`,
                changeVisibleTimeRange
              );
            return () => {
              action && unsubscribe(`${eventPrefix}/${subscribeEvent}`, action);
              follow &&
                unsubscribe(
                  `${eventPrefix}/${subscribeEvent}`,
                  changeVisibleTimeRange
                );
            };
          }
        }
      }
    });

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        const visibleTimeRangeSubscription = (params: any) => {
          const event = eventConfig?.timeRangeChange?.publishEvent;
          event && publish(`${eventPrefix}/${event}`, params);
        };
        chart
          .timeScale()
          .subscribeVisibleTimeRangeChange(visibleTimeRangeSubscription);
        return () => {
          chart
            .timeScale()
            .subscribeVisibleTimeRangeChange(visibleTimeRangeSubscription);
        };
      }
    });

    useEffect(() => {
      if (eventConfig) {
        const { logicalRangeChange } = eventConfig;
        if (logicalRangeChange) {
          const {
            action = null,
            subscribeEvent = null,
            follow = false,
          } = logicalRangeChange;
          if (subscribeEvent) {
            const changeVisibleLogicalRange = ({ from, to }: any) => {
              const chart = chartRef.current;
              if (chart) {
                if (from && to && (storedLeft || storedRight)) {
                  chart.timeScale().setVisibleLogicalRange({ from, to });
                }
              }
            };
            action && subscribe(`${eventPrefix}/${subscribeEvent}`, action);
            follow &&
              subscribe(
                `${eventPrefix}/${subscribeEvent}`,
                changeVisibleLogicalRange
              );
            return () => {
              action && unsubscribe(`${eventPrefix}/${subscribeEvent}`, action);
              follow &&
                unsubscribe(
                  `${eventPrefix}/${subscribeEvent}`,
                  changeVisibleLogicalRange
                );
            };
          }
        }
      }
    });

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) {
        const visibleLogicalRangeSubscription = (params: any) => {
          const event = eventConfig?.logicalRangeChange?.publishEvent;
          event && publish(`${eventPrefix}/${event}`, params);
        };
        chart
          .timeScale()
          .subscribeVisibleLogicalRangeChange(visibleLogicalRangeSubscription);
        return () => {
          chart
            .timeScale()
            .subscribeVisibleLogicalRangeChange(
              visibleLogicalRangeSubscription
            );
        };
      }
    });

    useEffect(() => {
      const chart = chartRef.current;
      const container = containerRef.current;
      if (container) {
        if (chart) chart.remove();
        chartRef.current = createChart(container, {
          width: autoWidth ? container.clientWidth : width,
          height: autoHeight ? window.innerHeight : height,
          autoSize: autoSize,
          watermark: getWatermark(
            i18n.t("COMMON_TRADING_CHART_WATERMARK") || "Dcatrader"
          ),
          layout: getLayout(),
          leftPriceScale: getPriceScale(enableLeftScale),
          rightPriceScale: getPriceScale(enableRightScale),
          overlayPriceScales: getOverlayPriceScale(),
          timeScale: getTimeScale(),
          crosshair: getCrosshair(),
          grid: {
            horzLines: getGridLine("#334158"),
            vertLines: getGridLine("#334158"),
          },
          localization: getLocalization(),
          handleScroll: getHandleScroll(),
          handleScale: getHandleScale(),
          kineticScroll: getKineticOptions(),
          trackingMode: getTrackingModeOptions(),
        });
      }
    }, [rightData]);

    useEffect(() => {
      const chart = chartRef.current;
      if (chart && leftData.length > 0) {
        if (leftDrawType === EChartDrawTypes.Line) {
          storedLeft = chart.addLineSeries(leftConfig);
        } else if (leftDrawType === EChartDrawTypes.Candle) {
          storedLeft = chart.addCandlestickSeries(
            leftConfig || getCandleOptions
          );
        } else if (leftDrawType === EChartDrawTypes.Area) {
          storedLeft = chart.addAreaSeries(leftConfig);
        } else if (leftDrawType === EChartDrawTypes.Histogram) {
          storedLeft = chart.addHistogramSeries(leftConfig);
        }
        storedLeft.setData(leftData);
        if (minMove !== null)
          storedLeft.applyOptions({
            priceFormat: {
              type: "price",
              precision: toPrecision(minMove),
              minMove,
            },
          });
        storedLeft.setMarkers(sortMarkers(leftMarkers));
        if (leftLegendRef.current) {
          switch (leftDrawType) {
            case EChartDrawTypes.Line:
            case EChartDrawTypes.Area:
            case EChartDrawTypes.Histogram:
              leftLegendRef.current.setLeftRightLegend(
                leftLegendLabel,
                leftData
              );
              break;
            case EChartDrawTypes.Candle:
              leftLegendRef.current.setLegend(leftData);
              break;
          }
        }
      }
    }, [leftConfig, leftData]);

    useEffect(() => {
      const chart = chartRef.current;
      if (chart && rightData.length > 0) {
        if (rightDrawType === EChartDrawTypes.Line) {
          storedRight = chart.addLineSeries(rightConfig);
        } else if (rightDrawType === EChartDrawTypes.Candle) {
          storedRight = chart.addCandlestickSeries(
            rightConfig || getCandleOptions
          );
        } else if (rightDrawType === EChartDrawTypes.Area) {
          storedRight = chart.addAreaSeries(rightConfig);
        } else if (rightDrawType === EChartDrawTypes.Histogram) {
          storedRight = chart.addHistogramSeries(rightConfig);
        }
        storedRight.setData(rightData);
        if (minMove !== null)
          storedRight.applyOptions({
            priceFormat: {
              type: "price",
              precision: toPrecision(minMove),
              minMove,
            },
          });
        storedRight.setMarkers(sortMarkers(rightMarkers));
        if (rightLegendRef.current) {
          switch (rightDrawType) {
            case EChartDrawTypes.Line:
            case EChartDrawTypes.Area:
            case EChartDrawTypes.Histogram:
              rightLegendRef.current.setLeftRightLegend(
                rightLegendLabel,
                rightData
              );
              break;
            case EChartDrawTypes.Candle:
              rightLegendRef.current.setLegend(rightData);
              break;
          }
        }
      }
    }, [rightConfig, rightData]);

    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.subscribeCrosshairMove((params: any) => {
          if (leftLegendRef.current) {
            const { time } = params;
            switch (leftDrawType) {
              case EChartDrawTypes.Line:
              case EChartDrawTypes.Area:
              case EChartDrawTypes.Histogram:
                leftLegendRef.current.setLeftRightLegend(
                  leftLegendLabel,
                  leftData,
                  time
                );
                break;
              case EChartDrawTypes.Candle:
                leftLegendRef.current.setLegend(leftData, time);
                break;
            }
          }
        });
      }
    }, [leftDrawType, leftData, leftLegendLabel]);

    useEffect(() => {
      if (chartRef.current) {
        chartRef.current.subscribeCrosshairMove((params: any) => {
          if (rightLegendRef.current) {
            const { time } = params;
            switch (rightDrawType) {
              case EChartDrawTypes.Line:
              case EChartDrawTypes.Area:
              case EChartDrawTypes.Histogram:
                rightLegendRef.current.setLeftRightLegend(
                  rightLegendLabel,
                  rightData,
                  time
                );
                break;
              case EChartDrawTypes.Candle:
                rightLegendRef.current.setLegend(rightData, time);
                break;
            }
          }
        });
      }
    }, [rightDrawType, rightData, rightLegendLabel]);

    useEffect(() => {
      if (chartRef.current) {
        const horizontalSeries =
          chartRef.current.addLineSeries(horizontalLineConfig);
        horizontalSeries.setData(horizontalLineData);
      }
    }, [horizontalLineConfig, horizontalLineData]);

    useEffect(() => {
      const chart = chartRef.current;
      if (chart) fitContent && chart.timeScale().fitContent();
    }, [leftData, rightData /*markers*/]);

    useEffect(() => {
      if (leftTitleLegendRef.current) {
        const current = leftTitleLegendRef.current;
        current.setTitleLegend(
          !leftName ? leftSymbol : `${leftSymbol} - ${leftName}`
        );
      }
      if (rightTitleLegendRef.current) {
        const current = leftTitleLegendRef.current;
        current.setTitleLegend(
          !rightName ? rightSymbol : `${rightSymbol} - ${rightName}`
        );
      }
    }, [leftName, leftSymbol, rightName, rightSymbol]);

    useEffect(() => {
      const legends = legendsRef.current;
      if (legends) {
        const observer = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setLegendsHeight(entry.contentRect.height);
          }
        });
        observer.observe(legends);
        return () => {
          observer.disconnect();
        };
      }
    }, []);

    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        const observer = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setContainerWidth(entry.contentRect.width);
          }
        });
        observer.observe(container);
        return () => {
          observer.disconnect();
        };
      }
    }, []);

    useEffect(() => {
      const chart = chartRef.current;
      const container = containerRef.current;
      //Height and width values ignored because 'autoSize' option is enabled
      if (chart && container && !autoSize) {
        let innerHeight = window.innerHeight - legendsHeight;
        //let chartHeight = height - legendsHeight;
        chart.resize(
          autoWidth ? containerWidth : width,
          autoHeight ? innerHeight : height,
          true
        );
      }
    }, [legendsHeight, containerWidth]);

    return (
      <Box>
        <Box
          ref={legendsRef}
          sx={{
            fontFamily: `"Calibri", "Helvetica"`,
            fontWeight: "bold",
            padding: "0 0 1% 1%",
            fontSize: "85%",
            background: "#253248",
            color: "white",
          }}
        >
          {showLeftLegend && (
            <Box>
              {leftData.length !== 0 &&
                leftDrawType === EChartDrawTypes.Line && (
                  <LineLegend ref={leftLegendRef} />
                )}
              {leftData.length !== 0 &&
                leftDrawType === EChartDrawTypes.Candle && (
                  <CandleLegend ref={leftLegendRef} />
                )}
              {leftData.length !== 0 &&
                leftDrawType === EChartDrawTypes.Area && (
                  <AreaLegend ref={leftLegendRef} />
                )}
            </Box>
          )}
          {showRightLegend && (
            <Box>
              {rightData.length !== 0 &&
                rightDrawType === EChartDrawTypes.Line && (
                  <LineLegend ref={rightLegendRef} />
                )}
              {rightData.length !== 0 &&
                rightDrawType === EChartDrawTypes.Candle && (
                  <CandleLegend ref={rightLegendRef} />
                )}
              {rightData.length !== 0 &&
                rightDrawType === EChartDrawTypes.Area && (
                  <AreaLegend ref={rightLegendRef} />
                )}
            </Box>
          )}
        </Box>
        <Box ref={containerRef} />
      </Box>
    );
  }
);

export default LeftRightChart;
