import React, { SyntheticEvent } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Tabs, Tab } from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ChartsStoredTab from "@pages/Charts/Stored/ChartsStoredTab";
import ChartsLiveTab from "@pages/Charts/Live/ChartsLiveTab";
import { AppDispatch } from "@store/configureStore";
import {
  storedIndicatorsChanged,
  storedSignalsChanged,
} from "@store/reducers/charts";

const ChartsPage: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("stored");
  const dispatch = useDispatch<AppDispatch>();

  const onTabChange = (e: SyntheticEvent, tab: string) => {
    setValue(tab);
    if (tab === "stored") {
      dispatch(storedIndicatorsChanged([]));
      dispatch(storedSignalsChanged([]));
    }
  };

  return (
    <Box className="charts-page" sx={{ padding: 1 }}>
      <Box sx={{ width: "100%", paddingBottom: "4%" }}>
        <Tabs value={value} onChange={onTabChange} variant="fullWidth">
          <Tab
            icon={<StorageIcon />}
            iconPosition="start"
            value="stored"
            label={t("CHARTS_STORED_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
          <Tab
            icon={<LiveTvIcon />}
            iconPosition="start"
            value="live"
            label={t("CHARTS_LIVE_TAB_LABEL")}
            sx={{ fontSize: "70%" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {value === "stored" && <ChartsStoredTab />}
        {value === "live" && <ChartsLiveTab />}
      </Box>
    </Box>
  );
};

export default ChartsPage;
