import React from "react";
import { Box, Typography } from "@mui/material";
import { getLogLevelColor, getLogLevelText } from "@pages/helpers";

type Props = {
  log: any;
};

const LogItemLevel: React.FC<Props> = ({ log }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 2 }}>
      <Box
        sx={{
          width: 15,
          height: 15,
          marginRight: "5%",
          background: getLogLevelColor(log.LEVEL),
          borderRadius: "50%",
        }}
      />
      <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {getLogLevelText(log.LEVEL)}
      </Typography>
    </Box>
  );
};

export default LogItemLevel;
