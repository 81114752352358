import React from "react";
import { Box } from "@mui/material";
import TfaSettings from "@pages/Settings/Security/TfaSettings/TfaSettings";

const SecurityTab: React.FC = () => {
  return (
    <Box sx={{ width: "100%", padding: "0px 10px 0px 10px" }}>
      <TfaSettings />
    </Box>
  );
};

export default SecurityTab;
