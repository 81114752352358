/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { DateTime } from "luxon";
import { Box, Stack } from "@mui/material";
import BaseSelect from "@common/BaseSelect/BaseSelect";
import { TAppState } from "@store/reducers";
import { AppDispatch } from "@store/configureStore";
import { marketsSelector } from "@components/selectors";
import { useGetActivePeriodsQuery, useGetAssetTypesQuery } from "@features/api";
import BaseDatePicker from "@common/BaseDatePicker/BaseDatePicker";
import { disableDates, getMaxDate } from "@utilities/helpers";
import {
  rankingsAssetTypeChanged,
  rankingsDatasetAssetTypesChanged,
  rankingsDatasetPeriodsChanged,
  rankingsPeriodChanged,
} from "@store/reducers/markets";
import { fetchLastSynced } from "@actions/period";
import { fetchRankings } from "@actions/markets";
import { rankingsValuedAtChanged } from "@store/reducers/markets";

const RankingsGridFilters: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { data: periodData, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: assetTypesData, isLoading: assetTypesLoading } =
    useGetAssetTypesQuery();
  const {
    rankings: {
      dataset: { periods, assetTypes },
      filters: { period, assetType, valuedAt },
    },
  } = useSelector((state: TAppState) => marketsSelector(state));

  const periodSelected = (period: any) => {
    dispatch(rankingsPeriodChanged(period));
  };

  const assetTypeSelected = (assetType: any) => {
    dispatch(rankingsAssetTypeChanged(assetType));
  };

  const dateSelected = (openedAt: any) => {
    dispatch(rankingsValuedAtChanged(openedAt));
  };

  useEffect(() => {
    if (periodData && !periodsLoading) {
      dispatch(rankingsDatasetPeriodsChanged(periodData));
      dispatch(rankingsPeriodChanged(first(periodData)));
    }
  }, [dispatch, periodData, periodsLoading]);

  useEffect(() => {
    if (assetTypesData && !assetTypesLoading) {
      dispatch(rankingsDatasetAssetTypesChanged(assetTypesData));
      dispatch(rankingsAssetTypeChanged(first(assetTypesData)));
    }
  }, [dispatch, assetTypesData, assetTypesLoading]);

  useEffect(() => {
    const periodId = period?.ID;
    if (periodId) {
      const setValuedAt = async () => {
        const {
          payload: { valuedAt = null },
        } = await dispatch(fetchLastSynced({ periodId }));
        if (valuedAt) {
          dispatch(
            rankingsValuedAtChanged(
              DateTime.fromFormat(valuedAt, "yyyy-MM-dd HH:mm:ss", {
                zone: "utc",
              })
            )
          );
        }
      };
      setValuedAt();
    }
  }, [dispatch, period]);

  useEffect(() => {
    if (period && assetType && valuedAt) {
      const assetTypeId = assetType?.ID;
      const date = valuedAt.toFormat("yyyy-MM-dd HH:mm:ss", { zone: "utc" });
      dispatch(
        fetchRankings({
          period,
          assetType: { ID: assetTypeId },
          valuedAt: date,
        })
      );
    }
  }, [dispatch, assetType, valuedAt]);

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginTop={2}
      marginBottom={2}
    >
      <Box>
        <BaseSelect
          data={periods}
          loading={periodsLoading}
          onChange={periodSelected}
          selectedDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          optionsDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selected={[period?.CODE || ""]}
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
        />
      </Box>
      <Box>
        <BaseSelect
          data={assetTypes}
          loading={assetTypesLoading}
          onChange={assetTypeSelected}
          disabled
          selected={[
            first(assetTypes.filter((as) => as.CODE === "crypto"))?.ID || "",
          ]}
          selectKey="ID"
          displayKey="NAME"
          inputLabel={t("ASSET_TYPE_SELECT_INPUT_LABEL")}
          label={t("ASSET_TYPE_SELECT_LABEL")}
          minWidth={200}
          maxWidth={200}
          selectedDisplay={(row: any) =>
            `${t(`ASSET_TYPE_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
          }
          optionsDisplay={(row: any) =>
            `${t(`ASSET_TYPE_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)}`
          }
        />
      </Box>
      <Box>
        <BaseDatePicker
          label={t("OPENED_AT_DATE_PICKER_LABEL")}
          defaultValue={valuedAt}
          maxDate={getMaxDate(period)}
          onChange={dateSelected}
          timeSteps={{ hours: 24, minutes: 60 }}
          shouldDisableDate={disableDates(period)}
        />
      </Box>
    </Stack>
  );
};

export default RankingsGridFilters;
