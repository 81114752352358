import { createSlice } from "@reduxjs/toolkit";
import { AXIOS_STATUSES } from "@app/types/entities";
import { clearAppLogs, fetchAppLogs } from "@actions/logs";
import { fetchCronTasks } from "../actions/tasks";

export interface TAdminState {
  logs: {
    list: {
      last: Array<any>;
      all: Array<any>;
    };
  };
  tasks: {
    data: Array<any>;
  };
  status: string | null;
  error: string | null;
}

const initialState: TAdminState = {
  logs: {
    list: {
      last: [],
      all: [],
    },
  },
  tasks: {
    data: [],
  },
  status: null,
  error: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    listLogsSet: (state, action) => {
      state.logs.list.all = action.payload;
    },
    listLogsClear: (state) => {
      state.logs.list.all = [];
    },
    taskChanged: (state, action) => {
      const task = action.payload;
      state.tasks.data = state.tasks.data.map((t) =>
        t.ID === task.ID ? task : t
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppLogs.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
        state.error = null;
      })
      .addCase(fetchAppLogs.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        state.logs.list.last = action.payload;
        state.logs.list.all = [...state.logs.list.all, ...state.logs.list.last];
      })
      .addCase(fetchAppLogs.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
        state.error = action.error.message || "Verification failed";
      })
      .addCase(clearAppLogs.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
        state.error = null;
      })
      .addCase(clearAppLogs.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        state.logs.list.last = [];
        state.logs.list.all = [];
      })
      .addCase(clearAppLogs.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
        state.error = action.error.message || "Verification failed";
      })
      .addCase(fetchCronTasks.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
        state.error = null;
      })
      .addCase(fetchCronTasks.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        state.tasks.data = action.payload;
      })
      .addCase(fetchCronTasks.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
        state.error = action.error.message || "Verification failed";
      });
  },
});

export const { listLogsSet, listLogsClear, taskChanged } = adminSlice.actions;
export default adminSlice.reducer;
