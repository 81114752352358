import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { APP_FETCH_SETTINGS } from "@actions/_types";
import { setInterceptors, clearInterceptors } from "@features/interceptors/app";
import { appUrl } from "@constants/endpoints";

export const fetchAppSettings = createAsyncThunk(
  APP_FETCH_SETTINGS,
  async () => {
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(`${appUrl}/settings`);
    clearInterceptors(intercepted);
    return response.data;
  }
);
