import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import i18n from "i18next";
import { APP, API } from "@constants/settings";
import { AppModes } from "@constants/enums";
import { ROUTES } from "@constants/routes";
import { checkForExtendedTimeout } from "@constants/endpoints";
import { history } from "@utilities/history";
import { store } from "@store/configureStore";
import { httpErrorChanged } from "@store/reducers/common";
import { changeLoggedIn } from "@store/reducers/user";
import { getSettingsInStorage, removeUserInStorage } from "@utilities/helpers";

const log = (message: string) => console.log(message);

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { method, url } = config;
  // Set request headers, loading start, check auth
  document.querySelector(`.app-spinner-container`)?.classList.remove("hide");
  APP.MODE === AppModes.Development &&
    log(`[API] ${method?.toUpperCase()} ${url} | Request`);
  config.timeout = checkForExtendedTimeout(url)
    ? API.EXTENDED_TIMEOUT
    : API.TIMEOUT;
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const { dispatch } = store;
  const { method, url } = response.config;
  const { status } = response;
  document.querySelector(`.app-spinner-container`)?.classList.add("hide");
  // Set response data, loading end, error handling
  APP.MODE === AppModes.Development &&
    log(`[API] ${method?.toUpperCase()} ${url} | Response ${status}`);
  const { success, data, error } = response.data;
  if (success === false) {
    dispatch(
      httpErrorChanged({
        errorStatus: true,
        errorMessage: error.message,
      })
    );
  }
  response.data = success ? data : [];
  return response;
};

const onErrorResponse = (error: AxiosError): Promise<AxiosError> => {
  document.querySelector(`.app-spinner-container`)?.classList.add("hide");
  if (axios.isAxiosError(error)) {
    const { dispatch } = store;
    const { message } = error;
    const { method, url } = error.config as AxiosRequestConfig;
    const { status, data = null } = (error.response as AxiosResponse) ?? {};
    const errorMessage = data?.error?.extra?.message;
    APP.MODE === AppModes.Development &&
      log(`[API] ${method?.toUpperCase()} ${url} | Error ${status} ${message}`);
    dispatch(
      httpErrorChanged({
        errorStatus: true,
        errorMessage:
          errorMessage || i18n.t(`NOTIFICATIONS_HTTP_ERROR_${status}`),
      })
    );
    if (status === 401) {
      const settings = getSettingsInStorage();
      const { authActive = null } = settings;
      removeUserInStorage();
      dispatch(changeLoggedIn(false));
      history.replace(authActive ? ROUTES.LOGIN : ROUTES.HOME);
    }
  } else {
    log(`[API] | Error ${error}`);
  }
  return Promise.reject(error);
};

export const setInterceptors = (instance: AxiosInstance) => ({
  requestId: instance.interceptors.request.use(onRequest, onErrorResponse),
  responseId: instance.interceptors.response.use(onResponse, onErrorResponse),
  instance,
});

export const clearInterceptors = ({
  requestId,
  responseId,
  instance,
}): AxiosInstance => {
  instance.interceptors.request.eject(requestId);
  instance.interceptors.response.eject(responseId);
  return instance;
};
