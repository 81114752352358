import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SIGNAL_FETCH, SIGNALS_CALCULATE } from "@actions/_types";
import { setInterceptors, clearInterceptors } from "@features/interceptors/app";
import { signalsUrl } from "@constants/endpoints";

type TFetchSignalPayload = {
  periodId: number;
  signalId?: number;
  pairId?: number;
  openedAt?: string;
  closedAt?: string;
};

type TCalculateSignalsPayload = {
  periodId: string;
  data: string | "";
};

export const fetchSignal = createAsyncThunk(
  SIGNAL_FETCH,
  async (params: TFetchSignalPayload) => {
    const {
      periodId,
      pairId = null,
      signalId = null,
      openedAt = null,
      closedAt = null,
    } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    let url = `${signalsUrl}?periodId=${periodId}`;
    url += signalId ? `&signalId=${signalId}` : ``;
    url += pairId ? `&pairId=${pairId}` : ``;
    url += openedAt ? `&openedAt=${openedAt}` : ``;
    url += closedAt ? `&closedAt=${closedAt}` : ``;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const calculateSignals = createAsyncThunk(
  SIGNALS_CALCULATE,
  async (params: TCalculateSignalsPayload) => {
    const { periodId, data } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.post(
      `${signalsUrl}/calculate`,
      { periodId, data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);
