import { createSlice } from "@reduxjs/toolkit";
import { first } from "lodash";
import { fetchSignal } from "@actions/signal";
import { AXIOS_STATUSES } from "@app/types/entities";
import { fetchCandles } from "@actions/charts";

export interface TSignalState {
  list: {
    data: Array<any>;
    dataset: {
      periods: Array<any>;
      signals: Array<any>;
    };
    filters: {
      period: any | null;
      signal: any | null;
      openedAt: any | null;
    };
  };
  analysis: {
    data: any;
    dataset: {
      periods: Array<any>;
      pairs: Array<any>;
    };
    filters: {
      period: any;
      pair: any;
      openedAt: any | null;
    };
  };
  status: string | null;
}

const initialState: TSignalState = {
  list: {
    data: [],
    dataset: {
      periods: [],
      signals: [],
    },
    filters: {
      period: null,
      signal: null,
      openedAt: null,
    },
  },
  analysis: {
    data: null,
    dataset: {
      periods: [],
      pairs: [],
    },
    filters: {
      period: null,
      pair: null,
      openedAt: null,
    },
  },
  status: null,
};

const signalSlice = createSlice({
  name: "signal",
  initialState,
  reducers: {
    listDatasetPeriodsChanged: (state, action) => {
      state.list.dataset.periods = action.payload;
    },
    listDatasetSignalsChanged: (state, action) => {
      state.list.dataset.signals = action.payload;
    },
    listPeriodChanged: (state, action) => {
      state.list.filters.period = action.payload;
    },
    listSignalChanged: (state, action) => {
      state.list.filters.signal = action.payload;
    },
    listOpenedAtChanged: (state, action) => {
      state.list.filters.openedAt = action.payload;
    },
    analysisDatasetPeriodsChanged: (state, action) => {
      state.analysis.dataset.periods = action.payload;
    },
    analysisDatasetPairsChanged: (state, action) => {
      state.analysis.dataset.pairs = action.payload;
    },
    analysisPeriodChanged: (state, action) => {
      state.analysis.filters.period = action.payload;
    },
    analysisPairChanged: (state, action) => {
      state.analysis.filters.pair = action.payload;
    },
    analysisOpenedAtChanged: (state, action) => {
      state.analysis.filters.openedAt = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSignal.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchSignal.fulfilled, (state, action) => {
        state.list.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchSignal.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchCandles.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchCandles.fulfilled, (state, action) => {
        state.analysis.data = first(action.payload);
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchCandles.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      });
  },
});

export const {
  listDatasetPeriodsChanged,
  listDatasetSignalsChanged,
  listPeriodChanged,
  listSignalChanged,
  listOpenedAtChanged,
  analysisDatasetPeriodsChanged,
  analysisDatasetPairsChanged,
  analysisPeriodChanged,
  analysisPairChanged,
  analysisOpenedAtChanged,
} = signalSlice.actions;
export default signalSlice.reducer;
