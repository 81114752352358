import React from "react";
import { Box } from "@mui/material";
import LogActions from "@pages/Admin/Logs/LogActions/LogActions";
import LogList from "@pages/Admin/Logs/LogList/LogList";

const LogsTab: React.FC = () => {
  return (
    <Box sx={{ width: "100%", padding: "0px 10px 0px 10px" }}>
      <LogActions />
      <LogList />
    </Box>
  );
};

export default LogsTab;
