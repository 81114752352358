export const enBackTestingPage = {
  ADMIN_BACKTESTING_TAB_LABEL: "Backtesting",
  ADMIN_BACKTESTING_CANDLES_LABEL: "Candlestick data",
  ADMIN_BACKTESTING_SIGNALS_LABEL: "Signal data",
  ADMIN_BACKTESTING_CANDLES_ACCORDION_TITLE:
    "Which is the format of candlestick data field?",
  ADMIN_BACKTESTING_SIGNALS_ACCORDION_TITLE:
    "Which is the format of signal data field?",
  ADMIN_BACKTESTING_CANDLES_ACCORDION_DESCRIPTION: `{"symbol":"BTC-USDT","candles":[{"OPENED_AT":"2020-12-28 00:00:00","OPEN":5000,"CLOSE":6000,"HIGH":6100,"LOW":5000},{"OPENED_AT":"2020-12-29 00:00:00","OPEN":6000,"CLOSE":7000,"HIGH":7100,"LOW":6000},{"OPENED_AT":"2020-12-30 00:00:00","OPEN":7000,"CLOSE":8000,"HIGH":8100,"LOW":7000}]}`,
  ADMIN_BACKTESTING_SIGNALS_ACCORDION_DESCRIPTION: `[{"name":"Enhanced Scan","signals":[{"OPENED_AT":"2020-12-28 00:00:00","BUY":1,"SELL":0,"RENDER_TEXT":"EXAMPLE"},{"OPENED_AT":"2020-12-29 00:00:00","BUY":0,"SELL":1},{"OPENED_AT":"2020-12-30 00:00:00","BUY":0,"SELL":0}]},{"name":"Signal","signals":[{"OPENED_AT":"2020-12-28 00:00:00","BUY":0,"SELL":1,"RENDER_TEXT":"EXAMPLE"},{"OPENED_AT":"2020-12-29 00:00:00","BUY":0,"SELL":0},{"OPENED_AT":"2020-12-30 00:00:00","BUY":1,"SELL":0}]}]`,
};

export const elBackTestingPage = {
  ADMIN_BACKTESTING_TAB_LABEL: "Δοκιμή",
  ADMIN_BACKTESTING_CANDLES_LABEL: "Δεδομένα Κεριών",
  ADMIN_BACKTESTING_SIGNALS_LABEL: "Δεδομένα Σημάτων",
  ADMIN_BACKTESTING_CANDLES_ACCORDION_TITLE:
    "Ποιά είναι η μορφοποίηση του πεδίου για τα δεδομένα κεριών;",
  ADMIN_BACKTESTING_SIGNALS_ACCORDION_TITLE:
    "Ποιά είναι η μορφοποίηση του πεδίου για τα δεδομένα σημάτων;",
  ADMIN_BACKTESTING_CANDLES_ACCORDION_DESCRIPTION: `{"symbol":"BTC-USDT","candles":[{"OPENED_AT":"2020-12-28 00:00:00","OPEN":5000,"CLOSE":6000,"HIGH":6100,"LOW":5000},{"OPENED_AT":"2020-12-29 00:00:00","OPEN":6000,"CLOSE":7000,"HIGH":7100,"LOW":6000},{"OPENED_AT":"2020-12-30 00:00:00","OPEN":7000,"CLOSE":8000,"HIGH":8100,"LOW":7000}]}`,
  ADMIN_BACKTESTING_SIGNALS_ACCORDION_DESCRIPTION: `[{"name":"Enhanced Scan","signals":[{"OPENED_AT":"2020-12-28 00:00:00","BUY":1,"SELL":0,"RENDER_TEXT":"EXAMPLE"},{"OPENED_AT":"2020-12-29 00:00:00","BUY":0,"SELL":1},{"OPENED_AT":"2020-12-30 00:00:00","BUY":0,"SELL":0}]},{"name":"Signal","signals":[{"OPENED_AT":"2020-12-28 00:00:00","BUY":0,"SELL":1,"RENDER_TEXT":"EXAMPLE"},{"OPENED_AT":"2020-12-29 00:00:00","BUY":0,"SELL":0},{"OPENED_AT":"2020-12-30 00:00:00","BUY":1,"SELL":0}]}]`,
};
