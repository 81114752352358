import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TAppState } from "@store/reducers";
import { marketsSelector } from "@components/selectors";
import LeftRightChart from "@app/components/Common/LeftRightChart/LeftRightChart";
import { EChartDrawTypes } from "@constants/enums";
import {
  getLineConfig,
  getLegendLabel,
} from "@pages/Markets/CryptoMetrics/ChartLines";
import {
  createChartDominance,
  createChartExchangesInflows,
  createChartExchangesOutflows,
  createChartExchangesSupply,
} from "@utilities/helpers";

const CryptoMetricsCharts: React.FC = () => {
  const {
    metrics: {
      crypto: {
        filters: { asset },
        data,
      },
    },
  } = useSelector((state: TAppState) => marketsSelector(state));

  return (
    <Box>
      <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <LeftRightChart
          height={300}
          rightDrawType={EChartDrawTypes.Area}
          rightConfig={getLineConfig("dominance")}
          rightData={createChartDominance(data)}
          rightLegendLabel={getLegendLabel("dominance", {
            symbol: asset?.SYMBOL,
          })}
        />
      </Box>
      <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <LeftRightChart
          height={300}
          rightDrawType={EChartDrawTypes.Area}
          rightConfig={getLineConfig("exchanges_supply_usd")}
          rightData={createChartExchangesSupply(data)}
          rightLegendLabel={getLegendLabel("exchanges_supply_usd", {
            symbol: asset?.SYMBOL,
          })}
        />
      </Box>
      <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <LeftRightChart
          height={300}
          rightDrawType={EChartDrawTypes.Area}
          rightConfig={getLineConfig("exchanges_inflows_ntv")}
          rightData={createChartExchangesInflows(data)}
          rightLegendLabel={getLegendLabel("exchanges_inflows_ntv", {
            symbol: asset?.SYMBOL,
          })}
        />
      </Box>
      <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <LeftRightChart
          height={300}
          rightDrawType={EChartDrawTypes.Area}
          rightConfig={getLineConfig("exchanges_outflows_ntv")}
          rightData={createChartExchangesOutflows(data)}
          rightLegendLabel={getLegendLabel("exchanges_outflows_ntv", {
            symbol: asset?.SYMBOL,
          })}
        />
      </Box>
    </Box>
  );
};

export default CryptoMetricsCharts;
