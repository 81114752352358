import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Box, Typography } from "@mui/material";
import MeterChart from "@common/MeterChart/MeterChart";
import { TAppState } from "@store/reducers";
import { marketsSelector } from "@components/selectors";

const StocksMeter: React.FC = () => {
  const {
    fearAndGreed: { stocks },
  } = useSelector((state: TAppState) => marketsSelector(state));
  const [current, setCurrent] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const today = DateTime.utc().startOf("day");
    let fgAt = today;
    if (today.isWeekend) {
      let i = 1;
      while (true) {
        const last = today.minus({ days: i });
        i++;
        if (today.diff(last, "days").days > 2) {
          break;
        }
        if (last.weekday === 5) {
          fgAt = last;
          break;
        }
      }
    }
    const filtered = stocks.filter(
      (fng) => fng.FG_AT === fgAt.toFormat("yyyy-MM-dd HH:mm:ss")
    );
    const value = filtered.length > 0 ? filtered[0] : null;
    setCurrent(value);
  }, [stocks]);

  const getColor = (percent: number) => {
    if (percent <= 0.25) return "#FF4D17";
    else if (percent > 0.25 && percent <= 0.45) return "#FF8D18";
    else if (percent > 0.45 && percent <= 0.55) return "#FDB737";
    else if (percent > 0.55 && percent <= 0.75) return "#AEB335";
    else if (percent > 0.75) return "#4CB43C";
    else return "#FDB737";
  };

  const getChartColors = () => [
    getColor(0),
    getColor(0.26),
    getColor(0.46),
    getColor(0.56),
    getColor(0.76),
  ];

  return (
    <>
      <Box sx={{ height: 350, width: 400, margin: "auto" }}>
        <Typography variant="h5" sx={{ textAlign: "center", height: 50 }}>
          {t("MARKETS_FEARGREED_STOCK_MARKET_GAUGE_HEADER")}
        </Typography>
        {current ? (
          <>
            <Box
              sx={{
                textAlign: "center",
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <Typography component="span">
                {t("MARKETS_FEARGREED_STOCK_MARKET_GAUGE_TODAY")}:{" "}
              </Typography>
              <Typography
                component="span"
                sx={{ color: getColor(current.FG_VALUE / 100) }}
              >
                {current?.FG_CLASS}
              </Typography>
            </Box>
            <MeterChart
              arcsLength={[0.25, 0.2, 0.1, 0.2, 0.25]}
              percent={Math.round(current.FG_VALUE) / 100}
              colors={getChartColors()}
              formatText={(value) => value}
              needleColor="#FF3131"
              needleBaseColor="#FF3131"
              textColor="#FF3131"
            />
            <Box sx={{ textAlign: "center" }}>
              {t("MARKETS_FEARGREED_GAUGE_REFERENCE_DATE")}:{" "}
              {DateTime.fromFormat(current.FG_AT, "yyyy-MM-dd HH:mm:ss", {
                zone: "utc",
              }).toFormat("dd/MM/yyyy")}
            </Box>
          </>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", height: 300 }}>
            <Typography variant="h5">
              {t("MARKETS_FEARGREED_NO_DATA_YET")}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default StocksMeter;
