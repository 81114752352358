import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import LeftRightChart from "@common/LeftRightChart/LeftRightChart";
import { AppDispatch } from "@store/configureStore";
import { economySelector } from "@components/selectors";
import { TAppState } from "@store/reducers";
import { EChartDrawTypes } from "@constants/enums";
import { fetchEconomyByExternalId } from "@actions/economy";
import { createChartEconomy, createHorizontalLine } from "@utilities/helpers";
import {
  getHorizontalLineConfig,
  getLegendLabel,
  getLineConfig,
  getRecessionConfig,
  hasRecession,
} from "@pages/Economy/Charts/ChartLines";
import {
  useGetEconomyGroupsQuery,
  useGetEconomyRecessionQuery,
} from "@features/api";
import { createRecessionMarkers } from "@common/helpers";

const EconomyCrisisTab: React.FC = () => {
  const { t } = useTranslation();
  const { data: groups, isLoading: isGroupLoading } =
    useGetEconomyGroupsQuery();
  const { data: recession, isLoading: isRecessionLoading } =
    useGetEconomyRecessionQuery();
  const dispatch = useDispatch<AppDispatch>();
  const { rows } = useSelector((state: TAppState) => economySelector(state));
  const series = useMemo(
    () => groups?.crisis?.series || [],
    [groups?.crisis?.series]
  );
  const recessionConfig = getRecessionConfig();

  useEffect(() => {
    if (!isGroupLoading && groups) {
      for (const serie of series) {
        const externalId = serie.serieExternalId;
        dispatch(fetchEconomyByExternalId({ externalId }));
      }
    }
  }, [dispatch, groups, series, isGroupLoading]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", flexFlow: "wrap", gap: "5%" }}>
        {series.map((serie: any, key: any) => {
          const { serieExternalId, referenceValue } = serie;
          const rightData = rows?.[serieExternalId];
          return (
            <Box
              key={key}
              sx={{
                minWidth: window.innerWidth >= 900 ? "45%" : "90%",
                flex: 1,
                paddingBottom: "5%",
              }}
            >
              {isGroupLoading || isRecessionLoading || !rightData ? (
                <></>
              ) : (
                <Box>
                  <LeftRightChart
                    height={300}
                    rightDrawType={EChartDrawTypes.Area}
                    rightConfig={getLineConfig(serieExternalId)}
                    rightData={createChartEconomy(rightData)}
                    rightLegendLabel={getLegendLabel(serie)}
                    horizontalLineConfig={getHorizontalLineConfig(serie)}
                    horizontalLineData={createHorizontalLine(
                      rightData,
                      referenceValue
                    )}
                    rightMarkers={
                      hasRecession(serieExternalId)
                        ? createRecessionMarkers(
                            recession,
                            recessionConfig,
                            rightData
                          )
                        : []
                    }
                  />
                  <Box sx={{ fontSize: "80%", paddingTop: "2%" }}>
                    <Typography>
                      {t(`ECONOMY_INDEX_DESCRIPTION_${serieExternalId}`)}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default EconomyCrisisTab;
