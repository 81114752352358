import { enLogsTab, elLogsTab } from "@pages/Admin/Logs/translations";
import {
  enScheduledTasks,
  elScheduledTasks,
} from "@pages/Admin/ScheduledTasks/translations";
import {
  enBackTestingPage,
  elBackTestingPage,
} from "@pages/Admin/Backtesting/translations";

export const enAdminPage = {
  ...enLogsTab,
  ...enScheduledTasks,
  ...enBackTestingPage,
};

export const elAdminPage = {
  ...elLogsTab,
  ...elScheduledTasks,
  ...elBackTestingPage,
};
