import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  task: any;
};

const TaskItemDescription: React.FC<Props> = ({ task }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 2 }}>
      <Typography
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        minWidth={300}
        maxWidth={300}
      >
        {task?.DESCRIPTION}
      </Typography>
    </Box>
  );
};

export default TaskItemDescription;
