import React from "react";
import { Box } from "@mui/material";
import SignalsGridFilters from "@pages/Signals/Grid/SignalsGridFilters";
import SignalsGridCount from "@pages/Signals/Grid/Count/SignalsGridCount";
import SignalsGridLegend from "@pages/Signals/Grid/SignalsGridLegend";
import SignalsGrid from "@pages/Signals/Grid/SignalsGrid";
import SignalsGridFaq from "@pages/Signals/Grid/Faq/SignalsGridFaq";

const SignalsGridTab: React.FC = () => {
  return (
    <Box>
      <SignalsGridFilters />
      <SignalsGridCount />
      <SignalsGridLegend />
      <SignalsGrid />
      <SignalsGridFaq />
    </Box>
  );
};

export default SignalsGridTab;
