import {
  enTfaSettings,
  elTfaSettings,
} from "@pages/Settings/Security/TfaSettings/translations";

export const enSecurityTab = {
  SETTINGS_SECURITY_TAB_LABEL: "Security",
  ...enTfaSettings,
};

export const elSecurityTab = {
  SETTINGS_SECURITY_TAB_LABEL: "Ασφάλεια",
  ...elTfaSettings,
};
