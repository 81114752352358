import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { AppDispatch } from "@store/configureStore";
import { LaunchButton } from "@common/BaseButton/Buttons";
import BaseDialog from "@common/BaseDialog/BaseDialog";
import { UserRoles } from "@constants/enums";
import { socket } from "@utilities/socket";
import {
  RestartButton,
  PlayButton,
  StopButton,
} from "@common/BaseButton/Buttons";
import { runCronTask, startCronTask, stopCronTask } from "@actions/tasks";
import { TAppState } from "@store/reducers";
import { commonSelector, userSelector } from "@components/selectors";
import TaskItemDetailsTopBar from "@components/Pages/Admin/ScheduledTasks/TaskItem/Details/TaskItemDetailsTopBar";
import TaskItemDetails from "@components/Pages/Admin/ScheduledTasks/TaskItem/Details/TaskItemDetails";

type Props = {
  task: any;
};

const TaskItemActions: React.FC<Props> = ({ task }) => {
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [isRunning, setIsRunning] = useState<boolean>(task.IS_RUNNING === 1);
  const {
    settings: { authActive },
  } = useSelector((state: TAppState) => commonSelector(state));
  const { profile } = useSelector((state: TAppState) => userSelector(state));

  const showActions = authActive ? profile?.role === UserRoles.Admin : true;

  const onStart = () => dispatch(startCronTask({ taskId: task?.ID }));

  const onStop = () => dispatch(stopCronTask({ taskId: task?.ID }));

  const onRun = () => {
    setIsRunning(true);
    dispatch(runCronTask({ taskId: task?.ID }));
    socket.emit("cron_task_started", { taskId: task?.ID });
  };

  useEffect(() => {
    if (task) {
      const running = task.IS_RUNNING === 1;
      setIsRunning(running);
    }
  }, [task]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
      <Box>
        {showActions && (
          <Box sx={{ display: "flex" }}>
            <RestartButton disabled={isRunning} onClick={onRun} />
            {task?.STATUS === 1 ? (
              <StopButton onClick={onStop} />
            ) : (
              <PlayButton onClick={onStart} />
            )}
          </Box>
        )}
      </Box>
      <Box>
        <BaseDialog
          open={detailsOpen}
          fullScreen
          padding={2}
          content={
            <>
              <TaskItemDetailsTopBar onClose={() => setDetailsOpen(false)} />
              <TaskItemDetails task={task} />
            </>
          }
        />
        <LaunchButton onClick={() => setDetailsOpen(true)} />
      </Box>
    </Box>
  );
};

export default TaskItemActions;
