import { Box } from "@mui/material";
import AnalysisChartFilters from "@pages/Analysis/Chart/AnalysisChartFilters";
import AnalysisChartLegends from "@pages/Analysis/Chart/AnalysisChartLegends";
import AnalysisChart from "@pages/Analysis/Chart/AnalysisChart";
import AnalysisChartFaq from "@pages/Analysis/Chart/AnalysisChartFaq";

const AnalysisChartTab: React.FC = () => {
  return (
    <Box>
      <AnalysisChartFilters />
      <AnalysisChartLegends />
      <AnalysisChart />
      <AnalysisChartFaq />
    </Box>
  );
};

export default AnalysisChartTab;
