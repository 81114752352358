import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  task: any;
};

const TaskItemCode: React.FC<Props> = ({ task }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
      <Typography
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        minWidth={150}
        maxWidth={150}
      >
        {task?.CODE}
      </Typography>
    </Box>
  );
};

export default TaskItemCode;
