export const enDateDisplay = {
  LOCAL_DATETIME_LABEL: "Local",
  UTC_DATETIME_LABEL: "Universal",
  NEW_YORK_DATETIME_LABEL: "New York",
};

export const elDateDisplay = {
  LOCAL_DATETIME_LABEL: "Τοπική",
  UTC_DATETIME_LABEL: "Παγκόσμια",
  NEW_YORK_DATETIME_LABEL: "Νέα Υόρκη",
};
