import { combineReducers } from "@reduxjs/toolkit";
import { api } from "@features/api";
import signalReducer, { TSignalState } from "@store/reducers/signal";
import analysisReducer, { TAnalysisState } from "@store/reducers/analysis";
import chartsReducer, { TChartsState } from "@store/reducers/charts";
import economyReducer, { TEconomyState } from "@store/reducers/economy";
import marketsReducer, { TMarketsState } from "@app/store/reducers/markets";
import backtestingReducer, {
  TBacktestingState,
} from "@store/reducers/backtesting";
import commonReducer, { TCommonState } from "@store/reducers/common";
import userReducer, { TUserState } from "@store/reducers/user";
import settingsReducer, { TSettingsState } from "@store/reducers/settings";
import adminReducer, { TAdminState } from "@store/reducers/admin";

export default combineReducers({
  // Add the generated RTK Query "API slice" caching reducer
  [api.reducerPath]: api.reducer,
  signal: signalReducer,
  analysis: analysisReducer,
  charts: chartsReducer,
  economy: economyReducer,
  markets: marketsReducer,
  backtesting: backtestingReducer,
  common: commonReducer,
  user: userReducer,
  settings: settingsReducer,
  admin: adminReducer,
});

export interface TAppState {
  signal: TSignalState;
  analysis: TAnalysisState;
  charts: TChartsState;
  economy: TEconomyState;
  markets: TMarketsState;
  backtesting: TBacktestingState;
  common: TCommonState;
  user: TUserState;
  settings: TSettingsState;
  admin: TAdminState;
}
