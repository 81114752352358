import { TAppState } from "@store/reducers";

export const signalSelector = (state: TAppState) => state.signal;

export const chartsSelector = (state: TAppState) => state.charts;

export const analysisSelector = (state: TAppState) => state.analysis;

export const economySelector = (state: TAppState) => state.economy;

export const marketsSelector = (state: TAppState) => state.markets;

export const backtestingSelector = (state: TAppState) => state.backtesting;

export const commonSelector = (state: TAppState) => state.common;

export const userSelector = (state: TAppState) => state.user;

export const settingsSelector = (state: TAppState) => state.settings;

export const adminSelector = (state: TAppState) => state.admin;
