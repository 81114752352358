import React from "react";
import { Box } from "@mui/material";
import BacktestingTopBar from "@pages/Admin/Backtesting/TopBar/BacktestingTopBar";
import BacktestingChart from "@pages/Admin/Backtesting/BacktestingChart";

const BacktestingTab: React.FC = () => {
  return (
    <Box>
      <BacktestingTopBar />
      <BacktestingChart autoSize />
    </Box>
  );
};

export default BacktestingTab;
