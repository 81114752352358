import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  MARKETS_FETCH_RANKINGS,
  MARKETS_FETCH_FEARANDGREED,
  MARKETS_FETCH_METRICS,
} from "@actions/_types";
import { setInterceptors, clearInterceptors } from "@features/interceptors/app";
import { marketUrl } from "@constants/endpoints";

type TFetchRankingsPayload = {
  period: any;
  assetType: any;
  valuedAt: string;
};

type TFetchFearAndGreedPayload = {
  type: string;
};

type TFetchMetricsPayload = {
  period: any;
  asset: any;
};

export const fetchRankings = createAsyncThunk(
  MARKETS_FETCH_RANKINGS,
  async ({ period, assetType, valuedAt }: TFetchRankingsPayload) => {
    const periodId = period.ID;
    const assetTypeId = assetType.ID;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    let url = `${marketUrl}/ranking?periodId=${periodId}`;
    url += `&assetTypeId=${assetTypeId}`;
    url += `&valuedAt=${valuedAt}`;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const fetchFearAndGreed = createAsyncThunk(
  MARKETS_FETCH_FEARANDGREED,
  async (params: TFetchFearAndGreedPayload) => {
    const { type } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${marketUrl}/fearandgreed?type=${type}`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const fetchMetrics = createAsyncThunk(
  MARKETS_FETCH_METRICS,
  async ({ period, asset }: TFetchMetricsPayload) => {
    const periodId = period.ID;
    const assetId = asset.ID;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    let url = `${marketUrl}/metrics`;
    url += `?periodId=${periodId}`;
    url += `&assetId=${assetId}`;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);
