import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import BaseTransition from "@common/BaseTransition/BaseTransition";

type Props = {
  title?: React.ReactNode;
  content: React.ReactNode;
  actions?: React.ReactNode;
  open: boolean;
  fullScreen?: boolean;
  padding?: string | number;
  contentPadding?: string | number;
  zIndex?: number;
};

const BaseDialog: React.FC<Props> = ({
  title,
  content,
  actions,
  open,
  fullScreen = false,
  zIndex = 200000,
  padding = "unset",
  contentPadding = "unset",
}) => {
  return (
    <Dialog
      closeAfterTransition={false}
      open={open}
      fullScreen={fullScreen}
      TransitionComponent={BaseTransition}
      sx={{ zIndex, padding }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent sx={{ padding: contentPadding }}>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default BaseDialog;
