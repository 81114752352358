import { createRoot } from "react-dom/client";
import App from "@app/App";
import * as serviceWorker from "@app/serviceWorker";
import "@utilities/i18n";

const appElement = document.getElementById("app");
const root = createRoot(appElement!);
root.render(<App />);

// Use register() to use service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
