import i18n from "i18next";

export const getChartFaqConfig = () => {
  return [
    {
      title: "ANALYSIS_FAQ_TITLE_RSI_DIV",
      description: "ANALYSIS_FAQ_DESCRIPTION_RSI_DIV",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_MFI_DIV",
      description: "ANALYSIS_FAQ_DESCRIPTION_MFI_DIV",
    },
    {
      title: "ANALYSIS_FAQ_TITLE_TAIL",
      description: "ANALYSIS_FAQ_DESCRIPTION_TAIL",
    },
  ];
};

export const getPreactivated = (code: string) => {
  switch (code) {
    case "movement":
      return ["RSIDIV_14"];
    default:
      return [];
  }
};

export const getLegendsConfig = (code: string) => {
  switch (code) {
    case "movement":
      return [
        {
          key: "RSIDIV_14",
          label: i18n.t("ANALYSIS_GRID_RSIDIV_14"),
          body: [
            { color: "#AAFF00", text: i18n.t("ANALYSIS_GRID_VALUE_POSITIVE") },
            { color: "#FF3131", text: i18n.t("ANALYSIS_GRID_VALUE_NEGATIVE") },
          ],
          extraText: "",
        },
        {
          key: "RSIDIV_21",
          label: i18n.t("ANALYSIS_GRID_RSIDIV_21"),
          body: [
            { color: "#228b22", text: i18n.t("ANALYSIS_GRID_VALUE_POSITIVE") },
            { color: "#cc0000", text: i18n.t("ANALYSIS_GRID_VALUE_NEGATIVE") },
          ],
          extraText: "",
        },
        {
          key: "MFIDIV_7",
          label: i18n.t("ANALYSIS_GRID_MFIDIV_7"),
          body: [
            { color: "#d4af37", text: i18n.t("ANALYSIS_GRID_VALUE_POSITIVE") },
            { color: "#ba55d3", text: i18n.t("ANALYSIS_GRID_VALUE_NEGATIVE") },
          ],
          extraText: "",
        },
        {
          key: "MFIDIV_14",
          label: i18n.t("ANALYSIS_GRID_MFIDIV_14"),
          body: [
            { color: "#ffd700", text: i18n.t("ANALYSIS_GRID_VALUE_POSITIVE") },
            { color: "#800080", text: i18n.t("ANALYSIS_GRID_VALUE_NEGATIVE") },
          ],
          extraText: "",
        },
        {
          key: "TAIL",
          label: i18n.t("ANALYSIS_GRID_TAIL"),
          body: [
            {
              color: "rgba(34, 139, 34, 0.6)",
              text: i18n.t("ANALYSIS_GRID_VALUE_BOTTOMING"),
            },
            {
              color: "rgba(204, 0, 0, 0.6)",
              text: i18n.t("ANALYSIS_GRID_VALUE_TOPPING"),
            },
          ],
          extraText: "",
        },
      ];
    default:
      return [];
  }
};

export const getMarkersConfig = (code: string) => {
  switch (code) {
    case "movement":
      return [
        {
          key: "RSIDIV_14",
          value: 1,
          config: {
            position: "belowBar",
            color: "#AAFF00",
            shape: "circle",
            text: "",
          },
        },
        {
          key: "RSIDIV_14",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#FF3131",
            shape: "circle",
            text: "",
          },
        },
        {
          key: "RSIDIV_21",
          value: 1,
          config: {
            position: "belowBar",
            color: "#228b22",
            shape: "circle",
            text: "",
          },
        },
        {
          key: "RSIDIV_21",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#cc0000",
            shape: "circle",
            text: "",
          },
        },
        {
          key: "MFIDIV_7",
          value: 1,
          config: {
            position: "belowBar",
            color: "#d4af37",
            shape: "circle",
            text: "",
          },
        },
        {
          key: "MFIDIV_7",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#ba55d3",
            shape: "circle",
            text: "",
          },
        },
        {
          key: "MFIDIV_14",
          value: 1,
          config: {
            position: "belowBar",
            color: "#ffd700",
            shape: "circle",
            text: "",
          },
        },
        {
          key: "MFIDIV_14",
          value: 2,
          config: {
            position: "aboveBar",
            color: "#800080",
            shape: "circle",
            text: "",
          },
        },
        {
          key: "TAIL",
          value: 1,
          config: {
            position: "belowBar",
            color: "rgba(34, 139, 34, 0.6)",
            shape: "arrowUp",
            text: "",
          },
        },
        {
          key: "TAIL",
          value: 2,
          config: {
            position: "aboveBar",
            color: "rgba(204, 0, 0, 0.6)",
            shape: "arrowDown",
            text: "",
          },
        },
      ];
    default:
      return [];
  }
};
