import React from "react";
import { Box } from "@mui/material";
import TaskActions from "@pages/Admin/ScheduledTasks/TaskActions/TaskActions";
import TaskList from "@pages/Admin/ScheduledTasks/TaskList/TaskList";

const ScheduledTasksTab: React.FC = () => {
  return (
    <Box sx={{ width: "100%", padding: "0px 10px 0px 10px" }}>
      <TaskActions />
      <TaskList />
    </Box>
  );
};

export default ScheduledTasksTab;
