import { createSlice } from "@reduxjs/toolkit";
import {
  AXIOS_STATUSES,
  TAppLanguage,
  TAppSettings,
  TAppTheme,
} from "@app/types/entities";
import { fetchAppSettings } from "@actions/common";
import { setSettingsInStorage } from "@utilities/helpers";

export interface TCommonState {
  settings: TAppSettings;
  theme: TAppTheme;
  language: TAppLanguage;
  http: {
    errorStatus: boolean;
    errorMessage: string;
  };
  status: string | null;
}

const initialState: TCommonState = {
  settings: {
    authActive: null,
    forceTwoFactor: null,
    serverTimezone: null,
    enableCronJobs: null,
  },
  theme: "light",
  language: "en",
  http: {
    errorStatus: false,
    errorMessage: "",
  },
  status: null,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    changeSettings: (state, action) => {
      state.settings = action.payload;
    },
    changeTheme: (state, action) => {
      state.theme = action.payload;
    },
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
    httpErrorChanged: (state, action) => {
      const { errorStatus = false, errorMessage = "" } = action.payload;
      state.http.errorStatus = errorStatus;
      state.http.errorMessage = errorMessage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppSettings.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchAppSettings.fulfilled, (state, action) => {
        state.status = AXIOS_STATUSES.IDLE;
        setSettingsInStorage(action.payload);
        state.settings = action.payload;
      })
      .addCase(fetchAppSettings.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      });
  },
});

export const { changeSettings, changeTheme, changeLanguage, httpErrorChanged } =
  commonSlice.actions;
export default commonSlice.reducer;
